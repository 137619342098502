import React, { forwardRef } from 'react'

import {
    ModalContainer,
    SelectContainer,
    SelectText
} from './ProfileHeaderElements'

import { followArtist } from '../../ArtistProfile/ArtistApi'

import { MdPersonRemove } from 'react-icons/md'

const FollowModal = forwardRef((props, ref) => {
    const handleUnfollowClick = () => {
        followArtist(props.slug)
        props.setIsFollowing(false)
        props.setShowFollowModal(false)
    }

    return (
        <ModalContainer ref={ref} >
            <SelectContainer onClick={handleUnfollowClick}>
                <MdPersonRemove />
                <SelectText>Unfollow</SelectText>
            </SelectContainer>
        </ModalContainer>
    )
})

// Add display name
FollowModal.displayName = 'FollowModal'

export default FollowModal