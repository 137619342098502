import {
  deleteExistingUpdate, 
  deleteMedia,
  addExistingUpdate,
  addUpdate,
  updateExistingTitle,
  editExistingUpdate,
  multiGraphicUpdates
} from '../updatesApi'

const useHandleUpdates = ({
  projectId, 
  setIsEditMode, 
  setUpdateElements, 
  storyElements, 
  deletedElementIds, 
  deletedMediaFiles,
  imageFiles, 
  dispatch, 
  deletedIndexes, 
  title, 
  setTitle, 
  update, 
  isExisting,
  setShowEditModal,
  updates,
  setUpdates,
  filled,
  setFilled,
  coverImage
 }) => {
  const handleSaveChanges = async () => {
    if (!projectId) {
      return
    }

    if (deletedElementIds.length > 0 && isExisting) {
      // Make an API call to delete elements
        const response = await deleteExistingUpdate(deletedElementIds, update._id, projectId)
        setUpdateElements(response.data.updateElements)

        if (deletedMediaFiles.length > 0) {
          const payload = {
            projectId,
            deletedMediaFiles,
          }
          await deleteMedia(payload)
        }
        // dispatch({ type: 'POPULATE_STORY', payload: response.data })
    }

    //remove elements from storyElements that have been deleted

    const updatedStoryElements = storyElements.filter(
      (element) =>
        !(element.operation === 'new' && element.content.length === 0) &&
        !(element.operation === 'delete')
    )

    const updatedStoryWithIndex = updatedStoryElements.map((element, index) => ({
      ...element,
      index: index,
    }))

    let updatedMappedStoryElements = updatedStoryWithIndex.filter(
      (element) => !(element.operation === 'add' && element.isFromDatabase)
    )

    const elementsToAdd = updatedMappedStoryElements.filter((element) => element.operation === 'add')

    if (elementsToAdd.length > 0) {
      // Make an API call to add new elements
      if (isExisting) {
        const payload = {
          projectId,
          updateArray: elementsToAdd,
          updateId: update._id
        }

        const response = await addExistingUpdate(payload)

        dispatch({ type: 'POPULATE_STORY', payload: response.data.update })
        setUpdateElements(response.data.update)
      } else {
        const payload = {
          projectId,
          updateArray: elementsToAdd,
          title,
          coverFile: coverImage
        }

        const response = await addUpdate(payload)
        // The response now contains the update with complete user info
        // Add new update at the beginning of the array
        setUpdates(prevUpdates => [response.data, ...prevUpdates])
        // Add new 'transparent' at the beginning of filled array to match update position
        setFilled(prevFilled => ['transparent', ...prevFilled])
        dispatch({ type: 'POPULATE_STORY', payload: response.data })
      }
    //   const endUpdateResponse = await axios.post(`${API_URL}${ADD_UPDATE_ENDPOINT}`, payload, { withCredentials: true })
    }

    //For title changes
    if (update?.title !== title && isExisting) {
      const payload = {
        projectId,
        updateId: update._id,
        title,
      }
      try {
        const response = await updateExistingTitle(payload)

        setUpdates(updates.map(u => 
          u._id === update._id ? { ...u, title: response.data } : u
        ))
      } catch (error) {
        console.error(error)
      }
    } 

    //For editing existing elements
    const elementsToEdit = updatedMappedStoryElements.filter((element) => element.operation === 'edit')

    if (elementsToEdit.length > 0 && isExisting) {
        const payload = {
          projectId,
          updateId: update._id,
          updateArray: elementsToEdit,
        }

        const response = await editExistingUpdate(payload)
        setUpdateElements(response.data.update)
        dispatch({ type: 'POPULATE_STORY', payload: response.data.update })
    }

    //For adding images
    if (imageFiles.length > 0) {
      const formData = new FormData()
      imageFiles.forEach((file, index) => {
        formData.append('images', file)
      })

      try {
        await multiGraphicUpdates(projectId, formData)
      } catch (error) {
        console.error(error)
      }
    }
    
    //turn off editor mode
    dispatch({ type: 'TOGGLE_EDITOR_MODE_STRICT' })

    if (!isExisting) {
      setShowEditModal(false)
    }
    if (setIsEditMode) {
      setIsEditMode(false)
    }

    document.body.style.overflow = ''
  }
  return { handleSaveChanges }
}

export default useHandleUpdates