import React, { useRef, useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { API_URL } from '../../../../config'

import axios from 'axios'
import { AuthContext } from '../../../../authContext'
import { ObjectId } from 'bson'
import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'

import { 
    addCommentRoute, 
    loadMore, 
    showOrLoadMoreReplies, 
} from './discussionApi'

import CommentCard from './CommentCard'
import ChildCommentCard from './ChildCommentCard'

import { 
    Wrapper,
    AddCommentWrapper,
    AddComment, 
    AddButton,
    CancelButton,
    CommentWrapper,
    CommentSectionWrapper,
    CommentsMetadata,
    LoadMoreComments,
    ShowMoreWrapper,
    ShowMoreRepliesWrapper
} from './DiscussionElements'

import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from 'react-icons/io'
import { BsArrowReturnRight } from 'react-icons/bs'

function Discussion({ projectInfo }) {
    const [isLoading, setIsLoading] = useState(true)
    const [comments, setComments] = useState([])
    const [commentsLength, setCommentsLength] = useState(0)
    const [addComment, setAddComment] = useState('')
    const [pageCount, setPageCount] = useState(1)
    const [limit] = useState(10)
    const [repliesLimit] = useState(5)
    const [commentAdds, setCommentAdds] = useState(0)
    const [showCommentButtons, setShowCommentButtons] = useState(false)
    const { loggedInUser } = useContext(AuthContext)

    const location = useLocation()

    useEffect(() => {
        const getComments = async () => {
            //NEEDS TO BE CACHED
            if (projectInfo.project) {
                if (comments.length === 0) {
                    const response = await axios.get(`${API_URL}/project-comments/comments/${projectInfo.project._id}`, { params: { page: pageCount, limit: repliesLimit, commentAdds }, withCredentials: true })

                    setComments(response.data.comments.results)
                    setCommentsLength(response.data.comments.arrayLength)
                } 
                setIsLoading(false)
            }
        }

        getComments()
        //eslint-disable-next-line
    }, [location, projectInfo])

    const textareaRef = useRef(null)
    const childCommentRef = useRef(null)
    const childCommentRefs = useRef([])
    childCommentRefs.current = []

    //@dev increases input dynamically depending on text line height.
    const handleIncrease = (ref) => {
        textareaRef.current.style.height = '25px'
        textareaRef.current.style.paddingBottom = '5px'
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
    }

    //@dev closes modal, resets comment state and resets text area height. 
    const handleCancel = () => {
        setAddComment('')
        setShowCommentButtons(false)
        textareaRef.current.value = ''
        textareaRef.current.style.height = '25px'
    } 

    //@dev adds a new comment to database and adds to react comment array
    //and adds +1 to comment counter for "loadmore" updates.
    const handleAddComment = async () => {
        //IT WILL SEND UP TO MONGODB WHERE YOU CAN PING IT. 
        if (addComment) {
            let isCreator = projectInfo?.project?.artist._id === loggedInUser?.artist ? true : false
            const isCollaborator = loggedInUser.collaborations.includes(projectInfo.project._id)

            const date = new Date()
            const id = new ObjectId()
            const newComment = {
                _id: id,
                childComments: [],
                comment: addComment,
                commentBy: loggedInUser?.displayName,
                username: loggedInUser.username,
                userId: loggedInUser.id,
                imageFilePath: loggedInUser?.userProfileImage,
                createdAt: date,
                editComment: false,
                isCreator,
                isCollaborator,
                projectId: projectInfo.project._id, 
            }

            const payload = {
                id,
                projectId: projectInfo.project._id,
                comment: addComment,
                commentBy: loggedInUser?.displayName,
                username: loggedInUser.username,
                userId: loggedInUser.id,
                isCreator,
                isCollaborator,
                imageFilePath: loggedInUser?.userProfileImage,
                createdAt: date
            }

            await addCommentRoute(payload)

            setCommentsLength(prev => prev + 1)
            setCommentAdds(prev => prev + 1)
            setComments(array => [newComment, ...array])
        } 
    }

    //@dev loads more comments after removing any duplicates. 
    const handleLoadMore = async () => {
        const updatedPageCount = pageCount + 1

        const response = await loadMore(projectInfo.project._id, updatedPageCount, limit, false, commentAdds)

        let data = response.data.comments.results

        setComments(prev => prev.concat(data))
        setPageCount(updatedPageCount)
    }

    //@dev updates current page count (for grabbing child comments) and grabs comments 
    //up to the limit and adds to the temp replies array if temp bool is false and is first call, 
    //otherwise collapses or shows loaded comments.
    const handleShowReplies = async (parentId, index) => {
        let tempArray = [...comments]
        //For first call to show reply. 
        if (!comments[index].showReplies && !tempArray[index].repliesCalled) {
            tempArray[index].pageCount = 1
            if (!tempArray[index].childCommentAdds)
                tempArray[index].childCommentAdds = 0
            try {
                const response = await showOrLoadMoreReplies(parentId, tempArray[index].pageCount, repliesLimit, true, comments[index].childCommentAdds)

                tempArray[index].replies = response.data.results

                tempArray[index].showReplies = true
            } catch (e) {
                console.error('Error loading replies:', e)
            }
        } else if (!comments[index].showReplies) {
            tempArray[index].showReplies = true
        } else {
            tempArray[index].showReplies = false
        }
        tempArray[index].repliesCalled = true
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }

    //@dev updates current page count (for grabbing comments) and grabs comments 
    //up to the limit and adds to the temp replies array.
    const handleLoadMoreReplies = async (index, parentId) => {
        try {
            const updatedComments = comments.map((comment, idx) => {
                if (idx === index) {
                    return {
                        ...comment,
                        pageCount: comment.pageCount + 1
                    }
                }
                return comment
            })
    
            const response = await showOrLoadMoreReplies(
                parentId, 
                updatedComments[index].pageCount, 
                repliesLimit, 
                true, 
                updatedComments[index].childCommentAdds
            )
    
            setComments(prevComments => prevComments.map((comment, idx) => {
                if (idx === index) {
                    return {
                        ...comment,
                        replies: [...comment.replies, ...response.data.results]
                    }
                }
                return comment
            }))
        } catch (error) {
            console.error('Error loading more replies:', error)
        }
    }
    
    let commentMapping

    //@dev loads both comments and child comments (replies).
    if (comments) {
        commentMapping = comments.map((comment, index) => {
            return (
                <div key={comment._id} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <CommentCard 
                        setCommentAdds={setCommentAdds} 
                        setPageCount={setPageCount} 
                        projectInfo={projectInfo} 
                        index={index} 
                        comments={comments} 
                        setComments={setComments} 
                        comment={comment} 
                        childCommentRef={childCommentRef} 
                        setCommentsLength={setCommentsLength}
                        route={'project-comments'}
                        loggedInUser={loggedInUser}
                    />
                {comment.childComments.length > 0 ? <ShowMoreWrapper onClick={() => handleShowReplies(comment._id, index)}>{!comment.showReplies ? <IoMdArrowDropdownCircle size={15} style={{ marginRight: 5 }}/> : <IoMdArrowDropupCircle size={15} style={{ marginRight: 5 }} />}<div>{comment.childComments.length ? comment.childComments.length : null} REPLIES</div></ShowMoreWrapper> : null}
                {comment.replies && comment.showReplies ? comment.replies.map((reply, replyIndex) => {
                    return (
                        <div key={reply._id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <ChildCommentCard 
                                setCommentAdds={setCommentAdds}
                                setPageCount={setPageCount} 
                                projectInfo={projectInfo}
                                comments={comments} 
                                setComments={setComments} 
                                comment={comment}
                                reply={reply}
                                index={index}
                                replyIndex={replyIndex}
                                setCommentsLength={setCommentsLength}
                                route={'project-comments'}
                            />
                            {replyIndex === comment.replies.length - 1 && comment.childComments.length !== comment.replies.length ? <ShowMoreRepliesWrapper onClick={() => handleLoadMoreReplies(index, comment._id)}><BsArrowReturnRight size={15} style={{ marginRight: 5 }} /> Show more replies</ShowMoreRepliesWrapper> : null}
                        </div>
                    )
                }) : null}
                </div>
            )
        })
    }

    return (
        <Wrapper>
            {isLoading 
            ?
            <LoadContainer style={{ width: '100%' }}>
                <div style={{position: "relative"}}>
                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                    </OuterSpinner>
                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </Spinner>
                </div>
            </LoadContainer>
            :
            <>
                <AddCommentWrapper>
                    <AddComment ref={textareaRef} onInput={handleIncrease} onChange={e => setAddComment(e.target.value)} onClick={() => setShowCommentButtons(true)} placeholder='Add a comment...' />
                    {showCommentButtons ? 
                    <div style={{ display: 'flex' }} >
                        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                        <AddButton onClick={handleAddComment} style={addComment ? {} : { background: '#D8D8D8', cursor: 'default' }}>Comment</AddButton>
                    </div>
                    : null
                    }
                </AddCommentWrapper>
                <CommentWrapper>
                    <CommentSectionWrapper >
                        {commentMapping}
                    </CommentSectionWrapper>
                    <CommentsMetadata>Showing {comments.length} of {commentsLength}</CommentsMetadata>
                    {comments.length !== commentsLength ? <LoadMoreComments onClick={handleLoadMore}>Load More</LoadMoreComments> : null}
                </CommentWrapper>
            </>
            }
        </Wrapper>
    )
}

export default Discussion