import React, { useState, useRef }  from 'react'
import { toggleAnonymous, cancelInvestment } from '../../investmentProfileApi'

import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
    BodySection
} from '../../../ProjectProfile/Modal/EditModalElements.js'

import { 
    PortfolioBody,
    ButtonWrapper,
    ConfirmButton,
    CancelButton,
    EmphasisText
 } from './PortfolioModalElements'

import { IoClose } from 'react-icons/io5'
import { IoIosArrowBack } from "react-icons/io"

import { TiCancel } from "react-icons/ti"
import { MdVisibility } from "react-icons/md"

const PortfolioModal = ({ 
    setShowModal, 
    clickedInvestmentId,
    isInvestmentAnonymous,
    setIsInvestmentAnonymous
}) => {
    const [selectedOption, setSelectedOption] = useState('')

    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''   
            setShowModal(false)
        }
    }

    const closeModalExit = () => {
        document.body.style.overflow = ''
        setShowModal(false)
    }

    const handleToggleAnonymous = () => {
        toggleAnonymous(clickedInvestmentId)
        setIsInvestmentAnonymous(prev => !prev)

        alert(`Your investment is now ${isInvestmentAnonymous ? 'ANONYMOUS' : 'VISIBLE'}.`)
        setShowModal(false)
    }

    const handleCancelInvestment = () => {
        cancelInvestment(clickedInvestmentId)

        alert(`Your investment has been successfully cancelled. Please give us a few days to refund your account.`)
        setShowModal(false)
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText onClick={() => setSelectedOption('')} style={{ display: 'flex', alignItems: 'center' }}>{selectedOption && <IoIosArrowBack />}Edit Investment</HeaderText>
                        <div onClick={closeModalExit} style={{ cursor: 'pointer' }} >
                            <IoClose size={25} />
                        </div>
                    </Header>
                    {selectedOption === 'Visibility' ?
                        (
                            <PortfolioBody>
                                Your investment is currently {isInvestmentAnonymous ? 'ANONYMOUS' : 'VISIBLE'}. 
                                Would you like to make it {isInvestmentAnonymous ? 'VISIBLE' : 'ANONYMOUS'}? 
                                The project owners will still be able to see your name and investment amount.
                                <ButtonWrapper>
                                    <CancelButton onClick={() => setSelectedOption('')}>Return</CancelButton>
                                    <ConfirmButton onClick={handleToggleAnonymous}>
                                        Make Investment <EmphasisText>{!isInvestmentAnonymous ? 'ANONYMOUS' : 'VISIBLE'}</EmphasisText>
                                    </ConfirmButton>
                                </ButtonWrapper>
                            </PortfolioBody>
                        )
                    : selectedOption === 'Cancel' ?
                        (
                            <PortfolioBody>
                                Are you sure you want cancel this investment? You can always invest in this project later as long as the project is still active and receiving funds.
                                <ButtonWrapper>
                                    <CancelButton onClick={() => setSelectedOption('')}>Return</CancelButton>
                                    <ConfirmButton onClick={handleCancelInvestment} >Cancel Investment</ConfirmButton>
                                </ButtonWrapper>
                            </PortfolioBody>
                        )
                    :
                        (
                        <Body>
                            <BodySection onClick={() => setSelectedOption('Visibility')}><MdVisibility size={20} />&nbsp; Edit Investment Visibility</BodySection>
                            <BodySection onClick={() => setSelectedOption('Cancel')}><TiCancel size={20} />&nbsp; Cancel Investment</BodySection>
                        </Body>
                        )
                    }
                </Modal>
            </ModalBg>
        </Wrapper>
    )
}

export default PortfolioModal