import React, { useState, useRef } from 'react'
import { addOfferingsApi } from '../detailsApi'
import { ObjectID } from 'bson'
import { DotPulse, LoadingContainer, LoadingText } from '../../UploadModal/UploadModalElements'

import { 
    PDFContainer, 
    FileName, 
    SubmitPDF, 
    Cancel, 
    ButtonContainer,
    Input 
} from './OfferingElements'
import { 
    Wrapper,
    ModalBg,
    Modal,
    Header,
    HeaderText,
    Body,
} from '../../../Modal/EditModalElements.js'

function UploadModal({ project, setProjectInfo, setOfferings, acceptedFiles, setAcceptedFiles, setShowUploadModal }) {
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = 'visible'
            setShowUploadModal(false)
            setAcceptedFiles([])
        }
    }

    const handleCancel = () => {
        document.body.style.overflow = 'visible'
        setShowUploadModal(false)
        setAcceptedFiles([])
    }

    const handleSubmit = async () => {
        let formData = new FormData()
        if (!title) {
            alert('Please enter a title!')
            return
        }
        setLoading(true)
    
        acceptedFiles.forEach(file => formData.append('uploadPDF', file, file.name))
        let id = new ObjectID()

        const payload = {
            title: title,
            fileName: acceptedFiles[0].name,
            id: project._id,
            offeringId: id,
        }

        Object.keys(payload).forEach(key => {
            formData.append(key, payload[key])
        })
    
        try {
            await addOfferingsApi(project._id, formData)

            const newOffering = { 
                title, 
                fileName: acceptedFiles[0].name, 
                _id: id 
            }

            setOfferings((prevOfferings) => [
                ...prevOfferings, newOffering
            ])

            setProjectInfo(prevProjectInfo => ({
                ...prevProjectInfo,
                project: {
                    ...prevProjectInfo.project,
                    offerings: [...prevProjectInfo.project.offerings, newOffering]
                }
            }))
        } catch (error) {
          console.error('File upload failed', error)
        }
        setLoading(false)
        document.body.style.overflow = 'visible'
        setShowUploadModal(false)
        setAcceptedFiles([])
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal}>
                <Modal>
                    <Header>
                        <HeaderText>Upload PDF</HeaderText>
                    </Header>
                    <Body>
                        {!loading ?
                        <PDFContainer>
                            <FileName>{acceptedFiles[0].name}</FileName>
                            <Input placeholder='Enter a title...' onChange={(e) => setTitle(e.target.value)}/>
                            <ButtonContainer>
                                <Cancel onClick={handleCancel}>Cancel</Cancel>
                                <SubmitPDF onClick={handleSubmit}>Upload</SubmitPDF> 
                            </ButtonContainer>
                        </PDFContainer>  
                        :
                        <LoadingContainer style={{ padding: 20 }}>
                            <DotPulse />
                            <LoadingText style={{ marginTop: 10 }}>Uploading...</LoadingText>
                        </LoadingContainer>
                        }
                    </Body>
                </Modal>
            </ModalBg>
        </Wrapper>              
    )
}

export default UploadModal