import React, { useState, useReducer, useEffect, useRef } from 'react'
import axios from 'axios'
import { format } from 'date-fns'
import { API_URL } from '../../../config'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import LaunchProjectModal from './LaunchProjectModal'

import { optionInitialState, optionReducer } from './Reducers/bodyReducer'

import { addCommasToNumber } from '../../../helpers/numberConvert'

import { 
    Wrapper,
    BodyTopWrapper,
    FundStatusWrapper,
    Title,
    CreatedBy,
    SubtitleBar,
    Logline,
    FundInfoContainer,
    FundProgressLabel,
    FundProgressBar,
    FundProgress,
    FundsRaised,
    FundInfo,
    FundButton,
    GaugeInterestButton,
    GaugeInterestLegalInfo,
    OptionBar,
    OptionWrapper,
    Option,
    BottomBorder,
    ContentContainer,
    PerkContainer,
    SocialWrapper,
    FavoriteProject,
    StyledTbHeart,
    StyledFaFacebook,
    StyledFaSquareXTwitter,
    StyledMdEmail,
    StyledImEmbed2,
    SocialContainer,
    MobileOptionWrapper,
    MobileOption,
    OptionBarButton,
    Sidebar,
    SidebarOption,
    SidebarContainer,
    SidebarContent,
    Selected
} from './BodyElements'

// import ProjectStory from './Story'
import Story from './ProjectStory'
import ProjectMedia from './Media'
import ProjectDetails from './Details'
import Updates from './Updates'
import ProjectTimeline from './Timeline'
import ProjectMyStats from './MyStats'
import Perks from './Perks'
import Faqs from './Faqs'
import Discussion from './Discussion'
import ProjectInfoCard from './ProjectInfoCard'
import ExpressedInterestModal from './ExpressedInterestModal'
import RemoveInterestModal from './ExpressedInterestModal/RemoveInterest'

import { IoPeopleOutline } from 'react-icons/io5'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { GiReceiveMoney, GiTwoCoins } from 'react-icons/gi'
import { IoIosOptions, IoMdClose } from 'react-icons/io'

const DaysLeftDisplay = ({ daysLeft, threshold = 10 }) => {
    const isAlmostOver = daysLeft < threshold
  
    return (
      <FundInfo style={{ color: isAlmostOver ? 'red' : 'inherit' }}>
        <AiOutlineClockCircle size={20} style={{ marginRight: 2 }}/>
        <b style={{ marginRight: 3, marginLeft: 3 }}>
          {daysLeft}
        </b> 
        Days Left
        {isAlmostOver && (
          <span style={{ 
            marginLeft: 5, 
            fontSize: '0.9em' 
          }}>
            (Time is almost up!)
          </span>
        )}
      </FundInfo>
    )
}

//950PX WIDTH
function Body({ projectInfo, setProjectInfo, loggedInUser }) {
    const [perkArray, setPerkArray] = useState([])
    const [showSidebar, setShowSidebar] = useState(false)
    const [isBelowOptionBar, setIsBelowOptionBar] = useState(0)
    const [currentOption, setCurrentOption] = useState('STORY')
    const [following, setFollowing] = useState(false)
    const [showGaugeInterestModal, setShowGaugeInterestModal] = useState(false)
    const [showRemoveInterestModal, setShowRemoveInterestModal] = useState(false)
    const [isExpressedInterest, setIsExpressedInterest] = useState(false)
    const [expressedInterestId, setExpressedInterestId] = useState('')
    const [expressedInterestAmount, setExpressedInterestAmount] = useState(0)
    const [interestPercentage, setInterestPercentage] = useState(0)
    const [showLaunchModal, setShowLaunchModal] = useState(false)
    const [daysLeft, setDaysLeft] = useState(0)

    const [optionState, optionDispatch] = useReducer(optionReducer, optionInitialState)

    const navigate = useNavigate()
    const location = useLocation()

    const handleSelect = (option) => {
        optionDispatch({ type: 'select', payload: option })
        setShowSidebar(false)
        setCurrentOption(option)
    }

    useEffect(() => {
        // Reset optionState when projectInfo changes
        handleSelect('STORY')
    }, [projectInfo?.project?._id])

    const handleDraftClick = () => {
        document.body.style.overflow = 'hidden'
        setShowLaunchModal(true)
    }

    const handleAuthenticatedAction = (action) => {
        if (!loggedInUser?.loggedIn) {
            // Encode the current URL to use as redirect parameter
            const currentPath = encodeURIComponent(location.pathname)
            navigate(`/login?redirect=${currentPath}`)
            return false
        }
        return true
    }

    const handleFundClick = () => {
        if (handleAuthenticatedAction()) {
            navigate(`/project-profile/${projectInfo.project._id}/invest-in-project`)
        }
    }

    const handleGaugeInterestClick = () => {
        if (handleAuthenticatedAction()) {
            document.body.style.overflow = 'hidden'
            setShowGaugeInterestModal(true)
        }
    }

    const handleRemoveInterestClick = () => {
        if (handleAuthenticatedAction()) {
            document.body.style.overflow = 'hidden'
            setShowRemoveInterestModal(true)
        }
    }

    const optionBarRef = useRef(null)
    const timeoutRef = useRef(null)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 950px)'
    })

    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 950px)'
    })

    useEffect(() => {
        const updateDaysLeft = () => {
          setDaysLeft(calculateDaysLeft(projectInfo?.project?.campaignEndDate));
        }
    
        // Update immediately
        updateDaysLeft()
    
        // Then update every hour
        const interval = setInterval(updateDaysLeft, 1000 * 60 * 60)
    
        // Clean up the interval on component unmount
        return () => clearInterval(interval)
      }, [projectInfo?.project?.campaignEndDate])

    useEffect(() => {
        const handleScroll = () => {
          if (timeoutRef.current !== null) {
            return
          }
      
          timeoutRef.current = setTimeout(() => {
            let offsetTop = optionBarRef.current?.getBoundingClientRect().top
            if (offsetTop !== 0) {
              setShowSidebar(false)
              setIsBelowOptionBar(false)
            } else {
              setIsBelowOptionBar(true)
            }
      
            timeoutRef.current = null
          }, 300) // Adjust the throttle delay as needed
        }
      
        window.addEventListener('scroll', handleScroll)
      
        return () => {
          window.removeEventListener('scroll', handleScroll)
          clearTimeout(timeoutRef.current)
        }
    }, [optionBarRef, setShowSidebar, setIsBelowOptionBar])

    useEffect(() => {
        //check if user is following project
        const checkFollowing = async () => {
            const response = await axios.get(`${API_URL}/investment-profile/is-following-project/${projectInfo?.project?._id}`, { withCredentials: true })

            setFollowing(response.data)
        }

        if (loggedInUser?.loggedIn && projectInfo?.project?._id)
            checkFollowing()
    }, [loggedInUser?.loggedIn, projectInfo?.project?._id])

    useEffect(() => {
        // Check if project id matches loggedInUser.expressedInterest.project
        const checkExpressedInterest = async () => {
          const expressedInterest = loggedInUser?.expressedInterest?.find(
            interest => interest.project === projectInfo?.project?._id
          )
      
          if (expressedInterest) {
            setExpressedInterestAmount(expressedInterest?.interest[0]?.amount)
            setExpressedInterestId(expressedInterest?.interest[0]?._id)
            setIsExpressedInterest(true)
          }
      
          if (projectInfo?.project?.targetFundAmount) {
            const percentage =
              (projectInfo?.project?.totalExpressedInterest /
                projectInfo?.project?.targetFundAmount) *
              100
            setInterestPercentage(Math.ceil(percentage))
          }
        }
      
        checkExpressedInterest()
      }, [
        loggedInUser?.expressedInterest,
        projectInfo?.project?._id,
        projectInfo?.project?.targetFundAmount,
        projectInfo?.project?.totalExpressedInterest,
    ])

    let renderComponent 

    if (optionState.store[0].show) {
        renderComponent = <Story projectInfo={projectInfo?.project} setProjectInfo={setProjectInfo} loggedInUser={loggedInUser} />
    } else if  (optionState.store[1].show) {
        renderComponent = <ProjectMedia project={projectInfo.project} setProjectInfo={setProjectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[2].show) {
        renderComponent = <ProjectDetails projectInfo={projectInfo} setProjectInfo={setProjectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[3].show) {
        renderComponent = <ProjectTimeline setProjectInfo={setProjectInfo} projectInfo={projectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[4].show) {
        renderComponent = <ProjectMyStats projectInfo={projectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[5].show) {
        renderComponent = <Perks perkArray={perkArray} setPerkArray={setPerkArray} setProjectInfo={setProjectInfo} projectInfo={projectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[6].show) {
        renderComponent = <Faqs projectInfo={projectInfo} setProjectInfo={setProjectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[7].show) {
        renderComponent = <Discussion projectInfo={projectInfo} loggedInUser={loggedInUser} />
    } else if (optionState.store[8].show) {
        renderComponent = <Updates projectInfo={projectInfo} loggedInUser={loggedInUser} />
    }

    // May need only one selected const
    const storySelected = optionState.store[0].show ? <BottomBorder style={{ width: 70 }} /> : ''
    const mediaSelected = optionState.store[1].show ? <BottomBorder style={{ width: 48 }} /> : ''
    const budgetSelected = optionState.store[2].show ? <BottomBorder style={{ width: 58 }} /> : ''
    const stageSelected = optionState.store[3].show ? <BottomBorder style={{ width: 48 }} /> : ''
    const myStatsSelected = optionState.store[4].show ? <BottomBorder style={{ width: 40 }} /> : ''
    const activitySelected = optionState.store[5].show ? <BottomBorder style={{ width: 65 }} /> : ''
    const faqSelected = optionState.store[6].show ? <BottomBorder style={{ width: 28 }} /> : ''
    const discussionSelected = optionState.store[7].show ? <BottomBorder style={{ width: 95 }} /> : ''
    const updatesSelected = optionState.store[8].show ? <BottomBorder style={{ width: 60 }} /> : ''

    let wrapperStyle

    const formatDate = (dateString) => {
        if (!dateString) return ''
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const date = new Date(dateString);
    
        return date.toLocaleDateString('en-US', options)
    }

    const handleFavoriteClick = async () => {
        try {
            await axios.post(`${API_URL}/project/follow/${projectInfo.project._id}`, { userId: loggedInUser.id }, { withCredentials: true })

            setFollowing(prev => !prev)
        } catch (error) {
            console.log(error)
        }
    }

    const calculateProgressWidth = () => {
        if (projectInfo?.project?.currentFunds && projectInfo?.project?.targetFundAmount) {
          const percentage = (projectInfo?.project?.currentFunds / projectInfo?.project?.targetFundAmount) * 100

          return `${percentage}%`
        }
        return '0%'
    }

    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) return ''
        
        const date = new Date(dateTimeString)
        
        const formattedDate = format(date, 'MMMM d, yyyy')
        const formattedTime = format(date, 'h:mm a')
        
        return `${formattedDate} at ${formattedTime}`
    }

    const calculateDaysLeft = (endDateString) => {
        if (!endDateString) return 0
        
        const endDate = new Date(endDateString)
        const now = new Date()
        
        // Calculate the difference in milliseconds
        const diffTime = endDate - now
        
        // Convert to days and round up
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        
        // Return 0 if the campaign has ended
        return Math.max(diffDays, 0)
    }

    const handleShowInterestOrFund = () => {
        if (projectInfo?.project?.projectStatus === 'GAUGING_INTEREST') {
            return (
                <FundInfoContainer>
                    <div style={{ width: '100%' }}>
                        <FundProgressLabel>Expressed Interest Goal</FundProgressLabel>
                        <FundProgressBar>
                            <FundProgress style={{ width: `${interestPercentage}%`, maxWidth: '100%' }}/>
                        </FundProgressBar>
                        <FundsRaised>${addCommasToNumber(projectInfo?.project?.totalExpressedInterest)}</FundsRaised>
                        <FundInfo style={{ marginTop: 5 }}>of <b style={{ marginLeft: 3, marginRight: 3 }}>${addCommasToNumber(projectInfo?.project?.targetFundAmount)}</b> goal</FundInfo>
                        <FundInfo > <GiReceiveMoney size={20} style={{ marginRight: 5 }}/> Offering <b style={{ marginLeft: 3, marginRight: 3 }}>{projectInfo?.project?.equityGiven}%</b> equity</FundInfo>
                        <FundInfo > <GiTwoCoins size={20} style={{ marginRight: 5 }}/>Investors get <b style={{ marginLeft: 3, marginRight: 3 }}>{100 + projectInfo?.project?.bonusReturn}%</b> return bonus</FundInfo>
                        <FundInfo><IoPeopleOutline size={20} style={{ marginRight: 5 }}/> <b style={{ marginRight: 3 }} >{projectInfo?.expressedInterestCount}</b> People have expressed interest</FundInfo>
                    </div>
                    <>
                        {!isExpressedInterest ?
                            <GaugeInterestButton onClick={handleGaugeInterestClick}>Express Interest</GaugeInterestButton>
                        :
                            <GaugeInterestButton onClick={handleRemoveInterestClick}>Remove Expressed Interest</GaugeInterestButton>
                        }
                    </>
                    {projectInfo?.project?.projectStatus === 'GAUGING_INTEREST' && <GaugeInterestLegalInfo>{legalInfo}</GaugeInterestLegalInfo>}
                    <SocialWrapper>
                        <FavoriteProject onClick={handleFavoriteClick} ><StyledTbHeart fill={following ? 'red' : 'transparent'} color={following ? 'red' : 'black'} />{following ? 'Following' : 'Follow'}</FavoriteProject>
                        <SocialContainer>
                            <a style={{ color: 'black' }} href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/project-profile/65f8c59de89ebc343e5f70c8" target="_blank" rel="noopener noreferrer">
                                <StyledFaFacebook />
                            </a>
                            <a style={{ color: 'black' }} href="https://twitter.com/intent/tweet?url=[URL_TO_SHARE]&text=[TEXT_TO_SHARE]&hashtags=[HASHTAGS]" target="_blank" rel="noopener noreferrer">
                                <StyledFaSquareXTwitter />
                            </a>
                            <a style={{ color: 'black' }} href="mailto:?subject=[SUBJECT]&body=[BODY_TEXT]%20[URL_TO_SHARE]" target="_blank" rel="noopener noreferrer">
                                <StyledMdEmail />
                            </a>
                            <StyledImEmbed2 />
                        </SocialContainer>
                    </SocialWrapper>
                </FundInfoContainer>
            )
        } else if (projectInfo?.project?.projectStatus === 'FUNDING') {
            return (
                <FundInfoContainer>
                    <div style={{ width: '100%' }}>
                        <FundProgressLabel>Project Funding Goal</FundProgressLabel>
                        <FundProgressBar>
                            <FundProgress style={{ width: calculateProgressWidth(), maxWidth: '100%'  }}/>
                        </FundProgressBar>
                        <FundsRaised>${addCommasToNumber(projectInfo?.project?.currentFunds)}</FundsRaised>
                        <FundInfo style={{ marginTop: 5 }}>of <b style={{ marginRight: 3, marginLeft: 3 }}>${addCommasToNumber(projectInfo?.project?.targetFundAmount)}</b> goal</FundInfo>
                        <FundInfo > <GiReceiveMoney size={20} style={{ marginRight: 5 }}/> Offering <b style={{ marginRight: 3, marginLeft: 3 }}>{projectInfo?.project?.equityGiven}%</b> equity</FundInfo>
                        <FundInfo > <GiTwoCoins size={20} style={{ marginRight: 5 }}/>Investors get <b style={{ marginRight: 3, marginLeft: 3 }}>{100 + projectInfo?.project?.bonusReturn}%</b> return bonus</FundInfo>
                        <FundInfo><IoPeopleOutline size={20} style={{ marginRight: 2 }}/><b style={{ marginRight: 3, marginLeft: 3 }}>{addCommasToNumber(projectInfo?.project?.investors)}</b> Backers</FundInfo>
                        <DaysLeftDisplay daysLeft={daysLeft} />
                        <FundInfo style={{ marginTop: 5, color: 'grey', fontSize: 11 }}>
                            This project will close on {formatDateTime(projectInfo?.project?.campaignEndDate)}
                        </FundInfo>
                    </div>
                    <FundButton onClick={handleFundClick}>Back this project</FundButton>
                <SocialWrapper>
                    <FavoriteProject onClick={handleFavoriteClick} ><StyledTbHeart fill={following ? 'red' : 'transparent'} color={following ? 'red' : 'black'} />{following ? 'Following' : 'Follow'}</FavoriteProject>
                    <SocialContainer>
                        <a style={{ color: 'black' }} href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/project-profile/65f8c59de89ebc343e5f70c8" target="_blank" rel="noopener noreferrer">
                            <StyledFaFacebook />
                        </a>
                        <a style={{ color: 'black' }} href="https://twitter.com/intent/tweet?url=[URL_TO_SHARE]&text=[TEXT_TO_SHARE]&hashtags=[HASHTAGS]" target="_blank" rel="noopener noreferrer">
                            <StyledFaSquareXTwitter />
                        </a>
                        <a style={{ color: 'black' }} href="mailto:?subject=[SUBJECT]&body=[BODY_TEXT]%20[URL_TO_SHARE]" target="_blank" rel="noopener noreferrer">
                            <StyledMdEmail />
                        </a>
                        <StyledImEmbed2 />
                    </SocialContainer>
                </SocialWrapper>
            </FundInfoContainer>
            )
        } else if (projectInfo?.project?.projectStatus === 'DRAFT') {
            return(
                <FundInfoContainer>
                    <div style={{ width: '100%' }}>
                        <FundProgressLabel>Project Funding Goal</FundProgressLabel>
                        <FundProgressBar>
                            <FundProgress style={{ width: calculateProgressWidth() }}/>
                        </FundProgressBar>
                        <FundsRaised>${addCommasToNumber(projectInfo?.project?.currentFunds)}</FundsRaised>
                        <FundInfo style={{ marginTop: 5 }}>of ${addCommasToNumber(projectInfo?.project?.targetFundAmount)} goal</FundInfo>
                        <FundInfo > <GiReceiveMoney size={20} style={{ marginRight: 5 }}/> Offering {projectInfo?.project?.equityGiven}% equity</FundInfo>
                        <FundInfo > <GiTwoCoins size={20} style={{ marginRight: 5 }}/>Investors get {100 + projectInfo?.project?.bonusReturn}% return bonus</FundInfo>
                        <FundInfo><IoPeopleOutline size={20} style={{ marginRight: 5 }}/> {addCommasToNumber(projectInfo?.project?.investors)} Backers</FundInfo>
                        <FundInfo><AiOutlineClockCircle size={20} style={{ marginRight: 5 }}/>{projectInfo?.project?.campaignDuration} day duration length</FundInfo>
                        {/* <FundInfo style={{ marginTop: 5, color: 'grey', fontSize: 11 }}>This project will close on {formatDate(projectInfo?.project?.campaignEndDate)} at {projectInfo?.project?.campaignEndTime}</FundInfo> */}
                    </div>
                    <FundButton onClick={handleDraftClick}>Launch Project</FundButton>
                    <SocialWrapper>
                        <SocialContainer>
                            <a style={{ color: 'black' }} href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/project-profile/65f8c59de89ebc343e5f70c8" target="_blank" rel="noopener noreferrer">
                                <StyledFaFacebook />
                            </a>
                            <a style={{ color: 'black' }} href="https://twitter.com/intent/tweet?url=[URL_TO_SHARE]&text=[TEXT_TO_SHARE]&hashtags=[HASHTAGS]" target="_blank" rel="noopener noreferrer">
                                <StyledFaSquareXTwitter />
                            </a>
                            <a style={{ color: 'black' }} href="mailto:?subject=[SUBJECT]&body=[BODY_TEXT]%20[URL_TO_SHARE]" target="_blank" rel="noopener noreferrer">
                                <StyledMdEmail />
                            </a>
                            <StyledImEmbed2 />
                        </SocialContainer>
                    </SocialWrapper>
                </FundInfoContainer>
            )   
        } else if (projectInfo?.project?.projectStatus === 'COMPLETED') {
            return (
                <FundInfoContainer>
                    <div style={{ width: '100%' }}>
                        <FundProgressLabel>Project Funding Goal</FundProgressLabel>
                        <FundProgressBar>
                            <FundProgress style={{ width: calculateProgressWidth(), maxWidth: '100%'  }}/>
                        </FundProgressBar>
                        <FundsRaised>${addCommasToNumber(projectInfo?.project?.currentFunds)}</FundsRaised>
                        <FundInfo style={{ marginTop: 5 }}>of ${addCommasToNumber(projectInfo?.project?.targetFundAmount)} goal</FundInfo>
                        <FundInfo > <GiReceiveMoney size={20} style={{ marginRight: 5 }}/> Offering {projectInfo?.project?.equityGiven}% equity</FundInfo>
                        <FundInfo > <GiTwoCoins size={20} style={{ marginRight: 5 }}/>Investors get {100 + projectInfo?.project?.bonusReturn}% return bonus</FundInfo>
                        <FundInfo><IoPeopleOutline size={20} style={{ marginRight: 5 }}/>{addCommasToNumber(projectInfo?.project?.investors)} Backers</FundInfo>
                        <FundInfo><AiOutlineClockCircle size={20} style={{ marginRight: 5 }}/>0 days left</FundInfo>
                        <FundInfo style={{ marginTop: 5, color: 'grey', fontSize: 11 }}>This project closed on {formatDate(projectInfo?.project?.campaignEndDate)}</FundInfo>
                    </div>
                    <SocialWrapper style={{ marginTop: 10 }}>
                        <FavoriteProject onClick={handleFavoriteClick} ><StyledTbHeart fill={following ? 'red' : 'transparent'} color={following ? 'red' : 'black'} />{following ? 'Following' : 'Follow'}</FavoriteProject>
                        <SocialContainer>
                            <a style={{ color: 'black' }} href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/project-profile/65f8c59de89ebc343e5f70c8" target="_blank" rel="noopener noreferrer">
                                <StyledFaFacebook />
                            </a>
                            <a style={{ color: 'black' }} href="https://twitter.com/intent/tweet?url=[URL_TO_SHARE]&text=[TEXT_TO_SHARE]&hashtags=[HASHTAGS]" target="_blank" rel="noopener noreferrer">
                                <StyledFaSquareXTwitter />
                            </a>
                            <a style={{ color: 'black' }} href="mailto:?subject=[SUBJECT]&body=[BODY_TEXT]%20[URL_TO_SHARE]" target="_blank" rel="noopener noreferrer">
                                <StyledMdEmail />
                            </a>
                            <StyledImEmbed2 />
                        </SocialContainer>
                    </SocialWrapper>
                </FundInfoContainer>
            )
        }
    }

    const legalInfo = "*No money or other consideration is being solicited, and if sent in response, will not be accepted. No sales of securities will be made or commitment to purchase accepted until qualification of the offering statement by the Securities and Exchange Commission and approval of any other required government or regulatory agency and only through an intermediary's platform. An indication of interest made by a prospective investor is non-binding and involves no obligation or commitment of any kind."

    // 'DRAFT', 'GAUGING_INTEREST', 'FUNDING', 'FUNDED', 'COMPLETED', 'PAUSED', 'CANCELLED'

    let offering

    if (projectInfo?.project?.projectFundingType === 'REG_CF')
        offering = 'REG CF'
    else if (projectInfo?.project?.projectFundingType === 'FIXED_REG_CF')
        offering = 'FIXED REG CF'
    else if (projectInfo?.project?.projectFundingType === 'REG_A_TIER_ONE')
        offering = 'REG A TIER ONE'
    else if (projectInfo?.project?.projectFundingType === 'REG_A_TIER_TWO')
        offering = 'REG A TIER TWO'

    let projectStatus 

    if (projectInfo?.project?.projectStatus === 'GAUGING_INTEREST')
        projectStatus = 'GAUGING INTEREST'
    else if (projectInfo?.project?.projectStatus === 'FUNDING')
        projectStatus = 'NOW FUNDING'
    else 
        projectStatus = projectInfo?.project?.projectStatus

    return (
        <Wrapper>
            {showLaunchModal && <LaunchProjectModal projectId={projectInfo?.project?._id} setShowLaunchModal={setShowLaunchModal} />}
            {showRemoveInterestModal && 
                <RemoveInterestModal 
                    setShowRemoveInterestModal={setShowRemoveInterestModal} 
                    setIsExpressedInterest={setIsExpressedInterest} 
                    isExpressedInterest={isExpressedInterest} 
                    setExpressedInterestId={setExpressedInterestId}
                    projectInfo={projectInfo} 
                    setProjectInfo={setProjectInfo}
                    loggedInUser={loggedInUser} 
                    expressedInterestId={expressedInterestId}
                    setExpressedInterestAmount={setExpressedInterestAmount}
                    expressedInterestAmount={expressedInterestAmount}
                />
            }
            {showGaugeInterestModal && 
                <ExpressedInterestModal 
                    loggedInUser={loggedInUser} 
                    projectInfo={projectInfo} 
                    setProjectInfo={setProjectInfo}
                    setShowGaugeInterestModal={setShowGaugeInterestModal} 
                    expressedInterest={isExpressedInterest} 
                    setExpressedInterest={setIsExpressedInterest} 
                    expressedInterestId={expressedInterestId}
                    setExpressedInterestId={setExpressedInterestId}
                    setExpressedInterestAmount={setExpressedInterestAmount}
                    expressedInterestAmount={expressedInterestAmount}
                />
            }
            <BodyTopWrapper>
                <FundStatusWrapper>
                    Offering: {offering} | {projectStatus}
                </FundStatusWrapper>
                <Title>
                    {projectInfo?.project?.projectName.toUpperCase()}
                </Title>
                <CreatedBy>
                    CREATED BY <b>{projectInfo?.project?.artist?.artistName.toUpperCase()}</b>
                </CreatedBy>
                <Logline>
                    {projectInfo?.project?.logline}
                </Logline>
                <SubtitleBar style={{ marginBottom: 10 }}>
                    {projectInfo?.project?.primaryGenre}
                    {projectInfo?.project?.secondaryGenre && projectInfo?.project?.secondaryGenre !== 'None' ? `/${projectInfo?.project?.secondaryGenre}` : ''}
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center', color: '#989898' }}>
                    {projectInfo?.project?.scriptPages} pages
                    </div>
                </SubtitleBar>
                {handleShowInterestOrFund()}
            </BodyTopWrapper>
            {isDesktopOrLaptop && 
                <>
                    <OptionBar>
                        <OptionWrapper style={{ marginLeft: 40 }} onClick={() => handleSelect(optionState.store[0].label)} >
                            <Option style={optionState.store[0].show ? { color: 'black'} : null}>OVERVIEW</Option>
                            {storySelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[1].label)}>
                            <Option style={optionState.store[1].show ? { color: 'black'} : null} >MEDIA</Option>
                            {mediaSelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[2].label)}>
                            <Option style={optionState.store[2].show ? { color: 'black'} : null} >DETAILS</Option>
                            {budgetSelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[3].label)}>
                            <Option style={optionState.store[3].show ? { color: 'black'} : null} >STAGE</Option>
                            {stageSelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[8].label)}>
                            <Option style={optionState.store[8].show ? { color: 'black'} : null} >UPDATES</Option>
                            {updatesSelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[4].label)}>
                            <Option style={optionState.store[4].show ? { color: 'black'} : null} >INFO</Option>
                            {myStatsSelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[5].label)}>
                            <Option style={optionState.store[5].show ? { color: 'black'} : null} >PERKS</Option>
                            {activitySelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[6].label)}>
                            <Option style={optionState.store[6].show ? { color: 'black'} : null} >FAQ</Option>
                            {faqSelected}
                        </OptionWrapper>
                        <OptionWrapper onClick={() => handleSelect(optionState.store[7].label)}>
                            <Option style={optionState.store[7].show ? { color: 'black'} : null} >DISCUSSION</Option>
                            {discussionSelected}
                        </OptionWrapper>
                    </OptionBar>
                </>
            }
            {isTabletOrMobile &&
                <>
                    <OptionBar ref={optionBarRef} >
                        {isBelowOptionBar 
                    ?
                        <>
                            <MobileOptionWrapper onClick={() => setShowSidebar(prev => !prev)} >
                                <IoIosOptions size={20} />
                                <MobileOption>{currentOption}</MobileOption>
                            </MobileOptionWrapper>
                            {projectInfo.project.projectStatus === "GAUGING_INTEREST" ? (
                                !isExpressedInterest ? (
                                    <OptionBarButton onClick={handleGaugeInterestClick}>
                                        Express Interest
                                    </OptionBarButton>
                                ) : (
                                    <OptionBarButton onClick={handleRemoveInterestClick}>
                                    Remove Expressed Interest
                                    </OptionBarButton>
                                )
                                ) : projectInfo.project.projectStatus === "FUNDING" ? (
                                    // Render buttons or components specific to the "FUNDING" status
                                    <OptionBarButton onClick={handleFundClick} >
                                        Back This Project
                                    </OptionBarButton>
                                ) : null}
                        </>
                    :
                        <>
                            <OptionWrapper className='first-child' onClick={() => handleSelect(optionState.store[0].label)} >
                                <Option style={optionState.store[0].show ? { color: 'black'} : null}>OVERVIEW</Option>
                                {storySelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[1].label)}>
                                <Option style={optionState.store[1].show ? { color: 'black'} : null} >MEDIA</Option>
                                {mediaSelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[2].label)}>
                                <Option style={optionState.store[2].show ? { color: 'black'} : null} >DETAILS</Option>
                                {budgetSelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[3].label)}>
                                <Option style={optionState.store[3].show ? { color: 'black'} : null} >STAGE</Option>
                                {stageSelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[8].label)}>
                                <Option style={optionState.store[8].show ? { color: 'black'} : null} >UPDATES</Option>
                                {updatesSelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[4].label)}>
                                <Option style={optionState.store[4].show ? { color: 'black'} : null} >INFO</Option>
                                {myStatsSelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[5].label)}>
                                <Option style={optionState.store[5].show ? { color: 'black'} : null} >PERKS</Option>
                                {activitySelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[6].label)}>
                                <Option style={optionState.store[6].show ? { color: 'black'} : null} >FAQ</Option>
                                {faqSelected}
                            </OptionWrapper>
                            <OptionWrapper onClick={() => handleSelect(optionState.store[7].label)}>
                                <Option style={optionState.store[7].show ? { color: 'black'} : null} >DISCUSSION</Option>
                                {discussionSelected}
                            </OptionWrapper>
                        </>
                    }
                    </OptionBar>
                    <Sidebar show={showSidebar}>
                        <IoMdClose size={25} style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }} onClick={() => setShowSidebar(prev => !prev)} />
                        <SidebarContainer>
                            <SidebarContent onClick={() => handleSelect(optionState.store[0].label)}>
                                {optionState.store[0].show && <Selected />}
                                <SidebarOption style={optionState.store[0].show ? { color: 'black' } : {}}>Story</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[1].label)}>
                                {optionState.store[1].show && <Selected />}
                                <SidebarOption style={optionState.store[1].show ? { color: 'black' } : {}}>Media</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[2].label)}>
                                {optionState.store[2].show && <Selected />}
                                <SidebarOption style={optionState.store[2].show ? { color: 'black' } : {}}>Details</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[3].label)}>
                                {optionState.store[3].show && <Selected />}
                                <SidebarOption style={optionState.store[3].show ? { color: 'black' } : {}}>Stage</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[8].label)}>
                                {optionState.store[8].show && <Selected />}
                                <SidebarOption style={optionState.store[8].show ? { color: 'black' } : {}}>Updates</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[4].label)}>
                                {optionState.store[4].show && <Selected />}
                                <SidebarOption style={optionState.store[4].show ? { color: 'black' } : {}}>Info</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[5].label)}>
                                {optionState.store[5].show && <Selected />}
                                <SidebarOption style={optionState.store[5].show ? { color: 'black' } : {}}>Perks</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[6].label)}>
                                {optionState.store[6].show && <Selected />}
                                <SidebarOption style={optionState.store[6].show ? { color: 'black' } : {}}>FAQ</SidebarOption>
                            </SidebarContent>
                            <SidebarContent onClick={() => handleSelect(optionState.store[7].label)}>
                                {optionState.store[7].show && <Selected />}
                               <SidebarOption style={optionState.store[7].show ? { color: 'black' } : {}}>Discussion</SidebarOption>
                            </SidebarContent>
                        </SidebarContainer>
                    </Sidebar>
                </>
            }
            <ContentContainer>
                {renderComponent}
                {isDesktopOrLaptop &&
                <PerkContainer>
                    <ProjectInfoCard 
                        projectInfo={projectInfo} 
                        wrapperStyle={wrapperStyle} 
                        interestPercentage={interestPercentage}
                        setShowRemoveInterestModal={setShowRemoveInterestModal} 
                        setShowGaugeInterestModal={setShowGaugeInterestModal}
                        isExpressedInterest={isExpressedInterest}
                        projectId={projectInfo?.project?._id} 
                        showLaunchModal={showLaunchModal}
                        setShowLaunchModal={setShowLaunchModal}
                    />
                </PerkContainer>
                }
            </ContentContainer>
        </Wrapper>
    )
}

export default Body