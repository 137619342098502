import axios from 'axios'
import { API_URL } from '../../../../config'

export const getTimelineData = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/project/get-timeline-data/${projectId}`)

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const addTimelineSection = async (payload) => {
    try {
      const response = await axios.post(
        `${API_URL}/project/add-timeline-section-positional/me`,
        payload,
        { withCredentials: true }
      )

      return response.data
    } catch (e) {
      console.error(e)
      alert('There was an error: ' + e)
    }
}

export const removeTimelineSection = async (payload) => {
    try {
      const response = await axios.post(`${API_URL}/project/remove-timeline-section/me`, payload, {
        withCredentials: true,
      })
      return response.data
    } catch (e) {
      console.error(e)
      alert('There was an error: ' + e)
    }
}

export const removeTimelineTasks = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project/remove-timeline-tasks/me`, payload, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const editTimelineSectionTasks = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project/edit-timeline-section-tasks/me`, payload, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const editStatus = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project/edit-status/me`, payload, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const addTimelineTasks = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project/add-timeline-tasks/me`, payload, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const editTimelineSectionTitle = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project/edit-timeline-section-title/me`, payload, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const editItemTitle = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/project/edit-timeline-section-items-title/me`, payload, { withCredentials: true })

        return response.data
    } catch (e) {
        console.error(e)
        alert('There was an error: ' + e)
    }
}

export const updateStrikethroughApi = async (sectionIndex, taskId, projectId) => {
    try {
      const response = await axios.put(
        `${API_URL}/project/strike-timeline-task/me`,
        { sectionIndex, taskId, projectId },
        { withCredentials: true }
      )
      return response
    } catch (e) {
      console.error(e)
      alert('There was an error: ' + e)
    }
}