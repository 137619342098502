import React, { useState, useEffect } from 'react'
import InfoModal from './InfoModal'
import { getMyExpressedInterest, getExpressedInterest, getInvestments, getMyInvestment } from './myStatsApi'
import { timeFromNow }from '../../../../helpers/TimeFromNow'

import 'react-circular-progressbar/dist/styles.css'
import { RiInformationFill } from 'react-icons/ri'
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi'

import { 
    Wrapper,
    StatusWrapper,
    Status,
    StatusDescription,
    StatsBoard, 
    StatSection, 
    StatContainer,
    Header,
    Info,
    TransactionWrapper,
    Table,
    TableHeader,
    TableRow,
    TableCell,
    PaginationContainer,
    ArrowButton,
    DisabledArrowButton 
} from './MyStatsElements'

//DELETE loggedInUser
function ProjectMyStats ({ projectInfo, loggedInUser }) {
    const [investorStake, setInvestorStake] = useState(0)
    const [investorPoolOwnership, setInvestorPoolOwnership] = useState(0)
    const [investorTotalEquity, setInvestorTotalEquity] = useState(0)
    const [page, setPage] = useState(1)
    const [limit] = useState(10)
    const [transactions, setTransactions] = useState([])
    const [isPoolOwnershipInfoVisible, setIsPoolOwnershipInfoVisible] = useState(false)
    const [isActualEquityInfoVisible, setIsActualEquityInfoVisible] = useState(false)

    // useEffect(() => {
    //     const getTransactions = async () => {
    //         if (projectInfo?.project && loggedInUser?.loggedIn) {
    //             if (transactions?.length === 0) {
    //                 try {
    //                     const myInterestResponse = await getMyExpressedInterest(projectInfo.project._id)
                        
    //                     if (!myInterestResponse || myInterestResponse.data === 'NO_EXPRESSED_INTEREST') {
    //                         setInvestorStake(0)
    //                         setInvestorPoolOwnership(0)
    //                         setInvestorTotalEquity(0)
    //                     } else {
    //                         setInvestorStake(myInterestResponse.data?.investorStake || 0)
    //                         setInvestorPoolOwnership(myInterestResponse.data?.investorPoolOwnership || 0)
    //                         setInvestorTotalEquity(myInterestResponse.data?.investorTotalEquity || 0)
    //                     }
    
    //                     let response
    //                     if (projectInfo?.project?.projectStatus === 'FUNDING') {
    //                         response = await getInvestments(projectInfo?.project?._id, page, limit)
    //                     } else {
    //                         response = await getExpressedInterest(projectInfo?.project?._id, page, limit)
    //                     }
    
    //                     if (response && response.data) {
    //                         setTransactions(response.data)
    //                     } else {
    //                         setTransactions([]) // Set to empty array if no data
    //                     }
    //                 } catch (error) {
    //                     console.error("Error fetching data:", error)
    //                     // Handle error state here, e.g., set error message
    //                 }
    //             }
    //         }
    //     }
    
    //     getTransactions()
    
    //     //eslint-disable-next-line
    // }, [projectInfo?.project?._id])

    useEffect(() => {
        const getTransactions = async () => {
            if (projectInfo?.project && loggedInUser?.loggedIn) {
                if (transactions?.length === 0) {
                    try {
                        let myResponse
                        if (projectInfo?.project?.projectStatus === 'FUNDING') {
                            myResponse = await getMyInvestment(projectInfo.project._id)
                        } else {
                            myResponse = await getMyExpressedInterest(projectInfo.project._id)
                        }
    
                        if ((!myResponse || myResponse.data === 'NO_EXPRESSED_INTEREST') && 
                            (!myResponse || myResponse.data === 'NO_INVESTMENT')) {
                            setInvestorStake(0)
                            setInvestorPoolOwnership(0)
                            setInvestorTotalEquity(0)
                        } else {
                            setInvestorStake(myResponse.data?.investorStake || 0)
                            setInvestorPoolOwnership(myResponse.data?.investorPoolOwnership || 0)
                            setInvestorTotalEquity(myResponse.data?.investorTotalEquity || 0)
                        }
    
                        let response;
                        if (projectInfo?.project?.projectStatus === 'FUNDING') {
                            response = await getInvestments(projectInfo.project._id, page, limit)
                        } else {
                            response = await getExpressedInterest(projectInfo.project._id, page, limit)
                        }
    
                        if (response && response.data) {
                            setTransactions(response.data)
                        } else {
                            setTransactions([]) // Set to empty array if no data
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error)
                        // Handle error state here, e.g., set error message
                    }
                }
            }
        }
    
        getTransactions()
    
        //eslint-disable-next-line
    }, [projectInfo?.project?._id])

    const loadNextPage = async () => {
        if (page < transactions.totalPages) {
          const nextPage = page + 1
          const response = await getExpressedInterest(projectInfo?.project?._id, nextPage, limit)
          setTransactions(response.data)
          setPage(nextPage)
        }
    }

    const loadPreviousPage = async () => {
        if (page > 1) {
          const previousPage = page - 1
          const response = await getExpressedInterest(projectInfo?.project?._id, previousPage, limit)
          setTransactions(response.data)
          setPage(previousPage)
        }
    }

    const renderTransactions = transactions?.data?.map(transaction => {
        return (
          <TableRow key={transaction._id}>
            <TableCell>{!transaction.isAnonymous ? transaction.user.username : 'Anonymous'}</TableCell>
            <TableCell>${transaction.investmentAmount ? transaction.investmentAmount : transaction.amount}</TableCell>
            <TableCell>{timeFromNow(transaction.timeOfInvestment ? transaction.timeOfInvestment : transaction.date)}</TableCell>
          </TableRow>
        )
    })

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(2) + 'M'
          } else if (num >= 10000) {
            return (num / 1000).toFixed(0) + 'K'
          } else if (num >= 1000) {
            return (num / 1000).toFixed(2) + 'K'
          } else {
            return num.toString()
          }
    }

    return (
        <Wrapper>
            <div style={{ marginTop: 20, marginBottom: 10, fontWeight: 700, fontSize: 18 }}>My Stats</div>
            <StatsBoard>
                <StatSection>
                    <StatContainer>
                        <Header>My Stake in USD </Header>
                        <Info><strong>$ {formatNumber(investorStake)}</strong></Info>
                    </StatContainer>
                    <StatContainer>
                        <Header>
                            My Pool Share Ownership <RiInformationFill style={{ marginLeft: 5 }} 
                            onMouseEnter={() => setIsPoolOwnershipInfoVisible(true)}
                            onMouseLeave={() => setIsPoolOwnershipInfoVisible(false)}  
                        />
                        </Header>
                        <Info>
                            <strong>{investorPoolOwnership} %</strong>
                        </Info>
                        <InfoModal isVisible={isPoolOwnershipInfoVisible}>
                            This represents your share of ownership in the investment pool.
                        </InfoModal>
                        </StatContainer>
                        <StatContainer>
                        <Header>
                            My Actual Equity <RiInformationFill style={{ marginLeft: 5 }} 
                                onMouseEnter={() => setIsActualEquityInfoVisible(true)}
                                onMouseLeave={() => setIsActualEquityInfoVisible(false)}
                            />
                        </Header>
                        <Info>
                            <strong>{investorTotalEquity} %</strong>
                        </Info>
                        <InfoModal isVisible={isActualEquityInfoVisible}>
                            This represents your actual equity in the project based on your pool share ownership and the total equity given.
                        </InfoModal>
                    </StatContainer>
                </ StatSection>
            </StatsBoard>
            <div style={{ marginTop: 20, marginBottom: 10, fontWeight: 700, fontSize: 18 }}>Project Status</div>
            {
                projectInfo?.project?.projectStatus === 'DRAFT' ? (
                <StatusWrapper>
                    <Status>Draft</Status>
                    <StatusDescription>
                    This project is currently in the &quot;Draft&quot; stage. It has not been published yet and is still being worked on by the project creator.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectStatus === 'FUNDING' ? (
                <StatusWrapper>
                    <Status>Active</Status>
                    <StatusDescription>
                    This project is currently in the &quot;Active&quot; stage. It is open for investment and accepting contributions from interested investors.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectStatus === 'FUNDED' ? (
                <StatusWrapper>
                    <Status>Funded</Status>
                    <StatusDescription>
                    This project has been successfully funded. It has reached its funding goal and is no longer accepting new investments.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectStatus === 'COMPLETED' ? (
                <StatusWrapper>
                    <Status>Completed</Status>
                    <StatusDescription>
                    This project has been completed. All the necessary work has been done, and the project has reached its conclusion.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectStatus === 'CANCELLED' ? (
                <StatusWrapper>
                    <Status>Cancelled</Status>
                    <StatusDescription>
                    This project has been cancelled. It is no longer active and will not be accepting any investments or contributions.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectStatus === 'GAUGING_INTEREST' ? (
                <StatusWrapper>
                    <Status>Gauging Interest</Status>
                    <StatusDescription>
                    This project is currently in the &quot;Gauging Interest&quot; phase. During this phase, we are assessing the level of interest from potential investors to determine the viability of the project.
                    <br /><br />
                    Please note that the stats displayed above and below, including the stake in USD, pool share ownership, and actual equity, are purely indicative and do not represent real dollar amounts or actual ownership in the project. These figures are provided for informational purposes only to gauge the potential interest and are not binding in any way.
                    <br /><br />
                    No money or other consideration is being solicited at this stage, and if sent in response, will not be accepted. No sales of securities will be made or commitment to purchase accepted until qualification of the offering statement by the Securities and Exchange Commission and approval of any other required government or regulatory agency, and only through an intermediary&apos;s platform.
                    <br /><br />
                    An indication of interest made by a prospective investor is non-binding and involves no obligation or commitment of any kind.
                    </StatusDescription>
                </StatusWrapper>
                ) : (
                    null
                )
            }
            <div style={{ marginBottom: 10, marginTop: 20, fontWeight: 700, fontSize: 18 }}>Project Type</div>
            {
                projectInfo?.project?.projectFundingType === 'REG_CF' ? (
                <StatusWrapper>
                    <Status>Regulation Crowdfunding (Reg CF)</Status>
                    <StatusDescription>
                    This project is being offered under Regulation Crowdfunding (Reg CF). Reg CF allows companies to raise up to $5 million from both accredited and non-accredited investors through SEC-registered online crowdfunding platforms. Investors are subject to investment limits based on their annual income and net worth.
                    <br /><br />
                    Investments in Reg CF are subject to the risks associated with startup investing, and there is no guarantee of a return on investment. If the company fails or is unable to generate sufficient revenue, investors may lose some or all of their invested capital.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectFundingType === 'FIXED_REG_CF' ? (
                <StatusWrapper>
                    <Status>Fixed Regulation Crowdfunding (Fixed Reg CF)</Status>
                    <StatusDescription>
                      This project is being offered under Fixed Regulation Crowdfunding (Fixed Reg CF). Fixed Reg CF is a variation of Reg CF that allows companies to offer debt securities with a fixed interest rate.
                      <br /><br />
                      Fixed Reg CF offerings are often (but not always) associated with print and advertising campaigns that occur after the project has been completed. These offerings are subject to the same maximum offering amount and investor limits as traditional Reg CF offerings.
                      <br /><br />
                      As with all Reg CF offerings, investments in Fixed Reg CF are subject to the risks associated with startup investing, and there is no guarantee of a return on investment. If the company fails or is unable to generate sufficient revenue, investors may lose some or all of their invested capital.
                    </StatusDescription>
                  </StatusWrapper>
                ) : projectInfo?.project?.projectFundingType === 'REG_A_TIER_ONE' ? (
                <StatusWrapper>
                    <Status>Regulation A+ Tier 1</Status>
                    <StatusDescription>
                    This project is being offered under Regulation A+ Tier 1. Reg A+ Tier 1 allows companies to raise up to $20 million from both accredited and non-accredited investors through a streamlined SEC registration process. The offering requires SEC and state-level review and approval.
                    <br /><br />
                    Investments in Reg A+ are subject to the risks associated with startup investing, and there is no guarantee of a return on investment. If the company fails or is unable to generate sufficient revenue, investors may lose some or all of their invested capital.
                    </StatusDescription>
                </StatusWrapper>
                ) : projectInfo?.project?.projectFundingType === 'REG_A_TIER_TWO' ? (
                <StatusWrapper>
                    <Status>Regulation A+ Tier 2</Status>
                    <StatusDescription>
                    This project is being offered under Regulation A+ Tier 2. Reg A+ Tier 2 allows companies to raise up to $75 million from both accredited and non-accredited investors through a streamlined SEC registration process. The offering requires SEC review and approval but is exempt from state-level review.
                    <br /><br />
                    Investments in Reg A+ are subject to the risks associated with startup investing, and there is no guarantee of a return on investment. If the company fails or is unable to generate sufficient revenue, investors may lose some or all of their invested capital.
                    </StatusDescription>
                </StatusWrapper>
                ) : (
                null
                )
            }
            <div style={{ marginTop: 30, fontWeight: 700, fontSize: 18 }}>Transactions</div>
            <TransactionWrapper>
            <Table>
                <thead>
                    <TableRow>
                    <TableHeader>User Name</TableHeader>
                    <TableHeader>Amount</TableHeader>
                    <TableHeader>Date</TableHeader>
                    </TableRow>
                </thead>
                <tbody>{renderTransactions}</tbody>
            </Table>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                    <PaginationContainer>
                        {page !== 1 ?
                            <ArrowButton onClick={loadPreviousPage} style={{ marginRight: 3 }}><HiOutlineArrowLeft size={16} /></ArrowButton>
                        :
                            <DisabledArrowButton style={{marginRight: 3}}><HiOutlineArrowLeft size={16} color='gray' /></DisabledArrowButton>
                        }
                        <div>Page {page} of {transactions.totalPages}</div>
                        {page !== transactions?.totalPages ?
                            <ArrowButton onClick={loadNextPage} style={{ marginLeft: 3 }}><HiOutlineArrowRight size={16} /></ArrowButton>
                        :
                            <DisabledArrowButton style={{marginLeft: 3}}><HiOutlineArrowRight size={16} color='gray' /></DisabledArrowButton>
                        }
                    </PaginationContainer>
                </div>
            </TransactionWrapper>
        </Wrapper> 
    )
}

export default ProjectMyStats