import './App.css'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from './config'
import { getCookie } from './helpers/getCookie'
import ScrollToTop from './ScrollTopTop'
// import * as Sentry from "@sentry/react"

import Layout from './Layout'
import Home from './pages'
import ArtistProfile from './pages/ArtistProfile'
import UserProfile from './pages/UserProfile'
import ProjectProfile from './pages/ProjectProfile'
import Posts from './pages/Posts'
import Post from './pages/Post'
import Login from './pages/Login'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword'
import ArtistRegistry from './pages/ArtistRegistry'
import CreateProject from './pages/CreateProject'
import InvestProject from './pages/InvestProject'
import InvestmentProfile from './pages/InvestmentProfile'
import ResetPassword from './pages/ResetPassword'
import Discover from './pages/Discover'
import DiscoverArtists from './pages/DiscoverArtists'
import DiscoverUsers from './pages/DiscoverUsers'
import Admin from './pages/Admin/Admin'
import AdminLogin from './pages/Admin/AdminLogin'
import AdminProjects from './pages/Admin/AdminProjects'
import AdminPosts from './pages/Admin/AdminPosts'
import AdminAcceptRejectList from './pages/Admin/AdminAcceptRejectList'
import AdminArtistWaitlist from './pages/Admin/AdminArtistWaitlist'
import AdminArtists from './pages/Admin/AdminArtists'
import AdminCreatePost from './pages/Admin/AdminCreatePost'
import ProtectedArtistRoute from './components/ProtectedArtistRoute'

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { AuthContext } from './authContext'

// Sentry.init({
//   dsn: "https://adef03b7b335cad6d0655c187751e1da@o4507671810539520.ingest.us.sentry.io/4507671821156352",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

const queryClient = new QueryClient()

// Error Fallback component
// const ErrorFallback = ({error}) => {
//   return (
//     <div>
//       <h1>Oops! Something went wrong.</h1>
//       <pre>{error.message}</pre>
//     </div>
//   )
// }

function AppContent() {
  const fetchUser = async () => {
    const token = getCookie('tokenPresence')
    const userType = getCookie('userType')
    if (!token) return { loggedIn: false }
    if (userType === 'admin') return
    try {
      const response = await axios.get(`${API_URL}/login/me`, { withCredentials: true })
      return response.data ? { ...response.data, loggedIn: true } : { loggedIn: false }
    } catch (error) {
      console.error('Error fetching user data:', error)
      return { loggedIn: false }
    }
  }

  const { data: userData, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    enabled: !!getCookie('tokenPresence'), // Only run the query if there's a token
    staleTime: 1000 * 60 * 5, // Consider the data fresh for 5 minutes
  })

  const loggedInUser = userData

  if (isLoading) {
    return (
      <>
      </>
    )
  }

  return (
    <AuthContext.Provider value={{ loggedInUser, isLoading }}>
      <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Layout><Home /></Layout>} />
            <Route path='/project-profile/:projectId' element={<Layout><ProjectProfile /></Layout>} />
            <Route path='/project-profile/:projectId/invest-in-project' element={<InvestProject />} />
            <Route path='/artist-registry' element={<Layout><ArtistRegistry /></Layout>} />
            <Route path='/login' element={<Layout><Login /></Layout>} />
            <Route path='/sign-up' element={<Layout><Signup /></Layout>} />
            <Route path='/password/reset' element={<Layout><ForgotPassword /></Layout>} />
            <Route 
              path='/create-project' 
              element={
                <ProtectedArtistRoute>
                  <CreateProject />
                </ProtectedArtistRoute>
              } 
            />
            <Route path='/artist-profile/:slug' element={<Layout><ArtistProfile /></Layout>} />
            <Route path='/user-profile/:username' element={<Layout><UserProfile /></Layout>} />
            <Route path='/my-investment-profile' element={<Layout><InvestmentProfile /></Layout>} />
            <Route path='/reset-password' element={<Layout><ResetPassword /></Layout>} />
            <Route path='/discover' element={<Layout><Discover /></Layout>} />
            <Route path='/discover-artists' element={<Layout><DiscoverArtists /></Layout>} />
            <Route path='/discover-investors' element={<Layout><DiscoverUsers /></Layout>} />
            <Route path='/admin/dashboard' element={<Admin />} />
            <Route path='/admin/login' element={<AdminLogin />} />
            <Route path='/admin/projects' element={<AdminProjects />} />
            <Route path='/admin/posts' element={<AdminPosts />} />
            <Route path='/admin/artist-waitlist' element={<AdminArtistWaitlist />} />
            <Route path='/admin/artist-acceptance' element={<AdminAcceptRejectList />} />
            <Route path='/admin/artists' element={<AdminArtists />} />
            <Route path='/admin/create-post' element={<AdminCreatePost />} />
            <Route path='/posts' element={<Layout><Posts /></Layout>} />
            <Route path='/posts/:slug' element={<Layout><Post /></Layout>} />
          </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Sentry.ErrorBoundary fallback={ErrorFallback}> */}
        <AppContent />
      {/* </Sentry.ErrorBoundary> */}
    </QueryClientProvider>
  )
}

export default App