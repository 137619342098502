import styled from 'styled-components'

export const PortfolioBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
`

export const ButtonWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

export const ConfirmButton = styled.button`
    padding: 10px;
    outline: none;
    border: none;
    background: black;
    color: white;
    cursor: pointer;

    &:hover {
        background: #343131;
    }
`

export const CancelButton = styled.button`
    padding: 10px;
    background: transparent;
    color: grey;
    border: none;
    cursor: pointer;
    margin-right: 5px;

    &:hover {
        background: #eee;
    }
`

export const EmphasisText = styled.span`
    font-weight: bold;
    display: inline;
`