import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../config'
import { AuthContext } from '../authContext'
import { ExploreButton } from '../components/Home/FeaturedProjects/ProjectContainer/ProjectContainerElements'

import Header from '../components/Home/Header'
//DELETE FILE IF APPLICABLE
import Faq from '../components/Home/Faq'
//DELETE FILE IF APPLICLABE
import FeaturedProjects from '../components/Home/FeaturedProjects/ProjectContainer'
import Navbar from '../components/Navbar'

function Home() {
    const [projects, setProjects] = useState([])
    const [expressedInterestProjects, setExpressedInterestProjects] = useState([])
    const { loggedInUser } = useContext(AuthContext)

    const navigate = useNavigate()
    
    useEffect(() => {
        const getProjects = async () => {
            const response = await axios.get(`${API_URL}/home/new-projects`)

            setProjects(response.data.fundingProjects)
            setExpressedInterestProjects(response.data.gaugingInterestProjects)
        }

        getProjects()
    }, [])

    const handleExploreClick = () => {
        window.scrollTo(0, 0)
        navigate('/discover')
    }

    return (
        <div>
            <Navbar loggedInUser={loggedInUser} />
            <Header />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 150, marginTop: 50 }}>
                <div style={{ 
                    fontFamily: "'DM Sans', sans-serif", 
                    fontWeight: '300', 
                    fontSize: 'clamp(24px, 5vw, 40px)', 
                    marginBottom: 10 
                    }}>
                    Explore projects currently being funded.
                    </div>
                    <div style={{ 
                    fontFamily: "'DM Sans', sans-serif", 
                    fontWeight: '300', 
                    fontSize: 'clamp(16px, 3vw, 20px)', 
                    width: '90%', 
                    maxWidth: '600px', 
                    textAlign: 'center' 
                    }}>
                    Invest with as little as $100 and no platform fees.
                </div>
                    {/* <button style={{ marginTop: 20, width: 200, height: 50, borderRadius: 10, border: 'none', background: 'white', fontFamily: "'DM Sans', sans-serif", fontWeight: '700', fontSize: 15, background: 'black', color: 'white' }}>See Faqs</button> */}
                </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <FeaturedProjects title={'New Projects Currently Funding'} projects={projects} top={0}/>
            <FeaturedProjects title={'New Projects Gauging Interest'} projects={expressedInterestProjects} top={50} />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                <ExploreButton onClick={handleExploreClick}>Explore All</ExploreButton>
            </div>
            <Faq />
            </div>
        </div>
    )
}

export default Home