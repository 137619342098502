import axios from 'axios'
import { API_URL } from '../../../../config'

export const uploadGraphic = async (projectId, data) => {
    const response = await axios.post(`${API_URL}/project-perks/upload-graphic/${projectId}`, data, 
        { headers: { 
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'text/html' 
        }, 
        withCredentials: true
    })

    return response
}

export const addPerkWithGraphic = async (data, id) => {
    const response = await axios.post(`${API_URL}/project-perks/add-perk-with-graphic/me/${id}`, data, {
        headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'multipart/form-data' // This is important for FormData
        },
        withCredentials: true
    })

    return response
}  

export const addPerk = async (payload) => {
    const response = await axios.post(`${API_URL}/project-perks/add-perk/me`, payload, { withCredentials: true })

    return response
}

export const deletePerkImage = async (payload, projectId, perkArray, perkIndex) => {
    const response = await axios.post(`${API_URL}/project-perks/delete-graphic/${projectId}/${perkArray[perkIndex].imageFile}`, payload, { withCredentials: true })

    return response 
}

export const updatePerk = async (payload, projectId, perkIndex) => {
    const response = await axios.post(`${API_URL}/project-perks/update-perk/${projectId}/${perkIndex}`, payload, { withCredentials: true })

    return response
}