import React, { useState, useEffect, useRef, useCallback } from 'react'
import { NEW, CHANGE, CAST, TEAM, BUDGET, RISKS, OFFERINGS, SCROLL_HEIGHT } from '../../../../constants'
import { editTeamApi, deleteTeamApi, deleteImageApi, deleteImagesApi, addTeamApi, multipleImagesApi } from './detailsApi'
import { PrimaryColor } from '../../../../colors'

import UploadModal from '../UploadModal'
import Cast from './Cast'
import Team from './Team'
import Budget from './Budget'
import Risks from './Risks'
import Offerings from './Offerings'

import { 
    Wrapper, 
    HeaderContainer,
    SectionSelect,
    SectionSelectButton,
    Header, 
} from './ProjectDetailElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'

//TEAM, FINANCIALS, BUDGET, OFFERINGS, RISKS
function ProjectDetails({ projectInfo, setProjectInfo, loggedInUser }) {
    const [isLoading, setIsLoading] = useState(true)
    const [showImageModal, setShowImageModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showBudgetModal, setShowBudgetModal] = useState(false)
    const [editTeam, setEditTeam] = useState(false)
    const [teamMembers, setTeamMembers] = useState([{ imageFileName: '', previewFileName: '', name: '', role: '', description: '', imageFile: [], index: 0, status: NEW }])
    const [deleteArray, setDeleteArray] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [imageDeleteArray, setImageDeleteArray] = useState([])
    const [imageFiles, setImageFiles] = useState([])
    const [focusedIndex, setFocusedIndex] = useState(0)
    const [activeSection, setActiveSection] = useState(CAST)

    const castRef = useRef(null)
    const teamRef = useRef(null)
    const budgetRef = useRef(null)
    const risksRef = useRef(null)
    const offeringsRef = useRef(null)

    const { _id } = projectInfo?.project || {}

    const calculateSectionPosition = (sectionRef, offset) => {
        return sectionRef.current
          ? sectionRef.current.getBoundingClientRect().top + window.pageYOffset - offset
          : 0
    }
      
    useEffect(() => {
        const handleScroll = () => {
            const yOffset = window.pageYOffset + SCROLL_HEIGHT // Adding the offset value to make it consistent with scrollToSection
          
            let pos = yOffset - calculateSectionPosition(castRef, SCROLL_HEIGHT)
            let pos1 = yOffset - calculateSectionPosition(teamRef, SCROLL_HEIGHT)
            let pos2 = yOffset - calculateSectionPosition(budgetRef, SCROLL_HEIGHT)
            let pos3 = yOffset - calculateSectionPosition(risksRef, SCROLL_HEIGHT)
            let pos4 = yOffset - calculateSectionPosition(offeringsRef, SCROLL_HEIGHT)
          
            if (pos4 >= 0) {
              setActiveSection(OFFERINGS)
            } else if (pos3 >= 0) {
              setActiveSection(RISKS)
            } else if (pos2 >= 0) {
              setActiveSection(BUDGET)
            } else if (pos1 >= 0) {
              setActiveSection(TEAM)
            } else if (pos >= 0) {
                setActiveSection(CAST)
            }
        }

        window.addEventListener('scroll', handleScroll)
        
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
      
    useEffect(() => {
        setIsLoading(false)
        if (projectInfo?.project?.team.length > 0 && !editTeam) {
            setTeamMembers(projectInfo?.project?.team)
        }
    }, [projectInfo?.project?.team, editTeam])
    
    // submit team members if there are any adds or changes
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault()
        setIsUploading(true)

        // Filter out team members that have been changed or added
        const filteredChange = teamMembers.filter(member => member.status === CHANGE)

        const filteredNew = teamMembers.reduce((acc, member, index) => {
            if (member.status === NEW) {
              const newMember = { ...member, index }
              acc.push(newMember)
            }
            return acc
          }, [])

        let updatedTeam = []
          
        // If there are changes to existing team members, send a request to update them
        if (filteredChange.length > 0) {
            try {
                if (imageFiles.length > 0) {
                    try {
                      const fileNamesToDelete =  filteredChange.map(change => change.oldImageName)

                      await deleteImagesApi(_id, fileNamesToDelete)
                    } catch (error) {
                      console.log(error)
                    }
                }

                const response = await editTeamApi(_id, filteredChange)

                if (response.data) {
                    updatedTeam = [...response.data]
                }
            } catch (error) {  
                console.log(error)
            }
        }

        if (deleteArray.length > 0) {  
            try {
                const response = await deleteTeamApi(deleteArray, _id)

                await deleteImageApi(deleteArray, _id)

                if (response.data) {
                    updatedTeam = [...response.data.team]
                }

                setDeleteArray([])
            } catch (error) {
                console.log(error)
            }
        }

        // If there are new team members, send a request to add them
        if (filteredNew.length > 0) {
            try {
                const response = await addTeamApi(_id, filteredNew)
                if (response.data) {
                    updatedTeam = [...response.data]
                }
            } catch (error) {
                console.log(error)
            }
        }

        // If there are images to be deleted after an image change, send a request to delete them
        if (imageDeleteArray.length > 0) {
            try {
                await deleteImageApi(deleteArray, _id)
                setImageDeleteArray([])
            } catch (error) {
                console.log(error)
            }
        }

        // If there are images to be uploaded, send a request to upload them
        if (imageFiles.length > 0) {
            let formData = new FormData()

            imageFiles.forEach(image => formData.append('images', image, image.name))

            try {
                await multipleImagesApi(_id, formData)
            } catch (error) {
                console.log(error)
            }
            setImageFiles([])
        }

        setProjectInfo(prevProjectInfo => {
            return {
                ...prevProjectInfo,
                project: {
                    ...prevProjectInfo.project,
                    team: updatedTeam
                }
            }
        })

        if (teamMembers.length === 0) {
            setTeamMembers(
                [{ 
                    imageFileName: '', 
                    previewFileName: '', 
                    name: '', 
                    role: '', 
                    description: '', 
                    imageFile: [], 
                    index: 0, 
                    status: NEW 
                }]
            )
        }
        setIsUploading(false)
        setEditTeam(false)
    }, [teamMembers, deleteArray, imageDeleteArray, imageFiles, _id, setProjectInfo])

    const scrollToSection = (sectionRef, offset) => {
        const sectionPosition = sectionRef.current.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({ top: sectionPosition - offset, behavior: 'smooth' })
    }

    return (
        <Wrapper>
            {isLoading
            ?
            <LoadContainer style={{ width: '100%' }}>
                <div style={{position: "relative"}}>
                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                    </OuterSpinner>
                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </Spinner>
                </div>
            </LoadContainer>
            :
            <>
                {isUploading ? <UploadModal /> : null}
                <SectionSelect>
                    <SectionSelectButton onClick={() => scrollToSection(castRef, SCROLL_HEIGHT)} style={activeSection === CAST ? { color: PrimaryColor, fontWeight: '700' } : {}}>Cast</SectionSelectButton>
                    <SectionSelectButton onClick={() => scrollToSection(teamRef, SCROLL_HEIGHT)} style={activeSection === TEAM ? { color: PrimaryColor, fontWeight: '700' } : {}}>Team</SectionSelectButton>
                    <SectionSelectButton onClick={() => scrollToSection(budgetRef, SCROLL_HEIGHT)} style={activeSection === BUDGET ? { color: PrimaryColor, fontWeight: '700' } : {}}>Budget</SectionSelectButton>
                    <SectionSelectButton onClick={() => scrollToSection(risksRef, SCROLL_HEIGHT)} style={activeSection === RISKS ? { color: PrimaryColor, fontWeight: '700' } : {}}>Risks</SectionSelectButton>
                    <SectionSelectButton onClick={() => scrollToSection(offeringsRef, SCROLL_HEIGHT)} style={activeSection === OFFERINGS ? { color: PrimaryColor, borderBottom: 'none', fontWeight: '700' } : { borderBottom: 'none' }}>Offerings</SectionSelectButton>
                </SectionSelect>
                <HeaderContainer>
                    <Header ref={castRef}>Cast</Header>
                        <Cast 
                            project={projectInfo.project} 
                            setProjectInfo={setProjectInfo}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            loggedInUser={loggedInUser}
                        />
                    <Header ref={teamRef}>Team</Header>
                        <Team 
                            project={projectInfo.project} 
                            handleSubmit={handleSubmit} 
                            focusedIndex={focusedIndex} 
                            setFocusedIndex={setFocusedIndex} 
                            teamMembers={teamMembers} 
                            imageFiles={imageFiles} 
                            setImageFiles={setImageFiles} 
                            setTeamMembers={setTeamMembers} 
                            showImageModal={showImageModal} 
                            setShowImageModal={setShowImageModal} 
                            showEditModal={showEditModal} 
                            setShowEditModal={setShowEditModal} 
                            editTeam={editTeam} 
                            setEditTeam={setEditTeam} 
                            deleteArray={deleteArray} 
                            setDeleteArray={setDeleteArray} 
                            imageDeleteArray={imageDeleteArray} 
                            setImageDeleteArray={setImageDeleteArray} 
                            loggedInUser={loggedInUser}
                        />
                    <Header ref={budgetRef}>Budget</Header>
                        <Budget 
                            project={projectInfo.project} 
                            setProjectInfo={setProjectInfo}
                            showBudgetModal={showBudgetModal} 
                            setShowBudgetModal={setShowBudgetModal}
                            loggedInUser={loggedInUser}
                        />
                    <Header ref={risksRef}>Risks</Header>
                        <Risks projectInfo={projectInfo} setProjectInfo={setProjectInfo} loggedInUser={loggedInUser} />
                    <Header ref={offeringsRef}>Offerings</Header>
                        <Offerings project={projectInfo.project} setProjectInfo={setProjectInfo} loggedInUser={loggedInUser} />
                </HeaderContainer>
            </>
            }
        </Wrapper>
    )
}

export default ProjectDetails