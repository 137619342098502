import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { HeaderContainer, Header } from '../ProjectContainer/ProjectContainerElements'
import { CarouselContainer, ButtonContainer, Button } from './CardCarouselElements'
import DiscoverFilmCard from '../../../Discover/DiscoverFilmCard'

function CardCarousel({ children, top, title }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef()
  const [visibleCards, setVisibleCards] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    const preventScroll = (e) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault()
      }
    }

    window.addEventListener('keydown', preventScroll);
    return () => window.removeEventListener('keydown', preventScroll)
  }, [])

  useEffect(() => {
    const updateVisibleCards = () => {
      const carouselWidth = carouselRef.current.offsetWidth
      const cardWidth = 340 + 10 * 2
      const visibleCount = Math.floor(carouselWidth / cardWidth)
      setVisibleCards(visibleCount)
    }

    updateVisibleCards()
    window.addEventListener('resize', updateVisibleCards)
    return () => window.removeEventListener('resize', updateVisibleCards)
  }, [])

  const nextSlide = () => {
    const cardWidth = 340 + 10 * 2
    const remainingCards = children.length - (currentIndex + visibleCards)

    if (remainingCards <= visibleCards) {
      // If we are about to reach the end, scroll to the last card
      const lastCardIndex = children.length - 1
      const lastCardPosition = lastCardIndex * cardWidth
      setCurrentIndex(lastCardIndex)
      carouselRef.current.scrollTo({
        left: lastCardPosition,
        behavior: 'smooth',
      })
    } else {
      const nextIndex = currentIndex + visibleCards
      const nextPosition = nextIndex * cardWidth
      setCurrentIndex(nextIndex)
      carouselRef.current.scrollTo({
        left: nextPosition,
        behavior: 'smooth',
      })
    }
  }

  const prevSlide = () => {
    const cardWidth = 340 + 10 * 2

    if (currentIndex <= visibleCards) {
      // If we are about to reach the beginning, scroll to the first card
      setCurrentIndex(0)
      carouselRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      })
    } else {
      const prevIndex = currentIndex - visibleCards
      const prevPosition = prevIndex * cardWidth
      setCurrentIndex(prevIndex);
      carouselRef.current.scrollTo({
        left: prevPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', paddingTop: top }}>
        <HeaderContainer>
          <Header>{title}</Header>
        </HeaderContainer>
        <ButtonContainer>
          <Button onClick={prevSlide}>
            <MdOutlineKeyboardArrowLeft size={20} />
          </Button>
          <Button onClick={nextSlide}>
            <MdOutlineKeyboardArrowRight size={20} />
          </Button>
        </ButtonContainer>
      </div>
      <CarouselContainer ref={carouselRef}>
        {children.map((project, index) => {
          const onClick = () => {
            navigate(`/project-profile/${project._id}`)
          }
  
          return (
            <DiscoverFilmCard
              key={project._id}
              artist={project.artist}
              image={project.coverImageUrl}
              title={project.projectName}
              logline={project.logline}
              projectId={project.projectId}
              currentFunds={project.currentFunds}
              targetFundAmount={project.targetFundAmount}
              investors={project.investors}
              projectType={project.projectType}
              projectStatus={project.projectStatus}
              onClick={onClick}
              home={true}
            />
          )
        })}
      </CarouselContainer>
    </div>
  )
}

export default CardCarousel