import axios from 'axios'
import { API_URL } from '../../../../config'

export const getMyExpressedInterest = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-invest/my-expressed-interest/${projectId}`, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const getExpressedInterest = async (projectId, page, limit) => {
    try {
        const response = await axios.get(`${API_URL}/project-invest/expressed-interest/${projectId}`, {
            params: {
              page: page,
              limit: limit,
            },
        })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const getMyInvestment = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-invest/my-investment/${projectId}`, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}

export const getInvestments = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-invest/investments/${projectId}`, { withCredentials: true })

        return response
    } catch (e) {
        console.error(e)
    }
}