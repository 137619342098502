import { v4 as uuidv4 } from 'uuid'
import { CDN_URL } from '../../../../../config'

export const initialState = {
    editorMode: false,
    focusedInput: null,
    storyElements: [],
    originalStoryElements: [],
    deletedIndexes: [],
    showAddOptions: false,
    dynamicHeight: null,
    selectedElement: null,
    captionFocused: false,
    rotate: false
  }
  
  export function updateReducer(state, action) {
    switch (action.type) {
        case 'POPULATE_STORY':
            return {
                ...state,
                storyElements: action.payload,
                originalStoryElements: Array.isArray(action.payload)
                    ? action.payload.map((element) => ({ ...element }))
                    : [{ ...action.payload }]
            }
        case 'TOGGLE_EDITOR_MODE_STRICT':
            return {
                ...state,
                storyElements: state.originalStoryElements.map((element) => ({ ...element })),
                editorMode: !state.editorMode,
            }
        case 'TOGGLE_EDITOR_MODE': {
            const newEditorMode = !state.editorMode;
            let newStoryElements = state.storyElements;
            
            if (!state.editorMode) {
                // Entering edit mode
                newStoryElements = [
                    { 
                    id: uuidv4(),
                    type: 'text',
                    content: '',
                    operation: 'new',
                    index: 0,
                    }
                ].map((element, index) => ({
                    ...element,
                    index: index,
                }))
            
                const lastElement = newStoryElements[newStoryElements.length - 1];
                if (lastElement.type === 'image' || lastElement.type === 'embed') {
                newStoryElements.push({ id: uuidv4(), type: 'text', content: '', operation: 'new', index: 0 });
                }
            
                // Update originalStoryElements
                return {
                ...state,
                editorMode: newEditorMode,
                storyElements: newStoryElements,
                originalStoryElements: newStoryElements.map((element) => ({ ...element })),
                focusedInput: newEditorMode ? 0 : null,
                }
            } else if (state.storyElements.length === 0 && state.editorMode) {
                // Exiting edit mode without any changes
                newStoryElements = [{ id: uuidv4(), type: 'text', content: '', operation: 'new', index: 0 }];
            } else if (state.editorMode) {
                // Exiting edit mode and reverting to original story elements
                newStoryElements = state.originalStoryElements;
            }
            
            const newFocusedInput = newEditorMode ? 0 : null;
            return {
                ...state,
                editorMode: newEditorMode,
                storyElements: newStoryElements,
                focusedInput: newFocusedInput,
            } 
        }                                      
        case 'TOGGLE_ADD_OPTIONS':
            return {
            ...state,
            rotate: !state.rotate,
            showAddOptions: !state.showAddOptions,
            }
        case 'UNROTATE_ADD_OPTIONS':
            return {
            ...state,
            rotate: false
        }
        case 'CHANGE_ELEMENT_TYPE': {
            const { elementType, index, url, name, projectId } = action.payload
            const updatedElements = [...state.storyElements]
            updatedElements[index].type = elementType
            if (elementType === 'image') {
                updatedElements[index].content = `${CDN_URL}/projects/${projectId}/updates/${name}`
                updatedElements[index].preview = url
                updatedElements[index].caption = ''
                updatedElements[index].operation = 'add'
            } else if (elementType === 'embed') {
                updatedElements[index].embedInputValue = ''
                if (Object.prototype.hasOwnProperty.call(updatedElements[index], 'caption') && updatedElements[index].isFromDatabase) {
                    let updatedObject = {...updatedElements[index]}
                    delete updatedObject.caption
                    updatedElements[index] = updatedObject
                }
            }
            return {
                ...state,
                storyElements: updatedElements,
            }
        }
        case 'SET_FOCUSED_INPUT': {
            const previousFocusedInput = state.focusedInput
            const updatedElements = [...state.storyElements]

            if (
                previousFocusedInput !== null &&
                previousFocusedInput !== action.payload &&
                updatedElements[previousFocusedInput] &&
                (
                  (updatedElements[previousFocusedInput].type === 'title') ||
                  (
                    updatedElements[previousFocusedInput].type === 'embed' &&
                    (!Object.prototype.hasOwnProperty.call(updatedElements[previousFocusedInput], 'embedInputValue') || updatedElements[previousFocusedInput].embedInputValue === '')
                  )
                ) &&
                updatedElements[previousFocusedInput].content === ''
              ) {
                updatedElements[previousFocusedInput].type = 'text'
              }

            return {
            ...state,
            focusedInput: action.payload,
            showAddOptions: false,
            selectedElement: null
            }
        }
        case 'HANDLE_MEDIA_FOCUS': {
            return {
                ...state,
                selectedElement: action.payload,
                focusedInput: action.payload,
                showAddOptions: false,
            }
        }
        case 'HANDLE_CAPTION_FOCUS': {
            return {
                ...state,
                captionFocused: true,
            }
        }
        case 'HANDLE_CAPTION_BLUR': {
            return {
                ...state,
                captionFocused: false,
            }
        }
        case 'ADD_ELEMENT': {
            const previousFocusedInput = state.focusedInput
            const { elementType, index, cursorAtStart } = action.payload
            const newElement = {
            id: uuidv4(),
            type: elementType,
            content: '',
            operation: 'new',
            index: cursorAtStart ? index : index + 1,
            }

            const updatedElements = [...state.storyElements]

            if (cursorAtStart) {
            updatedElements.splice(index, 0, newElement)
            } else {
            updatedElements.splice(index + 1, 0, newElement)
            }

            // Recompute the indexes of the remaining elements
            const elementsWithUpdatedIndexes = updatedElements.map((element, newIndex) => ({
            ...element,
            index: newIndex,
            }))

            if (
            previousFocusedInput !== null &&
            previousFocusedInput !== action.payload &&
            previousFocusedInput < elementsWithUpdatedIndexes.length &&
            (elementsWithUpdatedIndexes[previousFocusedInput].type === 'title' || elementsWithUpdatedIndexes[previousFocusedInput].type === 'embed') &&
            elementsWithUpdatedIndexes[previousFocusedInput].content === ''
            ) {
                elementsWithUpdatedIndexes[previousFocusedInput].type = 'text'
            }

            return {
            ...state,
            storyElements: elementsWithUpdatedIndexes,
            focusedInput: cursorAtStart ? index : index + 1,
            }
        }
        case 'UPDATE_ELEMENT': {
            const { index, updatedElement } = action.payload
            const updatedElements = [...state.storyElements]
            updatedElements[index] = updatedElement
            return {
              ...state,
              storyElements: updatedElements,
            }
        }
        case 'UPDATE_ELEMENT_CONTENT': {
            const { index, content } = action.payload
            const updatedElements = [...state.storyElements]
            updatedElements[index].content = content
            updatedElements[index].operation = updatedElements[index].isFromDatabase ? 'edit' : updatedElements[index].operation
            if (updatedElements[index].type === 'embed') {
                updatedElements[index].caption = ''
                if (updatedElements[index].operation === 'new') 
                    updatedElements[index].operation = 'add'
                
            }
            return {
              ...state,
              storyElements: updatedElements,
            }
        }
        case 'UPDATE_ELEMENT_PROPERTIES': {
            const { index, ...properties } = action.payload
            const updatedElements = [...state.storyElements]
            for (const property in properties) {
                updatedElements[index][property] = properties[property]
            }
            return {
                ...state,
                storyElements: updatedElements,
            }
        }
        case 'UPDATE_EMBED_INPUT_VALUE': {
            const { index, embedInputValue } = action.payload
            const updatedElements = [...state.storyElements]
            updatedElements[index].embedInputValue = embedInputValue
    
            return {
                ...state,
                storyElements: updatedElements,
            }
        }
        case 'UPDATE_MEDIA_CAPTION': {
            const { index, caption } = action.payload
            const updatedElements = [...state.storyElements]
            updatedElements[index].caption = caption
            if (updatedElements[index].isFromDatabase) 
                updatedElements[index].operation = 'edit'
            return {
                ...state,
                storyElements: updatedElements,
            }
        }
        case 'SET_ELEMENT_TO_DELETE_IF_EMPTY': {
            const { index } = action.payload
            const updatedElements = [...state.storyElements]
            const currentElement = updatedElements[index]
          
            if (currentElement.content === '' && currentElement.isFromDatabase) {
              currentElement.operation = 'delete'
            }
          
            return {
              ...state,
              storyElements: updatedElements,
            }
        }
        case 'DELETE_ELEMENT': {
            const { index } = action.payload
            const lastIndex = state.storyElements.length - 1
            const element = state.storyElements[index - 1]
            const newFocusedInput = index > 0 ? index - 1 : 0
          
            if (
              index === lastIndex &&
              (element.type === 'image' || element.type === 'embed') &&
              Object.prototype.hasOwnProperty.call(element, 'caption')
            ) {
              // Prevent deleting the last element if type is embed or text
              return {
                ...state,
                focusedInput: newFocusedInput,
                selectedElement: newFocusedInput,
              }
            }
                
            const updatedElements = [...state.storyElements]
            updatedElements.splice(index, 1)
          
            // Recompute the indexes of the remaining elements
            const elementsWithUpdatedIndexes = updatedElements.map((element, newIndex) => {
                if (element.isFromDatabase && newIndex >= index) {
                  return {
                    ...element,
                    index: newIndex,
                  }
                } else {
                  return element
                }
            })
          
            return {
              ...state,
              storyElements: elementsWithUpdatedIndexes,
              focusedInput: newFocusedInput,
              selectedElement: newFocusedInput,
              deletedIndexes: [...state.deletedIndexes, index],
            }
          }          
      default:
        return state
    }
}