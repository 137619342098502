import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    background: white;
    border-bottom: 1px solid #ECECEC;

    @media (max-width: 768px) {
        padding: 10px;
    }
`

export const BoxBottom = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 15px 10px -15px black;

    @media (max-width: 768px) {
        box-shadow: 0px 10px 7px -10px black;
    }
`

export const FilmmakerName = styled.div`
    font-size: 25px;
    color: black;
    font-weight: bold;
    width: 300px;

    @media (max-width: 768px) {
        font-size: 20px;
        width: 100%;
        text-align: center;
    }
`

export const ArtistMiniBio = styled.div`
    top: 140px;
    margin-left: 2px;
    margin-bottom: 3px;
    font-size: 13px;
    color: black;
    width: 400px;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 400px;
        font-size: 12px;
        text-align: center;
    }
`

export const IconBar = styled.div`
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 768px) {
        justify-content: center;
    }
`

export const IconImage = styled.img`
    height: 35px;
    width: 35px;
    margin-right: 3px;
    cursor: pointer;

    @media (max-width: 768px) {
        height: 30px;
        width: 30px;
    }
`

export const Divider = styled.div`
    width: 100%;
    max-width: 950px;
    border-bottom: 1px solid #989898;
    line-height: 0.1em;
    margin: 0px 0 20px;

    @media (max-width: 768px) {
        width: 90%;
    }
`

export const LocationContainer = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    color: black;

    @media (max-width: 768px) {
        justify-content: center;
    }
`

export const RoleWrapper = styled.div`
    margin-left: 2px;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 35%;
    font-size: 12px;
    color: #3A3845;

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const Location = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
`

export const OptionContainer = styled.div`
    width: 400px;
    height: 50px;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;

    .selected {
        color: black;
    }

    .unselected {
        color: #989898;
    }

    @media (max-width: 768px) {
        width: 82.5%;
        font-size: 15px;
    }
`

export const Option = styled.div`
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
`

export const OptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Halo = styled.div`
    position: absolute;
    width: 100px;
    border-top: 2px solid black;
    line-height: 0.1em;
    margin: -21.5px 0 20px;

    @media (max-width: 768px) {
        width: 80px;
    }
`

export const EditInfo = styled.button`
    color: black;
    font-weight: bold;
    background: white;
    border: 1px solid #DFDFDE;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    cursor: pointer;

    &:hover {
        border-color: black;
    }
`

//MOBILE STYLES