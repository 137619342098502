import React, { useState } from 'react'
import { CDN_URL } from '../../../../../config'

import { 
    Card, 
    DetailContainer,
    HeaderContainer,
    PhotoContainer, 
    Photo, 
    Name, 
    Role, 
    Details 
} from './TeamCardElements' 

import {
    ArtistCardNoImageContainer
} from '../../../../DiscoverArtists/DiscoverArtistElements.js'

import { FaUser } from 'react-icons/fa'

function TeamCard({ project, imageFileName, name, role, description }) {
    const [imageError, setImageError] = useState(false)

    const handleImageError = () => {
        setImageError(true)
    }

    return (
        <Card>
            {!imageError ?
                <PhotoContainer>
                    <Photo src={`${CDN_URL}/${imageFileName}`} onError={handleImageError} />
                </PhotoContainer>
            :
                <ArtistCardNoImageContainer>
                    <FaUser size={50} />
                </ArtistCardNoImageContainer>
            }
            <DetailContainer>
                <HeaderContainer>
                    <Name>{name}</Name>
                    <Role>{role}</Role>
                </HeaderContainer>
                <Details>{description}</Details>
            </DetailContainer>
        </Card>
    )
}

export default TeamCard