import React, { useState, useEffect, useRef, useReducer } from 'react'
import useHandleUpdates from './useHandleUpdates'
import { storyReducer, initialState } from './storyReducer'
import { useDropzone } from 'react-dropzone'
import {
  Wrapper,
  StoryEditTextArea,
  EmbedOverlay,
  InputEditContainer,
  BeltWrapper,
  BeltIconContainer,
  NoOutlineImage,
  SelectedElementWrapper,
  ImageBorderWrapper,
  CaptionInput,
  CaptionTextDisplay,
  VideoWrapper,
  VideoFrame
} from './StoryElements'

import { 
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../../LoadingElement'
import { NoInfo } from '../Details/ProjectDetailElements'

import RenderedStoryElement from './RenderedStoryElement'

import { EditButton } from '../Perks/PerkElements'
import { AiFillEdit } from 'react-icons/ai'
import { HiPlus } from 'react-icons/hi'
import { MdTitle } from 'react-icons/md'
import { IoImageOutline } from 'react-icons/io5'
import { ImEmbed } from 'react-icons/im'

function Story({ projectInfo, setProjectInfo, loggedInUser }) {
    const [isLoading, setIsLoading] = useState(true)
    const [triggerInputRef, setTriggerInputRef] = useState(false)
    const [updateMade, setUpdateMade] = useState(false)
    const [deletedElementIds, setDeletedElementIds] = useState([])
    const [imageFiles, setImageFiles] = useState([])
    const [deletedMediaFiles, setDeletedMediaFiles] = useState([])
    const [state, dispatch] = useReducer(storyReducer, initialState)
    const { 
        editorMode, 
        storyElements, 
        deletedIndexes,
        showAddOptions, 
        selectedElement, 
        focusedInput,
        captionFocused,
        rotate
    } = state

    const { handleSaveChanges } = useHandleUpdates(
        projectInfo ? projectInfo._id : null,
        projectInfo ? projectInfo.projectName : null,
        projectInfo ? projectInfo.ownerPublicAddress : null,
        projectInfo ? projectInfo.slug : null,
        storyElements,
        deletedElementIds,
        imageFiles,
        dispatch,
        setUpdateMade,
        deletedIndexes,
        setProjectInfo
    )

    const inputRefs = useRef([])

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        const imageUrl = URL.createObjectURL(file)
        setImageFiles((prevFiles) => [...prevFiles, file])
        changeElementType('image', focusedInput, imageUrl, file.name)
        if (focusedInput === storyElements.length - 1) {
            dispatch({ type: 'ADD_ELEMENT', payload: { elementType: 'text', index: focusedInput } })
        }   
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
        noDrag: true,
    })

    useEffect(() => {
        if (projectInfo) {
            dispatch({ type: 'POPULATE_STORY', payload: projectInfo.storyElements })
            setIsLoading(false)
        }

    }, [projectInfo])
    
    useEffect(() => {
        if (editorMode) {
          inputRefs.current.forEach((ref, index) => {
            handleInputIncreases(inputRefs, index)
          })
        }

    }, [editorMode])
  
    useEffect(() => {
        if (editorMode && focusedInput === 0 && inputRefs.current[0]) {
            inputRefs.current[0].focus()
        }

    }, [editorMode, focusedInput])

    useEffect(() => {
        if (focusedInput !== null && inputRefs.current[focusedInput]) {
            inputRefs.current[focusedInput].focus({ preventScroll: true })
        }

    }, [showAddOptions, focusedInput, triggerInputRef])

    useEffect(() => {
        // If the last index of story elements is an image or embed, add a new text element
        if (storyElements.length > 0) {
            const lastElement = storyElements[storyElements.length - 1]
            if (lastElement.type === 'image') {
                dispatch({ type: 'ADD_ELEMENT', payload: { elementType: 'text', index: storyElements.length } })
            }
        }

    }, [storyElements])

    const handleInputIncreases = (refs, index) => {
        if (refs.current[index]) {
            if (refs.current[index].classList.contains("title")) {
              refs.current[index].style.height = "40px"
            } else {
              refs.current[index].style.height = "60px"
            }
            refs.current[index].style.paddingBottom = "20px"
            refs.current[index].style.height = refs.current[index].scrollHeight + "px"
        }
    }

    const handleInputChange = (event, index) => {
        if (storyElements[index].type === 'embed') {
            dispatch({
                type: 'UPDATE_EMBED_INPUT_VALUE',
                payload: { index, embedInputValue: event.target.value }
            })
        } else {
            dispatch({
                type: 'UPDATE_ELEMENT_CONTENT',
                payload: { index, content: event.target.value },
            })
        }

        if (
            (storyElements[index].type === 'title' || storyElements[index].type === 'embed') 
            && event.target.value.length === 0
          ) {
            dispatch({
              type: 'CHANGE_ELEMENT_TYPE',
              payload: { elementType: 'text', index },
            })
            // inputRefs.current[index].style.height = '60px'
        }
    }

    const changeElementType = (elementType, index, url = '', name) => {
        dispatch({
            type: 'CHANGE_ELEMENT_TYPE',
            payload: { 
                elementType, 
                index, 
                url, 
                name, 
                projectId: projectInfo?._id,  
            },
          })
        dispatch({ type: 'TOGGLE_AND_OPTIONS' })
        dispatch({ type: 'SET_FOCUSED_INPUT', payload: index })
    }

    const handleBlur = (element, content, index) => {
        const hasContent = content !== ''
        
        if (element.isFromDatabase) {
          if (!hasContent && !deletedElementIds.includes(element._id)) {
            setDeletedElementIds([...deletedElementIds, element._id])
            dispatch({
                type: 'SET_ELEMENT_TO_DELETE_IF_EMPTY',
                payload: { index },
            })              
          } else if (hasContent) {
            if ((projectInfo.storyElements[index] && content !== projectInfo.storyElements[index].content))
                element.operation = 'edit'
            if (deletedElementIds.includes(element._id)) {
                //remove from deletedElementIds
                setDeletedElementIds(deletedElementIds.filter((id) => id !== element._id))
                element.operation = 'edit'
            }
          }
        } 
        else if (element.operation === 'new') {
            if (hasContent) {
                element.operation = 'add'
            }
        }
        else if (element.operation === 'add') {
            if (!hasContent) {
                element.operation = 'new'
            }
        }
        dispatch({
            type: 'SET_ELEMENT_TO_DELETE_IF_EMPTY',
            payload: { index },
        }) 
        if (rotate) {
            dispatch({ type: 'UNROTATE_ADD_OPTIONS'}) 
        }
    }

    const handleKeyDown = (event, element, index) => {
        const hasContent = element.content !== ''
        if (event.key === 'Enter') {
          event.preventDefault()
      
          if (element.type === 'embed' && !('caption' in element)) {
            const embedContent = createEmbed(element.embedInputValue)
      
            if (embedContent) {
              dispatch({
                type: 'UPDATE_ELEMENT_CONTENT',
                payload: { index, content: embedContent },
              })
            }
            dispatch({
              type: 'ADD_ELEMENT',
              payload: { elementType: 'text', index },
            })
          } else {
            const input = event.target
            const cursorAtStart = input.selectionStart === 0
            const hasContent = element.content.length > 0

            if ((element.type === 'embed' && Object.hasOwn(element, 'caption')) || element.type === 'image') {
                // Add new element above the current index if the element is an embed or image
                dispatch({
                    type: 'ADD_ELEMENT',
                    payload: { elementType: 'text', index: index, cursorAtStart: true },
                })
                setTriggerInputRef(!triggerInputRef)
            } else if (cursorAtStart && hasContent) {
                // Add new element above the current index
                dispatch({
                    type: 'ADD_ELEMENT',
                    payload: { elementType: 'text', index: index, cursorAtStart: true  },
                })
                setTriggerInputRef(!triggerInputRef)
            } else {
                // Add new element below the current index
                dispatch({
                    type: 'ADD_ELEMENT',
                    payload: { elementType: 'text', index, cursorAtStart: false },
                })
            }
          }
        } else if ((event.key === 'Backspace' || event.key === 'Delete') && !captionFocused) {
            if (!deletedElementIds.includes(element._id)) {
                setDeletedElementIds([...deletedElementIds, element._id])            
            } 
            if (storyElements.length > 1) {
                if ((element.type === 'text' || element.type === 'title') && element.content === '') {
                    dispatch({
                        type: 'DELETE_ELEMENT',
                        payload: { index },
                    })
                } else if ((element.type === 'image' || element.type === 'embed') && (Object.hasOwn(element, 'caption') || element.embedInputValue === '')) {
                    setDeletedMediaFiles([...deletedMediaFiles, element])
                    dispatch({
                        type: 'DELETE_ELEMENT',
                        payload: { index },
                    })
                }
                //set the index delete counter to the current index + 1
                setTriggerInputRef(!triggerInputRef)
            } else {
                // If there's only one element and its type is 'image' or 'embed', change it to 'text'
                if ((element.type === 'image' || element.type === 'embed')) {
                    setDeletedMediaFiles([...deletedMediaFiles, element])
                    if (!hasContent && !deletedElementIds.includes(element._id)) {
                        setDeletedElementIds([...deletedElementIds, element._id])            
                    } 
                    dispatch({
                        type: 'CHANGE_ELEMENT_TYPE',
                        payload: { elementType: 'text', index },
                    })
                    dispatch({
                        type: 'UPDATE_ELEMENT_PROPERTIES',
                        payload: { index, content: '', caption: '', embedInputValue: '' },
                    })
                }  
                setTriggerInputRef(!triggerInputRef)
            } 
        }
    }

    const createEmbed = (url) => {
        if (typeof url !== 'string') {
            return
        }
        
        const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/watch_videos\?video_ids=|\/watch\?p=|\/watch\?annotation_id=|\/watch\?feature=|\/watch\?list=))(?<id>[\w-]{11})/
        const match = url.match(youtubeRegex)
    
        if (match && match.groups && match.groups.id) {
        return `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${match.groups.id}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        }
    
        return null
    }

    const extractSrc = (iframeString) => {
        const match = iframeString.match(/src="([^"]+)"/)
        return match ? match[1] : null
    }

    return (
        <Wrapper>   
        {(loggedInUser?.artist === projectInfo?.artist._id || projectInfo?.authorizedCollaborators?.includes(loggedInUser?.id)) && (
            <div style={{ display: 'flex' }}>
                <EditButton
                onClick={() => {
                    if (!updateMade) {
                    dispatch({ type: 'TOGGLE_EDITOR_MODE', projectInfo: projectInfo });
                    } else {
                    dispatch({ type: 'TOGGLE_EDITOR_MODE_STRICT', projectInfo: projectInfo });
                    }
                    if (storyElements.length === 0) {
                    dispatch({ type: 'POPULATE_EMPTY_STORY' });
                    }
                }}
                >
                <AiFillEdit size={20} style={{ marginRight: 3 }} />
                {!editorMode ? 'Edit Overview' : 'Cancel'}
                </EditButton>
                {editorMode && (
                <EditButton style={{ marginLeft: 5 }} onClick={handleSaveChanges}>
                    Save and Publish
                </EditButton>
                )}
            </div>
        )}
        {isLoading ? (
            <LoadContainer>
                <div style={{position: "relative"}}>
                    <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                        <OuterPath cx="25" cy="25" r="20" fill="none" />
                    </OuterSpinner>
                    <Spinner width="50" height="50" viewBox="0 0 50 50">
                        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </Spinner>
                </div>
            </LoadContainer>
       ) : editorMode ? (
            storyElements.map((element, index) => (
            <InputEditContainer key={element._id ? element._id : element.id}>
                {focusedInput === index && (
                <>
                    <BeltIconContainer 
                        onClick={() => dispatch({ type: 'TOGGLE_ADD_OPTIONS' })}
                        className={rotate ? 'rotate' : 'un-rotate'}
                        style={
                            element.type === 'embed' 
                            || element.type === 'image' 
                            || element.type === 'title'
                            || (element.type === 'text' && element.content.length > 0)
                            ? { display: 'none' } 
                            : {}
                        }
                    >
                        <HiPlus size={21} />
                    </BeltIconContainer>
                    {showAddOptions && focusedInput === index ? (
                    <BeltWrapper> 
                        <BeltIconContainer onClick={() => changeElementType('title', index)} className='rise'>
                            <MdTitle size={21} />
                        </BeltIconContainer>
                        <BeltIconContainer {...getRootProps()} className='rise2'>
                            <input {...getInputProps()}/>
                            <IoImageOutline size={21} />
                        </BeltIconContainer>
                        <BeltIconContainer onClick={() => changeElementType('embed', index)} className='rise3'>
                            <ImEmbed size={21} />
                        </BeltIconContainer>
                    </BeltWrapper>
                    ) : null}
                </>
                )}
                {element.type === 'embed' && element.content.includes('<iframe') ? (
                    <VideoWrapper
                        tabIndex={0}
                        onKeyDown={(event) => handleKeyDown(event, element, index)}
                        onBlur={() => handleBlur(element, element.content, index)}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        onClick={() => dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })}
                    >

                        <VideoFrame
                            src={extractSrc(element.content)}
                        />
                        <EmbedOverlay onClick={() => dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })} />
                        {selectedElement === index && <ImageBorderWrapper />}
                    </VideoWrapper>
                ) : element.type === 'image' ? (
                    focusedInput === index ? (
                        <div 
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                        >
                        <SelectedElementWrapper>
                          <NoOutlineImage
                            src={element.isFromDatabase ? element.content : element.preview}
                            ref={(ref) => inputRefs.current[index] = ref}
                            alt="Story element"
                            onMouseDown={() => {
                                dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })
                            }}
                            onBlur={() => handleBlur(element, element.content, index)}
                            tabIndex={0} 
                            onKeyDown={(event) => handleKeyDown(event, element, index)} 
                          />
                          <ImageBorderWrapper />
                        </SelectedElementWrapper>
                        <CaptionInput 
                            type="text"
                            value={element.caption || ""}
                            onChange={(event) => dispatch({ type: 'UPDATE_MEDIA_CAPTION', payload: { index, caption: event.target.value } })}
                            placeholder="Add a caption..."
                            onFocus={() => dispatch({ type: 'HANDLE_CAPTION_FOCUS' })}
                                    onBlur={() => dispatch({ type: 'HANDLE_CAPTION_BLUR' })}
                        />
                        </div>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <NoOutlineImage
                                src={element.isFromDatabase ? element.content : element.preview}
                                alt="Story element"
                                onClick={() => dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })}
                            />
                            <CaptionTextDisplay>{element.caption}</CaptionTextDisplay>
                        </div>
                      )
                ) : (
                <StoryEditTextArea
                    type="text"
                    value={element.type === 'embed' ? element.embedInputValue : element.content}
                    onChange={(event) => {
                        handleInputChange(event, index)
                        handleInputIncreases(inputRefs, index)
                    }}
                    onFocus={() => {
                        dispatch({ type: 'SET_FOCUSED_INPUT', payload: index })
                    }}
                    onBlur={() => handleBlur(element, element.content, index)}
                    style={showAddOptions && focusedInput === index ? { display: 'none' } : {}}
                    onKeyDown={(event) => handleKeyDown(event, element, index)}
                    onInput={() => handleInputIncreases(inputRefs, index)}
                    ref={(ref) => inputRefs.current[index] = ref}
                    placeholder={
                    element.type === 'title'
                        ? 'Enter a title...'
                        : element.type === 'embed'
                        ? 'Paste a video embed link and press Enter'
                        : ''
                    }
                    className={element.type === 'title' ? 'title' : ''}
                />
                )}
            </InputEditContainer>
            ))
        ) : (
            storyElements.map((element) => (
                <RenderedStoryElement key={element._id ? element._id : element.id} element={element} />
              ))
            )}
            {storyElements.length === 0 && !editorMode && <NoInfo>No story elements yet.</NoInfo>}
        </Wrapper>
    )
}

export default Story