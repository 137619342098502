export const API_URL = process.env.REACT_APP_API_URL ||  'http://localhost:5001'
//process.env.REACT_APP_API_URL || 
export const AWS_URL = process.env.REACT_APP_AWS_URL || 'https://muugenpublictestbucket.s3.us-west-1.amazonaws.com'
export const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL

export const CDN_URL = process.env.NODE_ENV === 'production' 
    ? CLOUDFRONT_URL 
    : AWS_URL
// In config.js, temporarily hardcode to test
export const GOOGLE_URL = 'https://docs.google.com/'
export const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/1G06_WjWaFMqLHEQsQ-Hc8qlMqJ4sY69blq8YQ4SVI6g/viewform?usp=pp_url&entry.1874253174='

////PROJECT ENDPOINTS /////

//STORY ENDPOINTS
export const GET_STORY_ENDPOINT = '/project/get-story/me'
export const ADD_STORY_ELEMENT_ENDPOINT = '/project/add-story-elements/me'
export const EDIT_STORY_ELEMENT_ENDPOINT = '/project/edit-story-elements/me'
export const DELETE_STORY_ELEMENT_ENDPOINT = '/project/delete-story-elements/me'

//PROJECT ENDPOINTS
export const GET_PROJECT_ENDPOINT = '/project/get-project/me'
export const EDIT_PROJECT_ENDPOINT = '/project/edit-project/me'
export const DELETE_PROJECT_ENDPOINT = '/project/delete-project/me'
export const ADD_PROJECT_ENDPOINT = '/project/add-project/me'

//BUDGET ENDPOINTS
export const EDIT_BUDGET_ENDPOINT = '/details/edit-budget/me'
export const ADD_TO_BUDGET_ENDPOINT = '/details/add-to-budget/me'
export const DELETE_FROM_BUDGET_ENDPOINT = '/details/delete-budget-section/me'

//TEAM ENDPOINTS
export const ADD_TO_TEAM_ENDPOINT = '/details/add-team-members/me'
export const EDIT_TEAM_ENDPOINT = '/details/edit-team-members/me'
export const DELETE_FROM_TEAM_ENDPOINT = '/details/delete-team-members/me'
export const DELETE_IMAGE_ENDPOINT = '/details/delete-team-members-image/me'

//RISK ENDPOINTS
export const ADD_RISK_ENDPOINT = '/details/add-risk/me'
export const EDIT_RISK_ENDPOINT = '/details/edit-risk/me'
export const DELETE_RISK_ENDPOINT = '/details/delete-risk/me'

//OFFERING ENDPOINTS
export const ADD_OFFERING_ENDPOINT = '/details/add-offering'
export const DELETE_OFFERING_ENDPOINT = '/details/delete-offering/me'

//PROJECT PERK UPDATES
export const ADD_PERK = '/project-perks/add-perk/'
export const ADD_PERK_WITH_GRAPHIC = '/project-perks/add-perk-with-graphic/'
export const UPDATE_PERK = '/project-perks/update-perk/'
export const DELETE_GRAPHIC = '/project-perks/delete-graphic/'

//TIMELINE ENDPOINTS

//UPDATES ENDPOINTS
export const GET_UPDATES_ENDPOINT = '/updates/get-updates/me'

export const ADD_UPDATE_ENDPOINT = '/updates/add-updates/me'
export const ADD_EXISTING_UPDATE_ENDPOINT = '/updates/existing-add-updates/me'

export const EDIT_EXISTING_UPDATE_ENDPOINT = '/updates/existing-edit-updates/me'

export const DELETE_EXISTING_UPDATE_ENDPOINT = '/updates/existing-delete-updates/me'
export const DELETE_MEDIA_ENDPOINT = '/updates/delete-media/me'
export const END_UPDATE_ENDPOINT = '/updates/end-update/me'
export const UPDATE_TITLE_ENDPOINT = '/updates/update-title/me'

//UPDATE COMMENTS AND LIKES ENDPOINTS
export const ADD_COMMENT_ENDPOINT = '/updates/add-comment/me'
export const ADD_LIKE_ENDPOINT = `/updates/add-like/`
export const REMOVE_LIKE_ENDPOINT = `/updates/remove-like/`

//FAQ ENDPOINTS
export const ADD_FAQ = '/project/add-faq/me'
export const DELETE_FAQ = '/project/delete-faq/me'

////PROJECT ENDPOINTS /////

//ARTIST ENDPOINTS //////

//VIDEO ENDPOINTS
export const EDIT_MAIN_VIDEO_ENDPOINT = '/profile/edit-main-embed/me'
export const EDIT_VIDEO_ENDPOINT = '/profile/add-embed/me'

//IMAGE AWS ROUTES
export const AWS_MUUGEN_TEST_URL = 'https://muugenpublictestbucket.s3.us-west-1.amazonaws.com/'
export const FILEBASE_URL = 'https://movie-invest-test.s3.filebase.com/'

export const AWS_MUUGENPUBLIC_URL = 'https://muugenpublictestbucket.s3.us-west-1.amazonaws.com/'
