import React, { useState, useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../../authContext'
import { API_URL } from '../../../../config'
import { useNavigate } from 'react-router-dom'

import {
    AccountContainer,
    OptionSection,
    OptionInfo
} from '../AccountElements'

import LoginModal from './LoginModal'
import EmailVerification from './EmailVerificationModal'

const Login = ({ authEnabled, userPage, setUserPage }) => {
    const [showModal, setShowModal] = useState(false)
    const [activeModal, setActiveModal] = useState(null)
    const { setLoggedInUser } = useContext(AuthContext)

    const navigate = useNavigate()

    const handleModalOpen = (modalType) => {
        // If it's an email change, go directly to the verification modal
        if (modalType === 'Email Password') {
            setActiveModal('EmailVerification')
        } else {
            setActiveModal(modalType)
        }
        setShowModal(true)
    }

    const handlePasswordChange = async () => {
        try {
            handleModalOpen('Password')
            await axios.post(`${API_URL}/api/email/send`, { email: userPage.email, template: "passwordReset", data: { userId: userPage._id } }, { withCredentials: true })
        } catch (error) {
            console.log(error)
        }
    }

    const handleLogout = async () => {
        try {
            await axios.post(`${API_URL}/user-profile/logout-all/me`, {}, { withCredentials: true })
            setLoggedInUser({})
            navigate('/')
        } catch (error) {
            console.log(error)
        }
    }

    const renderModal = () => {
        if (!showModal) return null
        
        if (activeModal === 'EmailVerification') {
            return (
                <EmailVerification
                    modalType={activeModal}
                    setActiveModal={setActiveModal}
                    setShowModal={setShowModal}
                    userPage={userPage}
                    setUserPage={setUserPage}
                />
            )
        }
        
        return (
            <LoginModal 
                modalType={activeModal}
                setActiveModal={setActiveModal}
                setShowModal={setShowModal}
                authEnabled={authEnabled}
                userPage={userPage}
                setUserPage={setUserPage}
            />
        )
    }

    return (
        <AccountContainer>
            {renderModal()}
            <OptionSection onClick={() => handleModalOpen('Email Password')} >
                <OptionInfo>Email</OptionInfo>
                <OptionInfo>{userPage.email}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={handlePasswordChange} >
                <OptionInfo>Password</OptionInfo>
                <OptionInfo style={{ color: '#79AC78' }}>Reset</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => !authEnabled ? handleModalOpen('2FA') : handleModalOpen('Disable 2FA Password')} >
                <OptionInfo>2FA</OptionInfo>
                <OptionInfo style={!authEnabled ? { color: '#79AC78' } : { color: 'red' }}>{!authEnabled ? 'Add' : 'Disable'}</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Phone Password')} >
                <OptionInfo>Phone</OptionInfo>
                <OptionInfo style={{ color: '#79AC78' }}>Update</OptionInfo>
            </OptionSection>
            <OptionSection onClick={handleLogout} >
                <OptionInfo></OptionInfo>
                <OptionInfo style={{ color: 'red' }}>Logout of all devices</OptionInfo>
            </OptionSection>
            <OptionSection onClick={() => handleModalOpen('Delete Account Password')} style={{ borderBottom: 'none' }}>
                <OptionInfo></OptionInfo>
                <OptionInfo style={{ color: 'red' }}>Delete Account</OptionInfo>
            </OptionSection>
        </AccountContainer>
    )
}

export default Login