import React, { useState, useEffect, useCallback, useRef } from 'react'
import { NEW, EDITED, DELETE, DATABASE } from '../../../../../constants'
import { addBudgetApi, editBudgetApi, deleteBudgetApi } from '../detailsApi'

import {
Wrapper,
ModalBg,
Modal,
Header,
HeaderText,
Body,
AddInfo,
BudgetSection,
AddButton,
AddWrapper,
DeleteButton,
} from '../../../Modal/EditModalElements'

import {
  SubmitButton,
  CancelButton
} from './BudgetModalElements.js'

import { ColorContainer, Color } from '../Budget/BudgetElements'

import { IoCloseSharp } from 'react-icons/io5'

// New smaller component
const BudgetItem = ({ item, index, handleChange, handleValueChange, handleRemoveClick }) => (
    <BudgetSection style={index > 0 ? { marginTop: 10 } : {}}>
    <div>Item {index + 1}</div>
    <AddInfo name="title" value={item.title || ''} type="text" placeholder="Item" onChange={(e) => handleChange(e, index)} />
    <AddInfo name="value" value={item.value || ''} type="number" placeholder="Amount" onChange={(e) => handleValueChange(e, index)} />
    <ColorContainer>
        <AddInfo name="color" value={item.color || ''} placeholder="Color" onChange={(e) => handleChange(e, index)} />
        <Color color={item.color} />
    </ColorContainer>
    <DeleteButton type="button" onClick={() => handleRemoveClick(index)}>Remove</DeleteButton>
    </BudgetSection>
)

function BudgetModal({ 
  project, 
  setProjectInfo, 
  setShowModal, 
  setChartData, 
  budgetArray, 
  setBudgetArray, 
  isSubmitFired, 
  setIsSubmitFired 
}) {

  const [deleteArray, setDeleteArray] = useState([])

  useEffect(() => {
  if (!isSubmitFired && project.budgetAllocation.length > 0) {
      let tempArray = [...project.budgetAllocation]
      tempArray.forEach((budget, index) => {
      budget.status = DATABASE
      budget.index = index
      })
      tempArray = [...tempArray]
      setBudgetArray(tempArray)
  }
  }, [isSubmitFired, project.budgetAllocation, setBudgetArray])

  const modalRef = useRef(null)

  const updateChartData = () => {
  // const validChartData = budgetArray.filter((data) => !isNaN(data.value) && data.value !== null && data.value !== undefined)
  setChartData([...budgetArray])
  }

  const isValidBudgetItem = (item) => {
    return item && item.color && item.title && item.value
  }

  const handleSubmit = async (e) => {
      e.preventDefault()

      const filteredNew = budgetArray.filter(budget => 
        budget.status === NEW && 
        budget.color && 
        budget.title && 
        budget.value
      )
      const filteredEdited = budgetArray.filter(budget => budget.status === EDITED)

      if (filteredEdited.length > 0) {
          try {
              await editBudgetApi(filteredEdited, project._id)
              setBudgetArray(prev => prev.map(budget => {
                  const editedBudget = filteredEdited.find(item => item._id === budget._id)
                  return editedBudget || budget
              }))
              updateChartData()
          } catch (err) {
              console.log(err)
          }
      }

      if (filteredNew.length > 0) {
          try {
              await addBudgetApi(filteredNew, project._id)
              setBudgetArray(prev => prev.filter(budget => budget.status !== NEW).concat(filteredNew.map(item => ({ ...item, status: DATABASE }))))
              updateChartData()
          } catch (err) {
              console.log(err)
          }
      }

      if (deleteArray.length > 0) {
          const deleteIds = deleteArray.map(item => item._id)
          try {
              await deleteBudgetApi(deleteIds, project._id)
              setBudgetArray(prev => prev.filter(budget => !deleteIds.includes(budget._id)))
              updateChartData()
          } catch (err) {
              console.log(err)
          }
      }

      const validBudgetItems = budgetArray.filter(isValidBudgetItem)

      setProjectInfo(prevProjectInfo => {
        return {
            ...prevProjectInfo,
            project: {
                ...prevProjectInfo.project,
                budgetAllocation: validBudgetItems
            }
        }
      })
      setIsSubmitFired(true)
      document.body.style.overflow = 'visible'
      setShowModal(false)
  }

  const handleAddClick = () => {
      setBudgetArray([...budgetArray, { title: '', value: 0, color: '', status: NEW }])
  }

  const handleRemoveClick = useCallback((index) => {
      let tempArray = [...budgetArray]
      if (tempArray[index].status === DATABASE) {
        tempArray[index].status = DELETE
        setDeleteArray([...deleteArray, tempArray[index]])
      }
      if (tempArray.length === 1) {
        tempArray[index] = {
          ...tempArray[index],
          color: '',
          title: '',
          value: '',
          status: DELETE
        }
      } else {
        tempArray.splice(index, 1)
      }
      setBudgetArray(tempArray)
  }, [budgetArray, deleteArray, setBudgetArray])

  const handleChange = useCallback((e, index) => {
      const { name, value } = e.target


      setBudgetArray((prevBudgetArray) => {
        // Clone the previous array to maintain immutability
        const newBudgetArray = [...prevBudgetArray]
    
        // Directly update the item at the specified index
        if (newBudgetArray[index]) {
            newBudgetArray[index] = {
                ...newBudgetArray[index],
                [name]: value,
                status: newBudgetArray[index].status !== NEW ? EDITED : newBudgetArray[index].status,
            }
        }
    
        return newBudgetArray
      })
  }, [setBudgetArray])

  const handleValueChange = useCallback((e, index) => {
      const { name, value } = e.target

          // Ensure that the value is always a number
          const newValue = parseFloat(value)

          setBudgetArray(prev => {
              const updatedBudgetArray = [...prev]
              updatedBudgetArray[index] = {
              ...updatedBudgetArray[index],
              [name]: newValue,
              status: updatedBudgetArray[index].status !== NEW ? EDITED : updatedBudgetArray[index].status,
              }
              return updatedBudgetArray
      })
  }, [setBudgetArray])

  const closeModal = useCallback((e) => {
    if (modalRef.current === e.target) {
      document.body.style.overflow = 'visible'
      setShowModal(false)
    }
  }, [setShowModal])

  const handleExitClick = useCallback(() => {
    document.body.style.overflow = 'visible'
    setShowModal(false)
  }, [setShowModal])

  return (
    <Wrapper>
      <ModalBg ref={modalRef} onClick={closeModal}>
        <Modal style={{ top: '100px' }}>
          <Header>
            <HeaderText>Edit Budget</HeaderText>
            <IoCloseSharp size={25} style={{ cursor: 'pointer' }} onClick={handleExitClick} />
          </Header>
          <Body style={{ padding: 10 }}>
              {budgetArray.map((item, index) => (
                <React.Fragment key={item._id ? item._id : `new-${index}`}>
                  <BudgetItem
                    item={item}
                    index={index}
                    handleChange={handleChange}
                    handleValueChange={handleValueChange}
                    handleRemoveClick={handleRemoveClick}
                  />
                  {index + 1 === budgetArray.length ? (
                    <AddWrapper>
                      <AddButton color="#36AE7C" style={{ cursor: 'pointer', width: '50%' }} onClick={handleAddClick}>
                        Add Item
                      </AddButton>
                    </AddWrapper>
                  ) : null}
                </React.Fragment>
              ))}
              <SubmitButton onClick={handleSubmit}>Update</SubmitButton>
              <CancelButton>Cancel</CancelButton>
          </Body>
        </Modal>
      </ModalBg>
    </Wrapper>
)}

export default BudgetModal