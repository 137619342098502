import styled from 'styled-components'

export const Wrapper = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: white;
    padding: 20px;
    box-sizing: border-box;
`

export const ProfileInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 25px;

    @media (max-width: 500px) {
        margin-top: -20px;
    }
     // Adjust this to match the profile picture size
`

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 16 / 4;
`

export const CoverImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ProfileHeaderDetailContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    margin-top: 50px; // Adjust this value to fine-tune the overlap
`

export const ProfileHeaderDetail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 769px) {
        flex-direction: row;
        align-items: flex-start;
    }
`

export const IconBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;

  a {
    margin: 5px;
  }

  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`

//MOBILE STYLES

export const ProfilePictureWrapper = styled.div`
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
`

export const MobileHeaderDetailContainer = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    /* max-width: 900px; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const MobilePictureContainer = styled.div`
    position: relative;
    margin-top: -50px; // Reduced from -75px to move it up less
    height: 100px; // Reduced from 150px
    width: 100px; // Reduced from 150px
    border-radius: 50%;
    border: solid 3px white; // Slightly reduced border width
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
`

export const MobilePicture = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
`

export const MobileEdit = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background: white;
  border: 1px solid #DFDFDE;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: black;
  }

  /* @media (min-width: 769px) {
    width: auto;
    padding: 20px 20px;
  } */
`

export const FollowButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`

//MODAL STYLES
export const ModalContainer = styled.div`
    position: absolute;
    top: 45px;
    margin-left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
`

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 15px 40px;
    border-radius: 5px;

    &:hover {
        background: #F5F5F5;
    }
`

export const SelectText = styled.p`
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #272829;
`