import axios from 'axios'

import { 
    API_URL, 
    EDIT_TEAM_ENDPOINT, 
    DELETE_FROM_TEAM_ENDPOINT, 
    DELETE_IMAGE_ENDPOINT,
    ADD_TO_TEAM_ENDPOINT,
    ADD_TO_BUDGET_ENDPOINT,
    EDIT_BUDGET_ENDPOINT,
    DELETE_FROM_BUDGET_ENDPOINT,
    ADD_RISK_ENDPOINT,
    EDIT_RISK_ENDPOINT,
    DELETE_RISK_ENDPOINT,
    ADD_OFFERING_ENDPOINT,
    DELETE_OFFERING_ENDPOINT 
} from '../../../../config'

//DETAILS FILE

//TEAM APIS
export const editTeamApi = async (projectId, update) => {
    const payload = {
        projectId,
        update
    }

    return axios.post(`${API_URL}${EDIT_TEAM_ENDPOINT}`, payload, { withCredentials: true })
}

export const deleteTeamApi = async (deleteArray, projectId) => {
    const payload = {
        deleteArray,
        projectId
    }

    return axios.post(`${API_URL}${DELETE_FROM_TEAM_ENDPOINT}`, payload, { withCredentials: true })
}

export const deleteImageApi = async (deleteArray, projectId) => {
    const payload = {
        deleteArray,
        projectId
    }

    return axios.post(`${API_URL}${DELETE_IMAGE_ENDPOINT}`, payload, { withCredentials: true })
}

export const addTeamApi = async (projectId, team) => {
    const payload = {
        projectId,
        team
    }

    return axios.post(`${API_URL}${ADD_TO_TEAM_ENDPOINT}`, payload, { withCredentials: true })
}

export const multipleImagesApi = async (projectId, formData) => {
    return axios.post(`${API_URL}/details/multi-graphic/${projectId}`, formData, {
        headers: {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'text/html' 
        },
        withCredentials: true
    })
}

//CAST APIS
export const addCastApi = async (projectId, cast) => {
    const payload = {
        projectId,
        cast
    }

    return axios.post(`${API_URL}/details/add-cast-members/me`, payload, { withCredentials: true })
}

export const editCastApi = async (projectId, update) => {
    const payload = {
        projectId,
        update
    }

    return axios.post(`${API_URL}/details/edit-cast-members/me`, payload, { withCredentials: true })
}

export const deleteCastApi = async (deleteArray, projectId) => {
    const payload = {
        deleteArray,
        projectId
    }

    return axios.post(`${API_URL}/details/delete-cast-members/me`, payload, { withCredentials: true })
}

export const deleteImagesApi = async (projectId, imageFileNames) => {
    return axios.post(`${API_URL}/details/delete-images/me`, { projectId, imageFileNames }, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
}

//BUDGET APIS
export const addBudgetApi = async (budgetArray, projectId) => {
    const payload = {
        budgetArray,
        projectId,
    }

    return axios.post(`${API_URL}${ADD_TO_BUDGET_ENDPOINT}`, payload, { withCredentials: true } )
}

export const editBudgetApi = async (update, projectId) => {
    const payload = {
        update,
        projectId
    }

    return axios.post(`${API_URL}${EDIT_BUDGET_ENDPOINT}`, payload, { withCredentials: true } )
}

export const deleteBudgetApi = async (deleteIds, projectId) => {
    const payload = {
        deleteIds,
        projectId
    }

    return axios.post(`${API_URL}${DELETE_FROM_BUDGET_ENDPOINT}`, payload, { withCredentials: true } )
}

//RISKS
export const addRiskApi = async (risks, projectId) => {
    const payload = {
        risks,
        projectId
    }

    return axios.post(`${API_URL}${ADD_RISK_ENDPOINT}`, payload, { withCredentials: true })
}

export const editRiskApi = async (update, projectId) => {
    const payload = {
        update,
        projectId
    }

    return axios.post(`${API_URL}${EDIT_RISK_ENDPOINT}`, payload, { withCredentials: true })
}

export const deleteRiskApi = async (deletes, projectId) => {
    const payload = {
        deletes,
        projectId
    }

    return axios.post(`${API_URL}${DELETE_RISK_ENDPOINT}`, payload, { withCredentials: true })
}

//OFFERINGS
export const getOfferingApi = async (projectId, fileName) => {
    try {
      const response = await axios.get(`${API_URL}/details/offering/${projectId}/${fileName}`, {
        responseType: 'blob',
      })
      return response.data
    } catch (error) {
      console.error('Error fetching offering:', error)
      throw error
    }
}

export const addOfferingsApi = async (projectId, formData) => {
    return axios.post(`${API_URL}${ADD_OFFERING_ENDPOINT}/${projectId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
    })
}

export const deleteOfferingApi = async (offeringId, projectId, fileName) => {
    const payload = {
        offeringId: offeringId[0],
        projectId,
        fileName,
    }

    return axios.post(
        `${API_URL}${DELETE_OFFERING_ENDPOINT}`,
        payload,
        { withCredentials: true }
    )
} 