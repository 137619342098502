import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ObjectID } from 'bson'
import { addPerkWithGraphic, addPerk } from '../PerksApi'
import { 
    CDN_URL
} 
from '../../../../../config'

import { 
    Modal, 
    Header, 
    HeaderText, 
    Body,
    BodySection,
} from '../../../Modal/EditModalElements'

import { 
    AddPerkSection, 
    AddPerkTitle,
    AddPerkInput, 
    AddPerkTextBox,
    PhotoSection,
    UploadContainer,
    PerkImage,
    AddPerkButton,
    CancelPerkButton
} from '../PerkElements'

import { TbPhoto, TbPhotoOff } from 'react-icons/tb'
import { MdUpload } from 'react-icons/md'
import { IoIosArrowBack } from 'react-icons/io'
import { IoCloseSharp } from 'react-icons/io5'

function AddPerk({ setShowModal, setShowAddPerk, projectInfo, perkArray, setPerkArray }) {
    const [showPhotoOption, setShowPhotoOption] = useState(true)
    const [showAddPhoto, setShowAddPhoto] = useState(false)
    const [noPhoto, setNoPhoto] = useState(false)
    const [imageFile, setImageFile] = useState([])
    const [header, setHeader] = useState('')
    const [amount, setAmount] = useState(0)
    const [details, setDetails] = useState('')
    const [estimatedDelivery, setEstimatedDelivery] = useState('')
    const [shipsTo, setShipsTo] = useState('')

    const [isHeaderValid, setIsHeaderValid] = useState(true)
    const [isAmountValid, setIsAmountValid] = useState(true)
    const [isDetailsValid, setIsDetailsValid] = useState(true)
    const [isEstimatedDeliveryValid, setIsEstimatedDeliveryValid] = useState(true)
    const [isShipsToValid, setIsShipsToValid] = useState(true)

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles, fileRejections) => {
            // Reset image error state
    
            // Process accepted files
            if (acceptedFiles.length > 0) {
                setImageFile(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })))
            }
    
            // Handle any file rejections
            if (fileRejections.length > 0) {
                const errors = fileRejections.map(rejection => rejection.errors.map(error => error.message))
                alert(errors.flat().join('\n'))
            }
        }
    })

    let renderSection

    const handleClose = () => {
        document.body.style.overflow = 'visible'
        setShowModal(false)
    }

    const handleBack = () => {
        if (showAddPhoto) {
            setShowAddPhoto(false)
            setShowPhotoOption(true)
            setNoPhoto(false)
            setImageFile([])
        } else if (noPhoto) {
            setNoPhoto(false)
            setShowPhotoOption(true)
        } else {
            setShowPhotoOption(false)
            setShowAddPerk(false)
        }
    }

    const handleAmountChange = (e) => {
        const value = e.target.value
        // Regular expression to match only numeric characters
        const re = /^[0-9\b]+$/
    
        // If value is empty or it matches the regex, update the state
        if (value === '' || re.test(value)) {
            setAmount(value)
        }
    }

    const handleAmountKeyPress = (e) => {
        // Regular expression to allow only numeric characters
        if (!/^[0-9]+$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
            e.preventDefault()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const isHeaderEmpty = header.trim() === ''
        const isAmountEmpty = amount <= 0; // Assuming amount should be greater than 0
        const isDetailsEmpty = details.trim() === ''
        const isEstimatedDeliveryEmpty = estimatedDelivery.trim() === ''
        const isShipsToEmpty = shipsTo.trim() === ''

        // Update validation states
        setIsHeaderValid(!isHeaderEmpty)
        setIsAmountValid(!isAmountEmpty)
        setIsDetailsValid(!isDetailsEmpty)
        setIsEstimatedDeliveryValid(!isEstimatedDeliveryEmpty)
        setIsShipsToValid(!isShipsToEmpty)

        // Check if all inputs are valid before proceeding
        if(isHeaderEmpty || isAmountEmpty || isDetailsEmpty || isEstimatedDeliveryEmpty || isShipsToEmpty) {
            return // Stop submission if any input is invalid
        }

        const id = new ObjectID()

        try {
            if (imageFile.length > 0) {
                let data = new FormData()
                const file = imageFile[0]

                data.append('uploadGraphic', file, imageFile.name)

                // Prepare the perk data
                const perk = {
                    _id: id.toString(),
                    imageFile: imageFile[0].path,
                    imagePath: `${CDN_URL}/projects/${projectInfo.project._id}/perks/${imageFile[0].path}`,
                    header,
                    amount,
                    details,
                    estimatedDelivery,
                    shipsTo,
                    unlocks: 0
                }

                // Append the perk data as a JSON string
                data.append('perk', JSON.stringify(perk))

                data.append('projectId', projectInfo?.project?._id)

                // Axios POST request with FormData
                try {
                    const response = await addPerkWithGraphic(data, projectInfo?.project?._id)

                    // Handle the response
                    // Assuming you want to update the state with the new perk
                    setPerkArray(response.data)
                } catch (error) {
                    console.error('Error:', error)
                }
            } else {
                const perk = {
                    _id: id.toString(),
                    header,
                    amount: amount,
                    details: details,
                    estimatedDelivery,
                    shipsTo,
                    unlocks: 0
                }

                const payload = {
                    projectId: projectInfo.project._id,
                    perk
                }

                const response = await addPerk(payload)
                setPerkArray(response.data)
            }

            setShowModal(false)
            document.body.style.overflow = 'visible'
        } catch (e) {
            console.log(e)
        }
    }

    if (noPhoto || imageFile.length > 0) {
        renderSection = 
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    {imageFile.length > 0 ?
                    <AddPerkSection>
                        <AddPerkTitle>Perk Image</AddPerkTitle>
                        <PerkImage style={{ objectFit: 'cover' }} src={imageFile[0].preview} alt='preview'/>
                    </AddPerkSection>
                    : 
                    null
                    }
                    <AddPerkSection>
                        <AddPerkTitle isInvalid={!isHeaderValid}>Perk Header</AddPerkTitle>
                        <AddPerkInput placeholder='Enter a header...' onChange={(e) => setHeader(e.target.value)}/>
                    </AddPerkSection>
                    <AddPerkSection>
                        <AddPerkTitle isInvalid={!isAmountValid}>Perk Amount</AddPerkTitle>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            &#36;
                            <AddPerkInput placeholder='0' style={{ marginLeft: 5 }} onChange={handleAmountChange} onKeyPress={handleAmountKeyPress} />
                        </div>
                    </AddPerkSection>
                    <AddPerkSection>
                        <AddPerkTitle isInvalid={!isDetailsValid}>Perk Details</AddPerkTitle>
                        <AddPerkTextBox placeholder='Enter perk details...' onChange={(e) => setDetails(e.target.value)}/>
                    </AddPerkSection>
                    <AddPerkSection>
                        <AddPerkTitle isInvalid={!isEstimatedDeliveryValid}>Estimated Delivery</AddPerkTitle>
                        <AddPerkInput placeholder='January 2024...' onChange={e => setEstimatedDelivery(e.target.value)} />
                    </AddPerkSection>
                    <AddPerkSection>
                        <AddPerkTitle isInvalid={!isShipsToValid}>Perk Ships To</AddPerkTitle>
                        <AddPerkInput placeholder='Anywhere in the World...' onChange={e => setShipsTo(e.target.value) }/>
                    </AddPerkSection>
                    <AddPerkSection>
                        <AddPerkButton>Add Perk</AddPerkButton>
                        <CancelPerkButton type='button' onClick={handleBack}>Cancel</CancelPerkButton>
                    </AddPerkSection>
                </form>
            </React.Fragment>
    } else if (showAddPhoto) {
        renderSection = 
            <React.Fragment>
                {imageFile.length === 0 ?
                <div style={{ padding: 20 }} >
                    <PhotoSection {...getRootProps()}>
                        <input {...getInputProps()}/>
                        <UploadContainer>
                            <MdUpload size={30}/>
                        </UploadContainer>
                        <div style={{ fontSize: 13 }}>Drag and drop image or click to upload</div>
                    </PhotoSection>
                </div>
                : null
                }
        </React.Fragment>
    } else if (showPhotoOption && imageFile.length === 0) {
        renderSection = 
            <React.Fragment>
                <BodySection onClick={() => setShowAddPhoto(true)}><TbPhoto size={20}/>&nbsp;Add Photo</BodySection>
                <BodySection onClick={() => setNoPhoto(true)}><TbPhotoOff size={20}/>&nbsp;No Photo</BodySection>
            </React.Fragment>
    }

    return (
        <Modal>
            <Header>
                <HeaderText onClick={handleBack} style={{ display: 'flex', alignItems: 'center' }}><IoIosArrowBack size={25} /> Add Perk</HeaderText>
                <IoCloseSharp onClick={handleClose} size={25} style={{ cursor: 'pointer' }}/>
            </Header>
            <Body>
                {renderSection}
            </Body>
        </Modal>
    )
}

export default AddPerk