import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { 
    ProjectWrapper, 
    Info,
    InfoWrapper,
    ProjectImageWrapper,
    ProjectImage,
    ImageOverlay,
    BottomInfo,
    ImageText
} from '../Portfolio/Project/ProjectElements.js'

const Project = ({ project }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [percentageFunded, setPercentageFunded] = useState(0)
    const [imgUrl, setImgUrl] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        const percentage = (project?.amount / project?.project?.targetFundAmount) * 100

        setPercentageFunded(Math.ceil(percentage))

        setImgUrl(`${project?.project?.coverImageUrl}`)
    }, [project])

    const handleProjectClick = () => {
        navigate(`/project-profile/${project?.project?._id}`)
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const date = new Date(dateString)
        const formattedDate = date.toLocaleDateString('en-US', options)
        
        // Add the ordinal suffix (e.g., "st", "nd", "rd", "th")
        const day = date.getDate()
        const ordinalSuffix = getOrdinalSuffix(day)
        
        return formattedDate.replace(/\b\d+\b/, `${day}${ordinalSuffix}`)
      }
      
      const getOrdinalSuffix = (day) => {
        if (day >= 11 && day <= 13) {
          return 'th'
        }
        switch (day % 10) {
          case 1: return 'st'
          case 2: return 'nd'
          case 3: return 'rd'
          default: return 'th'
        }
    }
 
    return (
        <ProjectWrapper 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleProjectClick}
            style={{ height: '100%', width: '100%' }} 
        >
            <InfoWrapper>
                <Info>{project?.project?.projectName}</Info>
                <Info>${project?.amount}</Info>
            </InfoWrapper>
            <ProjectImageWrapper style={{ height: '100%', width: '100%' }}>
                {imgUrl && <ProjectImage style={{ height: '100%', width: '100%' }} src={imgUrl} />}
                <ImageOverlay style={{ height: '100%' }} isVisible={isHovered}>
                    <ImageText>{percentageFunded}% funded</ImageText>
                </ImageOverlay>
            </ProjectImageWrapper>
            <InfoWrapper>
                <BottomInfo>
                    <div style={{ fontFamily: "'DM sans', serif" }}>You expressed interest on {formatDate(project?.date)}</div>
                </BottomInfo>
            </InfoWrapper>
        </ProjectWrapper>
    )

}

export default Project
