import React, { useState, useContext } from 'react'
import { AuthContext } from '../../authContext'
import { useNavigate } from 'react-router-dom'
import { CLOUDFRONT_URL } from '../../config'
import { formatNumber } from '../../helpers/numberConvert'

import {
    UserCardContainer,
    UserCardMainContent,
    UserCardFooter,
    UserCardNoImageContainer,
    UserCardImage,
    UserCardName,
    UserMiniBio,
    Divider,
    InfoWrapper,
    FollowButton,
    Followers,
    Invested
} from './DiscoverUsersElements'

import { FaUser } from 'react-icons/fa'

const UserCard = ({ user, isFollowing, onFollowToggle }) => {
    const { loggedInUser } = useContext(AuthContext)
    const [imageError, setImageError] = useState(false)
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(`/user-profile/${user?.username}`)
    }

    const handleImageError = () => {
        setImageError(true)
    }

    const handleFollowButtonClick = async (event) => {
        event.stopPropagation()
        if (!loggedInUser?.loggedIn) {
            navigate('/login')
            return
        }
        onFollowToggle(isFollowing)
    }

    const truncatedMiniBio = user?.miniBio && user?.miniBio?.length > 100
        ? `${user?.miniBio?.substring(0, 100)}...`
        : user?.miniBio

    const imageUrl = user?.profilePictureUrl || 
        (user?.profilePicture && user.profilePicture !== 'null' ? 
            `${CLOUDFRONT_URL}/${user.profilePicture}` 
            : null
        )

    console.log(user)

    return (
        <UserCardContainer onClick={handleNavigate}>
            <UserCardMainContent>
                {!imageError && imageUrl ?
                    <UserCardImage src={imageUrl} alt={user?.username} onError={handleImageError} />
                    :
                    <UserCardNoImageContainer>
                        <FaUser size={50} color='white' />
                    </UserCardNoImageContainer>
                }
                <UserCardName>{user?.displayName ? user?.displayName : user?.username}</UserCardName>
                <UserMiniBio>{truncatedMiniBio}</UserMiniBio>
            </UserCardMainContent>
            <UserCardFooter>
                <Divider />
                {loggedInUser?.id && loggedInUser.id !== user._id && (
                    <FollowButton 
                        onClick={handleFollowButtonClick} 
                        className={isFollowing ? 'unfollow' : 'follow'}
                    >
                        {isFollowing ? 'Following' : 'Follow'}
                    </FollowButton>
                )}
                <InfoWrapper>
                    <Followers>{user.followersCount} followers</Followers>
                    {user.totalInvestment > 0 && (
                        <Invested>
                            <div style={{ color: 'black', marginRight: 4 }}>
                                ${formatNumber(user.totalInvestment)}
                            </div> 
                            invested
                        </Invested>
                    )}
                </InfoWrapper>
            </UserCardFooter>
        </UserCardContainer>
    )
}

export default UserCard