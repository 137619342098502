import React, { useState, useContext, useEffect, lazy, Suspense } from 'react'
import { API_URL } from '../config'
import { AuthContext } from '../authContext'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import LazyLoaderModal from '../components/LazyLoaderModal'

import NonMainNavbar from '../components/NonMainNav'
import ProfileHeader from '../components/ArtistProfile/ProfileHeader'
import ProfileInfo from '../components/ArtistProfile/ProfileInfo'
import Projects from '../components/ArtistProfile/ArtistProjects'
import Videos from '../components/ArtistProfile/Videos'
import About from '../components/ArtistProfile/About'

const ProfileEditModal = lazy(() => import('../components/ArtistProfile/Modal'))

function ArtistProfile() {
    // can update pass all data in single array to components
    const [isProjects, setIsProjects] = useState(true)
    const [isVideos, setIsVideos] = useState(false)
    const [isBio, setIsBio] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [socialChange, setSocialChange] = useState(false)
    const [infoChange, setInfoChange] = useState(false)
    const [artistAssets, setArtistAssets] = useState([])
    const [isFollowing, setIsFollowing] = useState(false)
    const [followCount, setFollowCount] = useState(0)

    const { loggedInUser } = useContext(AuthContext)

    const { slug } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let didCancel = false
    
        const getProfile = async () => {
            try {
                const response = await axios.get(`${API_URL}/profile/${slug}`, { withCredentials: true })
                if (!didCancel) {
                    setArtistAssets(response.data)
                }
                if (loggedInUser.loggedIn) {

                    const followResponse = await axios.get(`${API_URL}/investment-profile/following-artist-profile/me/${response?.data?.slug}`, {
                        withCredentials: true,
                    })

                    setIsFollowing(followResponse.data.isFollowing)
                    setFollowCount(followResponse.data.followerCount)
                }
                // setIsFollowing(isFollowingArtist)
            } catch (error) {
                console.log(error)
            }
        }
        getProfile()
    
        return () => {
            didCancel = true
        }

    //eslint-disable-next-line
    }, [slug, socialChange, infoChange])

    // Component selection handler, can be a helper
    const handleProjectsClick = () => {
        if (!isProjects) {
            setIsProjects(true)
            setIsVideos(false)
            setIsBio(false)
        }
    }

    const handleVideosClick = () => {
        if (!isVideos) {
            setIsVideos(true)
            setIsProjects(false)
            setIsBio(false)
        }
    }

    const handleBioClick = () => {
        if (!isBio) {
            setIsBio(true)
            setIsProjects(false)
            setIsVideos(false)
        }
    }

    let render

    if (isProjects) {
        render = <Projects 
                    projects={artistAssets.projects} 
                    loggedInUser={loggedInUser}
                    slug={artistAssets.slug}
                    artistId={artistAssets._id}
                />
    } else if (isVideos) {
        render = <Videos 
                    mainVideoTitle={artistAssets.mainVideoTitle} 
                    mainVideoEmbed={artistAssets.mainVideoEmbed} 
                    setArtistAssets={setArtistAssets}
                    videoEmbeds={artistAssets.videoEmbeds}
                    loggedInUser={loggedInUser}
                    artistId={artistAssets._id}
                />
    } else {
        render = <About 
                    embedVideo={artistAssets.aboutEmbedVideo}
                    miniBio={artistAssets.fullBio} 
                    projectsNumber={artistAssets.projects.length}
                    followCount={followCount}
                    setArtistAssets={setArtistAssets}
                    awards={artistAssets.awards} 
                    loggedInUser={loggedInUser}
                    slug={artistAssets.slug}
                    artistId={artistAssets._id}
                />
    }
   
    return (
        <div style={{ overflowX: 'hidden', background: 'white', outlineColor: 'red'  }}>
            <NonMainNavbar loggedInUser={loggedInUser} />
            {
            <Suspense fallback={<LazyLoaderModal />}>
                <ProfileEditModal 
                    profileImage={artistAssets.artistProfileImage}
                    coverImage={artistAssets.profileCoverImage}
                    showEditModal={showEditModal} 
                    setShowEditModal={setShowEditModal} 
                    slug={slug}
                    setArtistAssets={setArtistAssets}
                    artistName={artistAssets.artistName}
                    artistAddress={artistAssets.artistAddress}
                    artistRoles={artistAssets.artistRoles}
                    artistMiniBio={artistAssets.artistMiniBio}
                    artistLocation={artistAssets.artistLocation}
                    setSocialChange={setSocialChange}
                    setInfoChange={setInfoChange}
                    twitterLink={artistAssets.twitterLink}
                    facebookLink={artistAssets.facebookLink}
                    vimeoLink={artistAssets.vimeoLink}
                    instagramLink={artistAssets.instagramLink}
                    youtubeLink={artistAssets.youtubeLink}
                    imdbLink={artistAssets.imdbLink}
                    websiteLink={artistAssets.websiteLink}
                />
            </Suspense>
            }
            <ProfileHeader 
                profileImage={artistAssets.artistProfileImage}
                coverImage={artistAssets.profileCoverImage}
                artistName={artistAssets.artistName}
                artistMiniBio={artistAssets.artistMiniBio}
                artistLocation={artistAssets.artistLocation}
                artistId={artistAssets._id}
                slug={slug}
                artistRoles={artistAssets.artistRoles}
                showEditModal={showEditModal}
                setShowEditModal={setShowEditModal}
                twitterLink={artistAssets.twitterLink}
                facebookLink={artistAssets.facebookLink}
                vimeoLink={artistAssets.vimeoLink}
                instagramLink={artistAssets.instagramLink}
                youtubeLink={artistAssets.youtubeLink}
                imdbLink={artistAssets.imdbLink}
                websiteLink={artistAssets.websiteLink}
                loggedInUser={loggedInUser}
                isFollowing={isFollowing}
                setIsFollowing={setIsFollowing}
            />
            <ProfileInfo 
                isProjects={isProjects} 
                isVideos={isVideos}  
                isBio={isBio}
                handleProjectsClick={handleProjectsClick}
                handleBioClick={handleBioClick}
                handleVideosClick={handleVideosClick}
            />
            {render}
        </div>
    )
}

export default ArtistProfile