import React, { useState, useEffect, useRef } from 'react'
import { addRiskApi, editRiskApi, deleteRiskApi } from '../detailsApi'
import { NEW, EDITED, DATABASE, DELETE } from '../../../../../constants'
import { ObjectID } from 'bson'

import { Wrapper, RiskInput, RiskHeader, RiskText } from './RiskElements'
import { HeaderWrapper } from '../ProjectDetailElements'
import { NoInfo } from '../ProjectDetailElements'
import { EditButton } from '../../Perks/PerkElements'
import { AiFillEdit } from 'react-icons/ai'

function Risks({ projectInfo, setProjectInfo, loggedInUser }) {
    const [riskEdit, setRiskEdit] = useState(false)
    const [riskArray, setRiskArray] = useState([{ riskTitle: '', riskDetail: '', status: NEW, id: new ObjectID().toString(), index: 0 }])
    const [deletedRisks, setDeletedRisks] = useState([])
    const [focusedIndex, setFocusedIndex] = useState({ index: 0, type: '' })
    const [initialRisks, setInitialRisks] = useState([{ 
        _id: new ObjectID().toString(),
        riskTitle: '',
        riskDetail: '',
        status: NEW,
        index: 0
     }])

    const inputRefs = useRef([])
    const headerRefs = useRef([])
    inputRefs.current = []
    headerRefs.current = []

    useEffect(() => {
        const adjustAllTextareaHeights = () => {
            inputRefs.current.forEach((input) => {
                adjustTextareaHeight(input)
            })
        }
        adjustAllTextareaHeights()
    }, [riskEdit])

    useEffect(() => {
        if (projectInfo.project.risks) {
            if (projectInfo.project.risks.length > 0) {
                let tempRisks = []
                projectInfo.project.risks.forEach((risk, index) => {
                    tempRisks.push({
                        riskTitle: risk.riskTitle,
                        riskDetail: risk.riskDetail,
                        status: DATABASE,
                        _id: risk._id,
                        index: index,
                    })
                })
                setRiskArray(tempRisks)
            }
        }
    }, [projectInfo])

    useEffect(() => {
        if (focusedIndex.type === 'header' && headerRefs.current[focusedIndex.index]) {
            headerRefs.current[focusedIndex.index].focus()
        } else if (focusedIndex.type === 'detail' && inputRefs.current[focusedIndex.index]) {
            inputRefs.current[focusedIndex.index].focus()
        }
    }, [riskEdit, focusedIndex])

    useEffect(() => {
        if (projectInfo.project.risks) {
            if (projectInfo.project.risks.length > 0) {
                let tempRisks = projectInfo.project.risks.map((risk, index) => ({
                    riskTitle: risk.riskTitle,
                    riskDetail: risk.riskDetail,
                    status: DATABASE,
                    _id: risk._id,
                    index: index,
                }));
                setRiskArray(tempRisks)
                setInitialRisks(tempRisks) // Store the initial risks
            }
        }
    }, [projectInfo])

    const adjustTextareaHeight = (element) => {
        if (element) {
            // element.style.height = "auto" // Reset the height to auto before calculating the scroll height
            element.style.height = `${element.scrollHeight}px`
        }
    }

    const handleRiskTitle = (e, index) => {
        const newTitle = e.target.value // Get the new title from the event object
        setRiskArray((prevRiskArray) =>
            prevRiskArray.map((risk, i) =>
                i === index
                    ? {
                        ...risk,
                        riskTitle: newTitle,
                        status: risk.status !== NEW ? EDITED : risk.status,
                      }
                    : risk
            )
        )
        adjustTextareaHeight(e.target)
    }

    const handleRiskChange = (e, index) => {
        setRiskArray((prevRiskArray) =>
            prevRiskArray.map((risk, i) =>
                i === index
                    ? {
                          ...risk,
                          riskDetail: e.target.value,
                          status:
                              e.target.value === '' &&
                              (risk.status === EDITED || risk.status === DATABASE)
                                  ? DELETE
                                  : risk.status !== NEW
                                  ? EDITED
                                  : NEW,
                      }
                    : risk
            )
        )
        adjustTextareaHeight(e.target)
    }

    const handleEnterPress = (e, index) => {
        if (e.key === "Enter") {
          e.preventDefault()
          setRiskArray((prevRiskArray) => {
            const newArray = [...prevRiskArray]
            newArray.splice(index + 1, 0, {
              _id: new ObjectID().toString(),
              riskTitle: "",
              riskDetail: "",
              status: NEW,
              index: index + 1,
            })
      
            // Set focus after updating the riskArray
            setFocusedIndex({
                index: index + 1,
                type: 'header'
            })
      
            return newArray
          })
        } else if (e.which === 8 || e.which === 46) {
          if (riskArray.length > 1 && riskArray[index].riskDetail === "") {
            e.preventDefault()
      
            const deletedRisk = riskArray[index]
      
            setRiskArray((prevRiskArray) =>
              prevRiskArray.filter((risk, i) => i !== index)
            )
            // setFocusedIndex(index - 1)
            setFocusedIndex({
                index: index - 1,
                type: 'header'
            })
      
            // Add the removed risk _id to the deletedRisks array if the status is not 'NEW'
            if (deletedRisk.status !== NEW) {
              setDeletedRisks((prevDeletedRisks) => {
                if (!prevDeletedRisks.includes(deletedRisk._id)) {
                  return [...prevDeletedRisks, deletedRisk._id]
                } else {
                  return prevDeletedRisks
                }
              })
            }
          }
        }
    }

    const handleTitleEnterPress = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault() // Prevent default Enter key behavior
    
            // Check if the corresponding RiskInput for riskDetail exists
            if (inputRefs.current[index]) {
                inputRefs.current[index].focus() // Set focus to the RiskInput for riskDetail
            }
        } else if (e.which === 8 || e.which === 46) {
            if (riskArray.length > 1 && riskArray[index].riskTitle === "") {
                e.preventDefault()
        
                const deletedRisk = riskArray[index]

                setRiskArray((prevRiskArray) =>
                    prevRiskArray.filter((risk, i) => i !== index)
                )

                setFocusedIndex({
                    index: index - 1,
                    type: 'header'
                })

                if (deletedRisk.status !== NEW) {
                    setDeletedRisks((prevDeletedRisks) => {
                    if (!prevDeletedRisks.includes(deletedRisk._id)) {
                        return [...prevDeletedRisks, deletedRisk._id]
                    } else {
                        return prevDeletedRisks
                    }
                    })
                }
            }
        }
    }
      
    const handleSubmit = async () => {
        const riskArrayCopy = [...riskArray]
    
        const newRisks = riskArrayCopy.filter((risk) => risk.status === NEW)
        const editedRisks = riskArrayCopy.filter((risk) => risk.status === EDITED)
        const deletedRisksWithEmptyString = riskArrayCopy.filter((risk) => risk.status === DELETE || risk.riskDetail === '')
    
        let updatedRisks = []
        if (newRisks.length > 0) {
            const response = await addRiskApi(newRisks, projectInfo.project._id)

            if (response.data) {
                updatedRisks = [...response.data.risks]
            }
        }

        if (editedRisks.length > 0) {
            const response = await editRiskApi(editedRisks, projectInfo.project._id)
    
            if (response.data) {
                updatedRisks = [...response.data.risks]
            }
        }

        if (deletedRisks.length > 0 || deletedRisksWithEmptyString.length > 0) {
            const deleteIdsWithEmptyString = deletedRisksWithEmptyString.map((risk) => risk._id)

            const allDeletedIds = [...deletedRisks, ...deleteIdsWithEmptyString]
            const uniqueDeletedIds = [...new Set(allDeletedIds)]

            const response = await deleteRiskApi(uniqueDeletedIds, projectInfo.project._id)
            
            if (response.data) {
                updatedRisks = [...response.data.risks]
            }
        }

        // Update the riskArray state with the new risks
        setRiskArray(updatedRisks.map((risk, index) => {
            return {
                riskTitle: risk.riskTitle,
                riskDetail: risk.riskDetail,
                status: DATABASE,
                _id: risk._id,
                index: index,
            }
        }))

        setProjectInfo(prevProjectInfo => ({
            ...prevProjectInfo,
            project: {
                ...prevProjectInfo.project,
                risks: updatedRisks
            }
        }))

        // Set riskEdit state to false
        setRiskEdit(false)
    }

    const handleEditClick = () => {
        if (!riskEdit) {
            setRiskEdit(true)
        } else {
            setRiskArray(initialRisks) // Reset to initial risks on cancel
            setRiskEdit(false)
        }
    }
    
    const addRef = (element, refs, index) => {
        if (element && !refs.current.includes(element)) {
            refs.current[index] = element
        }
    }    

    const riskEditMapping = riskArray.map((risk, index) => {
        return (
            <React.Fragment key={risk._id ? risk._id : `new-${index}`}>
                <RiskInput
                    className="header"
                    placeholder='Enter a title...'
                    value={risk.riskTitle} // Bind the value to risk.riskTitle
                    onChange={(e) => handleRiskTitle(e, index)}
                    onKeyDown={(e) => handleTitleEnterPress(e, index)}
                    ref={element => addRef(element, headerRefs, index)}
                    onClick={() => setFocusedIndex({
                        index,
                        type: 'header'
                    })}
                />
                <RiskInput 
                    type="text" 
                    value={risk.riskDetail} 
                    ref={element => addRef(element, inputRefs, index)} 
                    onChange={e => handleRiskChange(e, index)} 
                    onKeyDown={(e) => handleEnterPress(e, index)} 
                    onClick={() => setFocusedIndex({
                        index,
                        type: 'detail'
                    })}
                />
            </React.Fragment>
        )
    })

    let riskMapping
    if (riskArray.length > 0) {
        riskMapping = riskArray.map((risk, index) => {
            return (
                <div key={risk._id ? risk._id : `new-${index}`} style={index !== 0 ? { marginTop: 10 } : { marginTop: 5 }}>
                    <RiskHeader>{risk.riskTitle}</RiskHeader>
                    <RiskText>{risk.riskDetail}</RiskText>
                </div>
            )  
        })
    }

    let renderRisks

    if (riskEdit) {
        renderRisks = riskEditMapping
    } else if (projectInfo.project.risks.length > 0) {
        renderRisks = riskMapping
    } else {
        renderRisks = <NoInfo>No risks have been added yet.</NoInfo>
    }

    return (
        <Wrapper>
            <HeaderWrapper style={{ display: 'flex' }}>
            {(loggedInUser?.artist === projectInfo?.project?.artist?._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) ? (
                <EditButton onClick={handleEditClick}>
                    <AiFillEdit size={20} style={{ marginRight: 3 }} />
                    {!riskEdit ? 'Edit Risks' : 'Cancel'}
                </EditButton>
            ) : ''}
                {riskEdit ? <EditButton style={{ marginLeft: 5 }} onClick={handleSubmit}>Save and Publish</EditButton> : null}
            </HeaderWrapper>
            {renderRisks}
        </Wrapper>
    )
}

export default Risks