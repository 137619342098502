import React, { useState, useEffect, useRef, Suspense, useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { addLike, removeLike, getUpdatesApi } from './updatesApi'
import NewUpdateModal from './NewUpdateModal'
import UpdateModal from './UpdateModal'
import LikesModal from './LikesModal'
import LoadingComponent from '../../../LoadingAsset'

import { 
    Wrapper,
    Container,
    IconContainer,
    IconWrapper,
    Count,
    Header,
    BottomBorder,
    StyledTbHeart,
    CoverImage,
} from './UpdatesElements'

import { VscCommentDiscussion } from 'react-icons/vsc'
import { NoInfo } from '../Details/ProjectDetailElements'

import { EditButton } from '../Perks/PerkElements'

import CreatorComponent from './CreatorComponent'

function Update({ projectInfo, loggedInUser }) {
    const queryClient = useQueryClient()
    const [isLoading, setIsLoading] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false)
    const [scrollToComments, setScrollToComments] = useState(false)
    const [showLikeModal, setShowLikeModal] = useState(false)
    const [updateId, setUpdateId] = useState('')
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [updates, setUpdates] = useState([])
    const [updateElements, setUpdateElements] = useState([])
    const [index, setIndex] = useState(0)
    // const [filled, setFilled] = useState([])
    const [imageError, setImageError] = useState(false)

    const commentRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        const getUpdates = async () => {
          setIsLoading(true)
          try {
            const response = await getUpdatesApi(
              projectInfo?.project?._id,
              loggedInUser?.id // Pass logged in user ID
            )
            setUpdates(response.data)
          } catch (error) {
            console.error('Error fetching updates:', error)
          } finally {
            setIsLoading(false)
          }
        }
    
        if (projectInfo?.project?._id && loggedInUser?.id) {
          getUpdates()
        } else {
          // Handle case when user is not logged in
          setIsLoading(false)
          if (projectInfo?.project?._id) {
            getUpdates() // Still fetch updates but without user ID
          }
        }

        //eslint-disable-next-line
      }, [projectInfo])

      const handleIconClick = useCallback(async (index) => {
        if (!loggedInUser?.id) {
            document.body.style.overflow = ''
            navigate('/login')
            return
        }

        const currentUpdate = updates[index]
        const isCurrentlyLiked = currentUpdate.isLiked
        const newLikeCount = isCurrentlyLiked ? currentUpdate.likeCount - 1 : currentUpdate.likeCount + 1

        // Update only the specific update object using DOM
        const heartIcon = document.querySelector(`#heart-icon-${index}`)
        if (heartIcon) {
            heartIcon.style.fill = isCurrentlyLiked ? 'transparent' : 'red'
            heartIcon.style.color = isCurrentlyLiked ? 'inherit' : 'red'
        }
        
        const likeCount = document.querySelector(`#like-count-${index}`)
        if (likeCount) {
            likeCount.textContent = newLikeCount
        }

        try {
            if (!isCurrentlyLiked) {
                await addLike(
                    currentUpdate._id, 
                    loggedInUser.artist, 
                    loggedInUser.artistSlug, 
                    loggedInUser.artistProfileImage, 
                    navigate
                )
            } else {
                await removeLike(currentUpdate._id, navigate)
            }
            
            // Quietly update the state without causing a rerender
            setUpdates(prev => {
                const newUpdates = [...prev]
                newUpdates[index] = {
                    ...newUpdates[index],
                    isLiked: !isCurrentlyLiked,
                    likeCount: newLikeCount
                }
                return newUpdates
            })
            
            queryClient.invalidateQueries(['user'])
        } catch (error) {
            // Revert the DOM changes on error
            if (heartIcon) {
                heartIcon.style.fill = isCurrentlyLiked ? 'red' : 'transparent'
                heartIcon.style.color = isCurrentlyLiked ? 'red' : 'inherit'
            }
            if (likeCount) {
                likeCount.textContent = currentUpdate.likeCount
            }

            if (error.response && error.response.status === 401) {
                document.body.style.overflow = ''
                navigate('/login')
            } else {
                console.error(error)
            }
        }
    }, [loggedInUser, updates, navigate, queryClient])

    const handleShowUpdateModal = (index) => {
        setIndex(index)
        document.body.style.overflow = 'hidden'
        setShowUpdateModal(true)
    }

    const handleCommentClick = (index) => {
        setIndex(index)
        document.body.style.overflow = 'hidden'
        setShowUpdateModal(true)
        setScrollToComments(true)
    }

    const handleCountClick = (index) => {
        document.body.style.overflow = 'hidden'
        setUpdateId(updates[index]._id)
        setShowLikeModal(true)
    }

    const handleShowNewUpdateModal = () => {
        document.body.style.overflow = 'hidden'
        setShowEditModal(true)
    }

    return (
        <Wrapper style={{overflowY: 'auto', overscrollBehavior: 'contain'}}>
            {isLoading 
                ? 
                <LoadingComponent />
                : <>
                {showEditModal && (
                    <Suspense>
                        <NewUpdateModal 
                            setShowEditModal={setShowEditModal} 
                            projectInfo={projectInfo} 
                            updates={updates}
                            setUpdates={setUpdates}
                            filled={updates[index].isLiked ? 'red' : 'transparent'}
                        />
                    </Suspense>
                )}
                {showUpdateModal && (
                    <Suspense>
                        <UpdateModal 
                            setShowUpdateModal={setShowUpdateModal} 
                            scrollToComments={scrollToComments}
                            setScrollToComments={setScrollToComments}
                            updates={updates} 
                            setUpdates={setUpdates} 
                            update={updates[index]}
                            updateElements={updateElements} 
                            setUpdateElements={setUpdateElements} 
                            projectInfo={projectInfo} 
                            updateIndex={index}
                            commentRef={commentRef}
                            filled={updates[index].isLiked ? 'red' : 'transparent'}
                            handleIconClick={handleIconClick}
                            setImageError={setImageError}
                        />
                    </Suspense>
                )}
                {showLikeModal && (
                    <Suspense fallback={<LoadingComponent />}>
                        <LikesModal 
                            loggedInUser={loggedInUser} 
                            updateId={updateId} 
                            showLikeModal={showLikeModal} 
                            setShowLikeModal={setShowLikeModal} 
                        />
                    </Suspense>
                )}
                {(loggedInUser?.artist === projectInfo.project.artist._id || projectInfo.project.authorizedCollaborators?.includes(loggedInUser?.id)) && (
                    <EditButton onClick={handleShowNewUpdateModal}>
                        Add Update
                    </EditButton>
                )}
                {updates.length > 0 ? 
                    updates.map((update, index) => (
                        <div key={update._id}>
                            <Container>
                                <IconContainer>
                                    <IconWrapper>
                                        <StyledTbHeart 
                                            id={`heart-icon-${index}`}
                                            onClick={() => handleIconClick(index)} 
                                            size={29} 
                                            className='heart-transition' 
                                            fill={update.isLiked ? 'red' : 'transparent'} 
                                            color={update.isLiked ? 'red' : undefined} 
                                        />
                                        <Count onClick={() => handleCountClick(index)}>{update?.likeCount}</Count>
                                    </IconWrapper>
                                    <IconWrapper style={{ marginTop: 20 }} onClick={() => handleCommentClick(index)} >
                                        <VscCommentDiscussion size={30} />
                                        <Count>{update?.comments}</Count>
                                    </IconWrapper>
                                </IconContainer>
                                <div style={{ marginLeft: 30 }}>
                                    <Header onClick={() => handleShowUpdateModal(index)}>{update?.title}</Header>
                                    {update?.coverFile && !imageError 
                                    ?
                                        <CoverImage src={update?.coverFile} onClick={() => handleShowUpdateModal(index)} onError={() => setImageError(true)}/>
                                    :
                                        <div></div>
                                    }
                                    <p>{update?.content}</p>
                                    <CreatorComponent 
                                        update={update} 
                                        handleShowUpdateModal={handleShowUpdateModal} 
                                        index={index} 
                                        projectInfo={projectInfo}
                                    />
                                </div>
                            </Container>
                            <BottomBorder />
                        </div>
                    ))
                    :
                        <NoInfo>
                            No Updates
                        </NoInfo>
                }
            </>
        }
        </Wrapper>
    )
}

export default Update