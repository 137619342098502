import axios from 'axios'
import { API_URL, ADD_STORY_ELEMENT_ENDPOINT, EDIT_STORY_ELEMENT_ENDPOINT,  DELETE_STORY_ELEMENT_ENDPOINT} from '../../../../config'

export const deleteOverviewApi = async (deletedElementIds, projectId) => {
    return axios.delete(`${API_URL}${DELETE_STORY_ELEMENT_ENDPOINT}`, { data: { elementIds: deletedElementIds, projectId }, withCredentials: true })
}

export const addOverviewApi = async (projectId, updateArray) => {
    const payload = {
        projectId,
        updateArray
      }

    return axios.post(`${API_URL}${ADD_STORY_ELEMENT_ENDPOINT}`, payload, { withCredentials: true })
}

export const editOverviewApi = async (projectId, updateArray) => {
    const payload = {
        projectId,
        updateArray
      }

    return axios.put(`${API_URL}${EDIT_STORY_ELEMENT_ENDPOINT}`, payload, { withCredentials: true })
}

export const addImageApi = async (projectId, formData) => {
  return axios.post(`${API_URL}/project/body/multi-graphic/me`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      projectId: projectId,
    },
    withCredentials: true,
  })
}