import React, { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { getOfferingApi, deleteOfferingApi } from '../detailsApi'
import { AuthContext } from '../../../../../authContext'

import UploadModal from './UploadModal'

import { useDropzone } from 'react-dropzone'
import { Wrapper, Offering, OfferingsWrapper, OfferingItem } from './OfferingElements'
import { HeaderWrapper } from '../ProjectDetailElements'
import { EditButton } from '../../Perks/PerkElements'
import { NoInfo } from '../ProjectDetailElements'

import { AiFillEdit } from 'react-icons/ai'
import { FiUpload } from 'react-icons/fi'
import { IoMdOpen } from 'react-icons/io'
import { AiOutlineDelete } from 'react-icons/ai'

function Offerings({ project, setProjectInfo }) {
    const [editOfferings, setEditOfferings] = useState(false)
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [acceptedFiles, setAcceptedFiles] = useState([])
    const [offerings, setOfferings] = useState([])
    const fileInputRef = useRef()
    const { loggedInUser } = useContext(AuthContext)

    useEffect(() => {
        if (offerings.length === 0)
            setOfferings(project.offerings)
    }, [project.offerings, offerings.length])

    const onDrop = useCallback((acceptedFiles) => {
      // Do something with the accepted files
      document.body.style.overflow = 'hidden'
      setAcceptedFiles(acceptedFiles)
      setShowUploadModal(true)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf', // Change this line to accept PDF files
        onDrop,
        noDrag: true,
    })

    const deleteOffering = async (offeringId, projectId, fileName) => {
      try {
          await deleteOfferingApi([offeringId], projectId, fileName)
      } catch (error) {
          console.error("Offering deletion failed", error)
      }
    }

    const handleDelete = async (e, offering) => {
        e.preventDefault()
        const confirmDelete = window.confirm("Are you sure you want to delete this offering?")
      
        if (confirmDelete) {
          const offeringToDelete = offering

           // Delete the offering from the server
          deleteOffering(offeringToDelete._id, project._id, offeringToDelete.fileName)
      
          // Filter out the offering to be deleted by its ID
          const newOfferings = offerings.filter(offering => offering._id !== offeringToDelete._id)
      
          // Update the offerings state with the new array
          setOfferings(newOfferings)
      
          // Update the projectInfo state with the updated offerings
          setProjectInfo(prevProjectInfo => ({
            ...prevProjectInfo,
            project: {
              ...prevProjectInfo.project,
              offerings: newOfferings
            }
          }))
        }
    }

    const handlePdfClick = async (fileName) => {
        try {
        const response = await getOfferingApi(project._id, fileName)

        const file = new Blob([response], { type: 'application/pdf' })

        const fileURL = URL.createObjectURL(file)

        window.open(fileURL, '_blank')
        } catch (e) {
            alert('there was an error: ' + e)
        }
    }
      
    //adjust to aws
    const offeringsMapping = offerings.map((offering, index) => {
            return (
            <OfferingItem key={offering._id} onClick={() => handlePdfClick(offering.fileName)}>
                <Offering>{offering.title}</Offering>
                <IoMdOpen size={20} color={'#68B984'} />
            </OfferingItem>
            )
    })

    const deleteOffer = offerings.map((offering, index) => (
        <OfferingItem key={offering._id} onClick={(e) => handleDelete(e, offering)} >
            <Offering style={{ color: '#EA5455' }}>{offering.title}</Offering>
            <AiOutlineDelete size={20} color={'#EA5455'} />
        </OfferingItem>
    ))

    let renderOfferings

    if (editOfferings) {
        if (offerings.length > 0)
            renderOfferings = deleteOffer
        else 
            renderOfferings = <NoInfo>No offerings yet.</NoInfo>
    } else if (project.offerings.length > 0) {
        renderOfferings = offeringsMapping
    } else {
        renderOfferings = <NoInfo>No offerings yet.</NoInfo>
    }
      
    return (
        <Wrapper>
            {showUploadModal > 0 && 
                <UploadModal 
                    project={project} 
                    setProjectInfo={setProjectInfo}
                    setOfferings={setOfferings} 
                    acceptedFiles={acceptedFiles} 
                    setAcceptedFiles={setAcceptedFiles} 
                    setShowUploadModal={setShowUploadModal} 
                />
            }
            <HeaderWrapper>
                {(loggedInUser?.artist === project?.artist?._id || project?.authorizedCollaborators?.includes(loggedInUser?.id)) ? (
                    <EditButton onClick={() => setEditOfferings(!editOfferings)}>
                        <AiFillEdit size={20} style={{ marginRight: 3 }} />
                        {!editOfferings ? 'Edit Offering' : 'Done'}
                    </EditButton>
                ) : ''}
                {editOfferings ? (
                <EditButton {...getRootProps()} style={{ marginLeft: 5 }}>
                    <FiUpload size={20} style={{ marginRight: 5 }} /> Upload PDF
                </EditButton>
                ) : null}
            </HeaderWrapper>
            <input ref={fileInputRef} {...getInputProps()} style={{ display: 'none' }} />
            <OfferingsWrapper>
                {renderOfferings}
            </OfferingsWrapper>
        </Wrapper>
    )
}

export default Offerings
