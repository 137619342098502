import React from 'react'
import { formatReadableDate } from '../../helpers/readableDate'
import { useNavigate } from 'react-router-dom'
import { formatNumberToK } from '../../helpers/formatNumberToK'

import {
    UserProfileWrapper,
    UserProfileContainer,
    UserInfo,
    ProjectTitle,
    ProjectDescription,
    ProjectName,
    ProjectsContainer,
    ProjectWrapper,
    Project,
    ProjectImage,
    SectionWrapper,
    ProjectGenreWrapper,
    ProjectGenre,
    CardWrapper,
    AboutCardContainer,
    AboutInfo,
    AboutUnderline
} from './UserProfileElements'

import UserCard from './UserCard'
import { addCommasToNumber } from '../../helpers/numberConvert'

const UserProfileComponent = ({ 
    user, 
    projects, 
    expressedInterestProjects, 
    totalInvested, 
    numberOfInvestedProjects, 
    numberOfFollowers, 
    setNumberOfFollowers,
    isFollowing, 
    setIsFollowing 
}) => {

    const navigate = useNavigate()

    const handleProjectClick = (projectId) => {
        navigate(`/project-profile/${projectId}`)
    }

    const genres = user?.favoriteGenres?.map((genre, index) => {
            return (
                <ProjectGenre key={index}>
                    <p>{genre}</p>
                </ProjectGenre>
            )
        }
    )

    const projectMapping = projects?.map((project) => {
        return (
            <ProjectWrapper key={project?._id} onClick={() => handleProjectClick(project?._id)}>
                <Project>
                    <ProjectImage src={`${project?.coverImageUrl}`} />
                </Project>
                <ProjectName>{project?.projectName}</ProjectName>
            </ProjectWrapper>
        )
    })

    const expressedInterestMapping = expressedInterestProjects?.map((project) => {
        return (
            <ProjectWrapper key={project?._id} onClick={() => handleProjectClick(project?.project?._id)}>
                <Project>
                    <ProjectImage src={`${project?.project?.coverImageUrl}`} />
                </Project>
                <ProjectName>{project?.project?.projectName}</ProjectName>
            </ProjectWrapper>
        )
    })

    return (
        <UserProfileWrapper>
            <UserProfileContainer>
                <CardWrapper>
                    <UserCard 
                        user={user} 
                        isFollowing={isFollowing} 
                        setIsFollowing={setIsFollowing}
                        setNumberOfFollowers={setNumberOfFollowers}
                    />
                    <AboutCardContainer>
                        {totalInvested > 0 && <AboutInfo>Invested <div>${addCommasToNumber(totalInvested)}</div></AboutInfo>}
                        {numberOfInvestedProjects > 0 && <AboutInfo style={{ marginTop: 10 }}>Projects Invested In <div>{numberOfInvestedProjects}</div></AboutInfo>}
                        {user?.investmentSizeMin && user?.investmentSizeMax && <AboutInfo style={{ marginTop: 10 }}><div>Typical investment</div><div>${formatNumberToK(user?.investmentSizeMin)} - ${formatNumberToK(user?.investmentSizeMax)}</div></AboutInfo>}
                        {(totalInvested > 0 || numberOfInvestedProjects || (user?.investmentSizeMin && user?.investmentSizeMax)) > 0 && <AboutUnderline />}
                        <AboutInfo style={{ marginTop: 10 }}><div>Followers</div> {numberOfFollowers}</AboutInfo>
                        <AboutInfo style={{ marginTop: 10, color: 'gray' }}><div>Joined</div> {formatReadableDate(user.joinedAt)}</AboutInfo>
                    </AboutCardContainer>
                </CardWrapper>
                <UserInfo>
                    {projects?.length > 0 && <ProjectTitle>Portfolio</ProjectTitle>}
                    <ProjectsContainer>
                        {projectMapping}
                    </ProjectsContainer>
                    {expressedInterestProjects?.length > 0 &&
                    <ProjectTitle style={{ marginTop: 15 }}>Expressed Interest</ProjectTitle>}
                    <ProjectsContainer>
                        {expressedInterestMapping}
                    </ProjectsContainer>
                    {user?.investmentThesis &&
                    <SectionWrapper>
                        <ProjectTitle >Investment Thesis</ProjectTitle>
                        <ProjectDescription >{user?.investmentThesis}</ProjectDescription>
                    </SectionWrapper>
                    }
                    {user?.favoriteGenres?.length > 0 &&
                    <SectionWrapper>
                        <ProjectTitle >Favorite Genres</ProjectTitle>
                        <ProjectGenreWrapper>
                            {genres}
                        </ProjectGenreWrapper>
                    </SectionWrapper>
                    }
                </UserInfo>
            </UserProfileContainer>
        </UserProfileWrapper>
    )
}

export default UserProfileComponent 