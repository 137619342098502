import React, { useState, useEffect, useRef, useReducer } from 'react'
import useHandleUpdates from './useHandleUpdates'
import { updateReducer, initialState } from './newUpdateReducer'
import { useDropzone } from 'react-dropzone'
import {
  StoryEditTextArea,
  EmbedContainer,
  Embed,
  EmbedOverlay,
  InputEditContainer,
  BeltWrapper,
  BeltIconContainer,
  NoOutlineImage,
  SelectedElementWrapper,
  ImageBorderWrapper,
  CaptionInput,
  CaptionTextDisplay
} from '../../ProjectStory/StoryElements'

import { 
    Wrapper,
    Container,
    CloseContainer,
    TitleInput,
    CoverButton,
} from './NewUpdateModalElements'

import {
    Underline,
} from '../UpdateModal/UpdateModalElements'

import { EditButton } from '../../Perks/PerkElements'
import { ModalBg } from '../../../Modal/EditModalElements.js'

import { IoClose } from 'react-icons/io5'
import { HiPlus } from 'react-icons/hi'
import { MdTitle } from 'react-icons/md'
import { IoImageOutline } from 'react-icons/io5'
import { ImEmbed } from 'react-icons/im'
import { BiPhotoAlbum } from 'react-icons/bi'

function NewUpdateModal({ 
    setShowEditModal, 
    projectInfo, 
    updates, 
    setUpdates, 
    setFilled, 
    filled,
    loggedInUser
}) {
    const [triggerInputRef, setTriggerInputRef] = useState(false)
    const [title, setTitle] = useState('')
    //eslint-disable-next-line
    const [updateMade, setUpdateMade] = useState(false)
    const [deletedElementIds, setDeletedElementIds] = useState([])
    const [imageFiles, setImageFiles] = useState([])
    const [deletedMediaFiles, setDeletedMediaFiles] = useState([])
    const [coverImage, setCoverImage] = useState('')
    const [state, dispatch] = useReducer(updateReducer, initialState)
    const { 
        storyElements, 
        deletedIndexes,
        showAddOptions, 
        selectedElement, 
        focusedInput,
        captionFocused,
        rotate
    } = state

    const { handleSaveChanges } = useHandleUpdates({
        projectId: projectInfo ? projectInfo.project._id : null,
        storyElements: storyElements,
        deletedElementIds: deletedElementIds,
        imageFiles: imageFiles,
        dispatch: dispatch,
        setUpdateMade: setUpdateMade,
        deletedIndexes: deletedIndexes,
        title: title,
        setTitle: setTitle,
        setShowEditModal: setShowEditModal,
        updates,
        setUpdates,
        filled,
        setFilled,
        coverImage,
    })

    const inputRefs = useRef([])
    const modalRef = useRef(null)

    const closeModalClick = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''
            setShowEditModal(false)
        }
    }

    const handleExitClick = () => {
        document.body.style.overflow = ''
        setShowEditModal(false)
    }

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]
        const imageUrl = URL.createObjectURL(file)
        setImageFiles((prevFiles) => [...prevFiles, file])
        changeElementType('image', focusedInput, imageUrl, file.name)
        if (focusedInput === storyElements.length - 1) {
            dispatch({ type: 'ADD_ELEMENT', payload: { elementType: 'text', index: focusedInput } })
        }   
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
        noDrag: true,
    })

    useEffect(() => {
          dispatch({ type: 'TOGGLE_EDITOR_MODE' })
      }, [])
    
    useEffect(() => {
          inputRefs.current.forEach((ref, index) => {
            handleInputIncreases(inputRefs, index)
          })
    }, [])
  
    useEffect(() => {
        if (focusedInput === 0 && inputRefs.current[0]) {
            inputRefs.current[0].focus()
        }
    }, [focusedInput])

    useEffect(() => {
        if (focusedInput !== null && inputRefs.current[focusedInput]) {
            inputRefs.current[focusedInput].focus({ preventScroll: true })
        }
    }, [showAddOptions, focusedInput, triggerInputRef])

    const handleInputIncreases = (refs, index) => {
        if (refs.current[index]) {
            if (refs.current[index].classList.contains("title")) {
              refs.current[index].style.height = "40px"
            } else {
              refs.current[index].style.height = "60px"
            }
            refs.current[index].style.paddingBottom = "20px"
            refs.current[index].style.height = refs.current[index].scrollHeight + "px";
        }
    }

    const handleInputChange = (event, index) => {
        if (storyElements[index].type === 'embed') {
            dispatch({
                type: 'UPDATE_EMBED_INPUT_VALUE',
                payload: { index, embedInputValue: event.target.value }
            })
        } else {
            dispatch({
                type: 'UPDATE_ELEMENT_CONTENT',
                payload: { index, content: event.target.value },
            })
        }

        if (
            (storyElements[index].type === 'title' || storyElements[index].type === 'embed') 
            && event.target.value.length === 0
          ) {
            dispatch({
              type: 'CHANGE_ELEMENT_TYPE',
              payload: { elementType: 'text', index },
            })
            // inputRefs.current[index].style.height = '60px'
        }
    }

    const changeElementType = (elementType, index, url = '', name) => {
        dispatch({
            type: 'CHANGE_ELEMENT_TYPE',
            payload: { 
                elementType, 
                index, 
                url, 
                name, 
                projectId: projectInfo.project._id, 
            },
          })
        dispatch({ type: 'TOGGLE_AND_OPTIONS' })
        dispatch({ type: 'SET_FOCUSED_INPUT', payload: index })
    }

    const handleBlur = (element, content, index) => {
        const hasContent = content !== ''
        if (element.isFromDatabase) {
          if (!hasContent && !deletedElementIds.includes(element._id)) {
            setDeletedElementIds([...deletedElementIds, element._id])
            dispatch({
                type: 'SET_ELEMENT_TO_DELETE_IF_EMPTY',
                payload: { index },
            })              
          } else if (hasContent) {
            if ((projectInfo.storyElements[index] && content !== projectInfo.storyElements[index].content))
                element.operation = 'edit'
            if (deletedElementIds.includes(element._id)) {
                //remove from deletedElementIds
                setDeletedElementIds(deletedElementIds.filter((id) => id !== element._id))
                element.operation = 'edit'
            }
          }
        } 
        else if (element.operation === 'new') {
            if (hasContent) {
                element.operation = 'add'
            }
        }
        else if (element.operation === 'add') {
            if (!hasContent) {
                element.operation = 'new'
            }
        }
        dispatch({
            type: 'SET_ELEMENT_TO_DELETE_IF_EMPTY',
            payload: { index },
        }) 
        if (rotate) {
            dispatch({ type: 'UNROTATE_ADD_OPTIONS'}) 
        }
    }

    const handleKeyDown = (event, element, index) => {
        const hasContent = element.content !== ''
        if (event.key === 'Enter') {
          event.preventDefault()
      
          if (element.type === 'embed' && !Object.prototype.hasOwnProperty.call(element, 'caption')) {
            const embedContent = createEmbed(element.embedInputValue)
      
            if (embedContent) {
              dispatch({
                type: 'UPDATE_ELEMENT_CONTENT',
                payload: { index, content: embedContent },
              })
            }
            dispatch({
              type: 'ADD_ELEMENT',
              payload: { elementType: 'text', index },
            })
          } else {
            const input = event.target
            const cursorAtStart = input.selectionStart === 0
            const hasContent = element.content.length > 0

            if ((element.type === 'embed' && Object.prototype.hasOwnProperty.call(element, 'caption')) || element.type === 'image') {
                // Add new element above the current index if the element is an embed or image
                dispatch({
                    type: 'ADD_ELEMENT',
                    payload: { elementType: 'text', index: index, cursorAtStart: true },
                })
                setTriggerInputRef(!triggerInputRef)
            } else if (cursorAtStart && hasContent) {
                // Add new element above the current index
                dispatch({
                    type: 'ADD_ELEMENT',
                    payload: { elementType: 'text', index: index, cursorAtStart: true  },
                })
                setTriggerInputRef(!triggerInputRef)
            } else {
                // Add new element below the current index
                dispatch({
                    type: 'ADD_ELEMENT',
                    payload: { elementType: 'text', index, cursorAtStart: false },
                })
            }
          }
        } else if ((event.key === 'Backspace' || event.key === 'Delete') && !captionFocused) {
            if (!deletedElementIds.includes(element._id)) {
                setDeletedElementIds([...deletedElementIds, element._id])            
            } 
            if (storyElements.length > 1) {
                if ((element.type === 'text' || element.type === 'title') && element.content === '') {
                    dispatch({
                        type: 'DELETE_ELEMENT',
                        payload: { index },
                    })
                } else if ((element.type === 'image' || element.type === 'embed') && (Object.prototype.hasOwnProperty.call(element, 'caption') || element.embedInputValue === '')) {
                    setDeletedMediaFiles([...deletedMediaFiles, element])
                    dispatch({
                        type: 'DELETE_ELEMENT',
                        payload: { index },
                    })
                }
                //set the index delete counter to the current index + 1
                setTriggerInputRef(!triggerInputRef)
            } else {
                // If there's only one element and its type is 'image' or 'embed', change it to 'text'
                if ((element.type === 'image' || element.type === 'embed')) {
                    setDeletedMediaFiles([...deletedMediaFiles, element])
                    if (!hasContent && !deletedElementIds.includes(element._id)) {
                        setDeletedElementIds([...deletedElementIds, element._id])            
                    } 
                    dispatch({
                        type: 'CHANGE_ELEMENT_TYPE',
                        payload: { elementType: 'text', index },
                    })
                    dispatch({
                        type: 'UPDATE_ELEMENT_PROPERTIES',
                        payload: { index, content: '', caption: '', embedInputValue: '' },
                    })
                }  
                setTriggerInputRef(!triggerInputRef)
            } 
        }
    }

    const createEmbed = (url) => {
        if (typeof url !== 'string') {
            return
        }
        
        const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/watch_videos\?video_ids=|\/watch\?p=|\/watch\?annotation_id=|\/watch\?feature=|\/watch\?list=))(?<id>[\w-]{11})/
        const match = url.match(youtubeRegex)
    
        if (match && match.groups && match.groups.id) {
        return `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${match.groups.id}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        }
    
        return null
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            dispatch({ type: 'SET_FOCUSED_INPUT', payload: 0 })
            
        }
    }

    const handleCoverClick = (index) => {
        if (!coverImage)
            setCoverImage(storyElements[index].content)
        else 
            setCoverImage('')
    }

    return (
        <ModalBg ref={modalRef} onClick={closeModalClick} >
            <Wrapper>
                <Container>
                    <CloseContainer onClick={handleExitClick}>
                        <IoClose size={30} />
                    </CloseContainer>
                    <div>
                        <EditButton onClick={handleSaveChanges}>Publish</EditButton>
                    </div>
                    <TitleInput placeholder='Enter a main title and hit enter...' onKeyDown={handleKeyPress} onChange={e => setTitle(e.target.value)} />
                    <Underline />
                {
                    Array.isArray(storyElements) && storyElements.map((element, index) => (
                    <InputEditContainer key={element._id ? element._id : element.id}>
                        {focusedInput === index && (
                        <>
                            <BeltIconContainer 
                                onClick={() => dispatch({ type: 'TOGGLE_ADD_OPTIONS' })}
                                className={rotate ? 'rotate' : 'un-rotate'}
                                style={
                                    element.type === 'embed' 
                                    || element.type === 'image' 
                                    || element.type === 'title'
                                    || (element.type === 'text' && element.content.length > 0)
                                    ? { display: 'none' } 
                                    : {}
                                }
                            >
                                <HiPlus size={21} />
                            </BeltIconContainer>
                            {showAddOptions && focusedInput === index ? (
                            <BeltWrapper> 
                                <BeltIconContainer onClick={() => changeElementType('title', index)} className='rise'>
                                    <MdTitle size={21} />
                                </BeltIconContainer>
                                <BeltIconContainer {...getRootProps()} className='rise2'>
                                    <input {...getInputProps()}/>
                                    <IoImageOutline size={21} />
                                </BeltIconContainer>
                                <BeltIconContainer onClick={() => changeElementType('embed', index)} className='rise3'>
                                    <ImEmbed size={21} />
                                </BeltIconContainer>
                            </BeltWrapper>
                            ) : null}
                        </>
                        )}
                        {element.type === 'embed' && element.content.includes('<iframe') ? (
                            <Embed 
                                tabIndex={0}
                                style={{ 
                                    marginBottom: selectedElement === index || element.caption ? '50px' : '0',
                                }}
                                onKeyDown={(event) => handleKeyDown(event, element, index)} 
                                onBlur={() => handleBlur(element, element.content, index)}
                                ref={(ref) => (inputRefs.current[index] = ref)} 
                                onClick={() => dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })}
                            >
                                <EmbedContainer>
                                    {selectedElement === index && <SelectedElementWrapper />}
                                        <div dangerouslySetInnerHTML={{ __html: element.content }} />
                                        <EmbedOverlay onClick={() => dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })} />
                                    {selectedElement === index && <ImageBorderWrapper />}
                                    {selectedElement === index ?
                                        <CaptionInput 
                                            type="text"
                                            value={element.caption || ""}
                                            onChange={(event) => dispatch({ type: 'UPDATE_MEDIA_CAPTION', payload: { index, caption: event.target.value } })}
                                            placeholder="Add a caption..."
                                            onFocus={() => dispatch({ type: 'HANDLE_CAPTION_FOCUS' })}
                                            onBlur={() => dispatch({ type: 'HANDLE_CAPTION_BLUR' })}
                                        />
                                        : 
                                        <CaptionTextDisplay>{element.caption}</CaptionTextDisplay>
                                    }
                                </EmbedContainer>
                            </Embed>
                        ) : element.type === 'image' ? (
                            focusedInput === index ? (
                                <div 
                                    style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%' }}
                                >
                                <SelectedElementWrapper>
                                <NoOutlineImage
                                    src={element.isFromDatabase ? element.content : element.preview}
                                    ref={(ref) => inputRefs.current[index] = ref}
                                    alt="Story element"
                                    onMouseDown={() => {
                                        dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })
                                    }}
                                    onBlur={() => handleBlur(element, element.content, index)}
                                    tabIndex={0} 
                                    onKeyDown={(event) => handleKeyDown(event, element, index)} 
                                />
                                <ImageBorderWrapper />
                                </SelectedElementWrapper>
                                <CaptionInput 
                                    type="text"
                                    value={element.caption || ""}
                                    onChange={(event) => dispatch({ type: 'UPDATE_MEDIA_CAPTION', payload: { index, caption: event.target.value } })}
                                    placeholder="Add a caption..."
                                    onFocus={() => dispatch({ type: 'HANDLE_CAPTION_FOCUS' })}
                                            onBlur={() => dispatch({ type: 'HANDLE_CAPTION_BLUR' })}
                                />
                                {selectedElement === index &&
                                    <CoverButton onClick={() => handleCoverClick(index)}>
                                        <BiPhotoAlbum size={20} style={{ marginRight: 5 }}/>
                                        {coverImage === storyElements[index].content ? 'Unset as Cover' : 'Set as Cover'}
                                    </CoverButton>
                                }
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <NoOutlineImage
                                        src={element.isFromDatabase ? element.content : element.preview}
                                        alt="Story element"
                                        onClick={() => dispatch({ type: 'HANDLE_MEDIA_FOCUS', payload: index })}
                                    />
                                    <CaptionTextDisplay>{element.caption}</CaptionTextDisplay>
                                </div>
                            )
                        ) : (
                        <StoryEditTextArea
                            type="text"
                            value={element.type === 'embed' ? element.embedInputValue : element.content}
                            onChange={(event) => {
                                handleInputChange(event, index)
                                handleInputIncreases(inputRefs, index)
                            }}
                            onFocus={() => {
                                dispatch({ type: 'SET_FOCUSED_INPUT', payload: index })
                            }}
                            onBlur={() => handleBlur(element, element.content, index)}
                            style={showAddOptions && focusedInput === index ? { display: 'none' } : {}}
                            onKeyDown={(event) => handleKeyDown(event, element, index)}
                            onInput={() => handleInputIncreases(inputRefs, index)}
                            ref={(ref) => inputRefs.current[index] = ref}
                            placeholder={
                            element.type === 'title'
                                ? 'Enter a title...'
                                : element.type === 'embed'
                                ? 'Paste a video embed link and press Enter'
                                : ''
                            }
                            className={element.type === 'title' ? 'title' : ''}
                        />
                        )}
                    </InputEditContainer>
                    ))}
                </Container>
            </Wrapper>
        </ModalBg>
    )
}

export default NewUpdateModal