import React, { useState, useEffect } from 'react'
import { getWatchlist } from '../investmentProfileApi'
import { useQuery } from '@tanstack/react-query'

import { 
    GridWrapper,
    PortfolioHeader 
} from '../Portfolio/PortfolioElements'

import {
    ShowMoreButton,
    ShowMoreWrapper
} from '../Followers/FollowerElements'

import {
    LoadContainer,
    OuterSpinner,
    OuterPath,
    Spinner,
    Path
} from '../../LoadingElement.js'

import {
    NoInfo
} from '../InvestmentProfileElements'

import Project from './Project'

const Watchlist = ({ userPage, setUserPage }) => {
    const [projects, setProjects] = useState([])
    const [page, setPage] = useState(1)

    const { data, isLoading, isError } = useQuery({
        queryKey: ['watchlist', page], // Include page in the queryKey
        queryFn: () => getWatchlist(page), // Use an inline function that calls getWatchlist with the current page
        keepPreviousData: true, // Optional: This can be useful for pagination to keep previous data until new data is loaded
    })

    useEffect(() => {
        if (page === 1 && data?.projects) {
            setProjects(data.projects)
        } else if (page > 1 && data?.projects) {
            setProjects(prevProjects => [...prevProjects, ...data.projects])
        }
    }, [data?.projects, page])

    if (isError) {
        return (
        <div style={{ padding: '20px 25px' }}>
            <NoInfo>No projects found in the watchlist.</NoInfo>
        </div>
        )
    }

    const handleShowMore = () => {
        setPage((prevPage) => prevPage + 1) // Increment page to trigger refetch
    }

    const projectMapping = projects?.map((project, index) => {
        return (
            <Project
                key={index}
                project={project}
                setProjects={setProjects}
            />
        )
    })

    return (
        <div style={{ padding: '20px 25px 50px 25px' }}>
          <PortfolioHeader>Watchlist</PortfolioHeader>
          {isLoading ? (
            <LoadContainer style={{ height: 500 }}>
              <div style={{ position: 'relative' }}>
                <OuterSpinner width="50" height="50" viewBox="0 0 50 50">
                  <OuterPath cx="25" cy="25" r="20" fill="none" />
                </OuterSpinner>
                <Spinner width="50" height="50" viewBox="0 0 50 50">
                  <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                </Spinner>
              </div>
            </LoadContainer>
            ) : projectMapping?.length > 0 ? (
                <>
                    <GridWrapper>{projectMapping}</GridWrapper>
                    {data?.pagination?.hasMore &&
                        <ShowMoreWrapper>
                            <ShowMoreButton onClick={handleShowMore}>Show More</ShowMoreButton>
                        </ShowMoreWrapper>
                    }
                </>
            ) : (
                <NoInfo>No projects found in the watchlist.</NoInfo>
            )}
        </div>
    )
}

export default Watchlist