import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { 
    ProjectWrapper, 
    Info,
    InfoWrapper,
    BottomInfo,
    ProjectImageWrapper,
    ProjectImage,
    ImageOverlay,
    ImageText,
    EditButton
} from './ProjectElements'

import { MdOutlineModeEdit } from "react-icons/md"

const Project = ({ 
    investmentId,
    projectInfo,
    projectName,
    projectEndDate,
    amount,
    investmentStatus,
    msg, 
    date, 
    src, 
    strike, 
    option, 
    addMsg, 
    color,
    projectId,
    showModal,
    setShowModal,
    setClickedInvestmentId,
    isAnonymous,
    isInvestmentAnonymous,
    setIsInvestmentAnonymous
}) => {
    const [isHovered, setIsHovered] = useState(false)

    const navigate = useNavigate(null)

    const percentFunded = () => {
        const currentFunds = projectInfo?.currentFunds
        const targetFundAmount = projectInfo?.targetFundAmount
        
        const percent = (currentFunds / targetFundAmount) * 100
        
        // If we're between 99 and 100, show the exact percentage to prevent premature "100%"
        if (percent > 99 && percent < 100) {
            return Number(percent.toFixed(1)) // Show one decimal place
        }
        
        // If we're exactly 100 or above, show 100
        if (percent >= 100) {
            return 100
        }
        
        // For all other cases, round up
        return Math.ceil(percent)
    }

    const navigateToProject = () => {
        navigate(`/project-profile/${projectId}`)
    }

    const handleEditClick = (e, investmentId) => {
        e.stopPropagation()

        setShowModal(true)
        setClickedInvestmentId(investmentId)
        if (isInvestmentAnonymous === '')
            setIsInvestmentAnonymous(isAnonymous)
    }

    return (
        <ProjectWrapper
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
            onClick={navigateToProject}
        >
            {investmentStatus === 'ACTIVE' &&
            <EditButton onClick={(e) => handleEditClick(e, investmentId)}>
                <MdOutlineModeEdit 
                    size={15} 
                    style={{ marginRight: 5 }} 
                /> 
                    EDIT INVESTMENT
            </EditButton>
            }
            <InfoWrapper>
                <Info>{projectInfo?.projectName}</Info>
                <Info style={strike ? { textDecoration: 'line-through', color: 'grey' } : {}} >${amount}</Info>
            </InfoWrapper>
            <ProjectImageWrapper>
                <ProjectImage src={`${projectInfo?.coverImageUrl}`} />
                <ImageOverlay isVisible={isHovered}>
                    <ImageText>{percentFunded()}% funded</ImageText>
                </ImageOverlay>
            </ProjectImageWrapper>
            <BottomInfo>
                    <div style={{ fontFamily: "'DM sans', serif" }}>{msg} {date}</div>
                    <div style={{ fontFamily: "'DM sans', serif", color, fontWeight: '500' }}>{addMsg}</div>
            </BottomInfo>
        </ProjectWrapper>
    )
}

export default Project