import styled from 'styled-components'
import { TbHeart } from 'react-icons/tb'
import { FaFacebook } from "react-icons/fa"
import { FaSquareXTwitter } from "react-icons/fa6"
import { MdEmail } from "react-icons/md"
import { ImEmbed2 } from "react-icons/im"
import { PrimaryColor, PrimaryHoverColor } from '../../../colors'

export const Wrapper = styled.section`
    position: relative;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
`

export const BodyTopWrapper = styled.div`
    width: 100%;
    padding: 0 40px;

    @media screen and (max-width: 950px) {
        align-items: flex-start;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
        align-items: flex-start;
    }
`

export const FundStatusWrapper = styled.div`
    display: flex;
    color: grey;
    font-size: 13px;

    @media screen and (max-width: 768px) {
        margin-left: 20px;
    }
`

export const Title = styled.div`
    margin-top: 10px;
    font-size: 30px;
    font-weight: bold;
    z-index: 2;
    line-height: 1;

    @media screen and (min-width: 950px) {
        display: none;
    }

    @media screen and (max-width: 768px) {
        margin: 10px 20px;
    }
`

export const CreatedBy = styled.div`
    margin-top: 5px;
    font-size: 15px;
    color: #989898;
    z-index: 2;
    font-weight: 500;

    @media screen and (min-width: 950px) {
        display: none;
    }

    @media screen and (max-width: 768px) {
        margin-top: 3px;
        margin-left: 20px;
    }
`

export const SubtitleBar = styled.div`
    margin-top: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 950px) {
        display: none;
    }

    @media screen and (max-width: 768px) {
        margin-left: 20px;
    }
`

export const Logline = styled.div`
    margin-top: 5px;
    font-size: 15px;
    width: 95%;
    z-index: 3;

    @media screen and (min-width: 950px) {
        display: none;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 5px;
        margin-left: 20px;
        padding-right: 50px;
    }
`  

export const BigDivider = styled.div`
    border-bottom: 1px solid #e7e6e1;
    line-height: 0.1em;
    margin: 60px 0 30px;
`

export const FundWrapper = styled.div`
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const FundInfoContainer = styled.div`
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 950px) {
        padding-top: 5px;
    }
    
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`

export const FundProgressLabel = styled.div`
    font-size: 13px;
    margin-bottom: 5px;
`

export const FundProgressBar = styled.div`
    position: relative;
    width: 100%;
    height: 8px;
    display: flex;
    background: #D8D9DA;
`

export const FundProgress = styled.div`
    position: absolute;
    height: 100%;
    /* width: 50%; */
    background: #68B984;
`

export const FundsRaised = styled.div`
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    color: #68B984;
`

export const FundInfo = styled.div`
    font-size: 13px;
    margin-top: 10px;
    display: flex;
    align-items: center;
`

export const FundButton = styled.button`
    width: 100%;
    height: 50px;
    border: none;
    font-weight: 300;
    background: ${PrimaryColor};
    color: white;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: rgb(114,195,142);
    }
`

export const GaugeInterestButton = styled.button`
    width: 100%;
    height: 50px;
    border: none;
    background: rgb(235, 227, 213);
    color: black;
    font-size: 15px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: rgb(235, 227, 213, 0.8);
    }
`

export const GaugeInterestLegalInfo = styled.div`
    font-size: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`

export const OptionBar = styled.div`
    position: -webkit-sticky; /* Safari */
    position: sticky;
    border-bottom: 1px solid #E7E6E1;
    width: 100%;
    display: flex;
    background: white;
    margin-top: 2px;
    top: 0;
    padding: 20px 60px 0px 0;
    margin-bottom: 20px;
    align-items: center;
    color: #989898;
    z-index: 500;

    .selected {
        width: 70px;
        border-bottom: 2px solid black;
        line-height: 0.1em;
    }

    .unselected {
        border-color: #989898;
    }

    @media screen and (max-width: 768px) {
        overflow-x: auto;
    }

    @media screen and (max-width: 950px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const OptionWrapper = styled.div`
    height: 60px;
    margin-left: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: black;
    }

    @media screen and (max-width: 768px) {
        &:first-child {
            margin-left: 0px;
        }
    }
`

export const Option = styled.div`
    margin-bottom: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
`

export const BottomBorder = styled.div`
    position: absolute;
    bottom: 0;
    border-bottom: 3px solid black;
    line-height: 0.1em;

    @media screen and (max-width: 768px) {
        top: 50px;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 40px;
    min-height: 400px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0 20px;
    }
`

export const PerkContainer = styled.div`
    position: sticky;
    position: -webkit-sticky; 
    margin-top: 20px;
    top: 120px;
    height: 100%;
    width: auto;
    padding-top: 20px;
    margin-left: 40px;
    display: flex;
`

export const Perk = styled.div`
    margin-top: 30px;
    border: 1px solid #989898;
    height: auto;
    width: 250px;

    @media screen and (max-width: 922px) { 
        flex: 0 0 auto;
        margin-left: 20px;
    }
`

export const FirstPerk = styled.div`
    margin-top: 30px;
    border: 1px solid #989898;
    height: auto;
    width: 250px;

    @media screen and (max-width: 922px) { 
        flex: 0 0 auto;
        margin-left: 60px;
    }
`

export const PerkImage = styled.img`
    height: auto;
    width: 100%;
    object-fit: cover;

`

export const PerkInfoContainer = styled.div`
    padding: 20px;
`

export const PerkHeader = styled.div`
    font-size: 18px;
`

export const InvestmentAmount = styled.div`
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
`

export const PerkContainerHeader = styled.div`
    position: absolute;
    font-size: 15px;

    @media screen and (max-width: 922px) {
        margin-left: 20px;
    }
`

export const StyledFaFacebook = styled(FaFacebook)`
    font-size: 20px;
    cursor: pointer;
`

export const StyledFaSquareXTwitter = styled(FaSquareXTwitter)`
    font-size: 20px;
    cursor: pointer;
`

export const StyledMdEmail = styled(MdEmail)`
    font-size: 20px;
    cursor: pointer;
`

export const StyledImEmbed2 = styled(ImEmbed2)`
    font-size: 20px;
    cursor: pointer;
`

export const SocialWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`

export const SocialContainer = styled.div`
    padding-left: 40px;
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
`

export const FavoriteProject = styled.button`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid lightgrey;
    cursor: pointer;
    font-size: 15px;
    padding: 10px;

    &:hover {
        border: 1px solid black;
    }
`

export const StyledTbHeart = styled(TbHeart)`
    margin-right: 10px;
    transition: fill 0.10s ease-in-out;
`

//MOBILE VIEW

export const MobileOptionWrapper = styled.div`
    height: 60px;
    margin-left: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: black;
    }
`

export const MobileOption = styled.div`
    font-size: 14px;
    margin-left: 5px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
`

export const OptionBarButton = styled.div`
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
    justify-content: center;
    border-radius: 0px;
    border: none;
    background: rgb(0, 0, 0);
    color: white;
    font-size: 13px;
    font-weight: 300;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-left: auto;

    &:hover {
        background: #272829;
    }
`

// SIDEBAR ELEMENTS
export const Sidebar = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  background: white;
  height: 100%;
  z-index: 1000;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(-100%)')};
  box-shadow: ${(props) => (props.show ? '5px 0 15px rgba(0, 0, 0, 0.1)' : 'none')};
`

export const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 40px;
`

export const SidebarContent = styled.div`
    font-size: 14px;
    display: flex;
    border-bottom: 1px solid #E7E6E1;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
`

export const SidebarOption = styled.div`
    margin: 20px;
    color: grey;

    &:hover {
        color: black;
    }
`

export const Selected = styled.div`
    border-left: 5px solid #68B984;
`

//LAUNCH PROJECT MODAL
export const LaunchModal = styled.div`
    width: 500px;
    padding: 20px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export const LaunchText = styled.div`
    font-size: 15px;
    text-align: center;

`

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 30px;
`

export const ContinueButton = styled.button`
    background: ${PrimaryColor};
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
        background: ${PrimaryHoverColor};
    }
`

export const CancelButton = styled.button`
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;

    &:hover {
        background: #eee;
    }
`