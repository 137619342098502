import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { API_URL } from '../../../../../config'

import axios from 'axios'
import { ObjectId } from 'bson'
import { handleFocus, addToRef, handleInputIncreases } from '../helpers/inputHelpers'
import { addChildComment, updateComment } from '../discussionApi'
import EditModal from '../EditModal'

import { 
    AddComment, 
    AddButton,
    CancelButton,
    Reply,
    CommentSection, 
    InfoSection,
    ProfileErrorContainer,
    ProfilePicContainer, 
    HeaderWrapper,
    HeaderContainer,
    Header,
    SubHeader,
    Time,
    CreatorTab,
} from '../DiscussionElements'

import { timeFromNow } from '../../../../../helpers/TimeFromNow'

import { FaUser } from 'react-icons/fa'

function CommentCard({ 
    projectInfo, 
    account, 
    comment, 
    comments, 
    setComments, 
    index, 
    childCommentRef, 
    setCommentsLength, 
    setPageCount,
    setCommentAdds,
    type,
    updateId,
    setUpdates,
    route,
    loggedInUser
}) {
    const [showEditModal, setShowEditModal] = useState(false)
    const [imageError, setImageError] = useState(false)

    const commentRefs = useRef([])
    const replyRefs = useRef([])
    const editRef = useRef(null)
    commentRefs.current = []
    replyRefs.current = []

    const navigate = useNavigate()

    //@dev closes edit modal
    const handleEditCommentCancel = (index) => {
        let tempArray = [...comments]
        tempArray[index].editComment = false
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }

    //@dev adds updateCommentText object key and sets it to e value.
    const handleEditCommentChange = (e, index) => {
        let tempArray = [...comments]
        tempArray[index].updateCommentText = e.target.value
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }

    //@dev updates dom view to show newly updated comment, closes edit modal
    //and sends update to server
    const handleEditCommentSubmit = async (index, commentId) => {
        let tempArray = [...comments]
        tempArray[index].comment = comments[index].updateCommentText
        tempArray[index].editComment = false
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
        const payload = {
            updatedComment: comments[index].updateCommentText
        }
        if (type === 'UPDATE') {
            try {
                await axios.post(`${API_URL}/updates/update-comment/${commentId}`, payload, { withCredentials: true })
            } catch (e) {
                console.error(e)
            }
        } else {
            try {
                await updateComment(commentId, comments[index].updateCommentText)
            } catch (e) {
                console.error(e)
            }
        }
    }

    //@dev opens edit modal if it is closed, otherwise closes it. 
    const handleEditClick = (e, index) => {
        if (showEditModal) {
            setShowEditModal(false)
        }
        else {
            setShowEditModal(true)
        }
    }

    //@dev creates addComment object key (for replies) and sets it to e value.  
    const handleChildCommentChange = (e, index) => {
        let tempArray = [...comments]

        tempArray[index].addComment = e.target.value

        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }

    //@dev checks if the reply owner is the creator, creates Date, sends data to server.
    //if the child comment adds (for pagination) does not exist, creates it.
    //if the comment replies key exists and is greater than 0, updates Dom view with update
    //adds Object id to childComments array and adds 1 to child comment adds
    //if the replies is not open, adds to Object Id and closes input. 
    const handleAddChildComment = async (commentId, index) => {
        let isCreator = loggedInUser?.artist === projectInfo?.project?.artist._id ? true : false

        const isCollaborator = loggedInUser.collaborations.includes(projectInfo.project._id)

        const date = new Date()
        const id = new ObjectId()

        const newComment = {
            _id: id,
            projectId: projectInfo.project._id,
            parentCommentId: commentId, 
            imageFilePath: loggedInUser?.userProfileImage,
            comment: comments[index].addComment,
            commentBy: loggedInUser?.displayName,
            isCreator,
            isCollaborator,
            createdAt: date
        }

        const payload = {
            _id: id,
            projectId: projectInfo.project._id,
            userId: loggedInUser.id,
            username: loggedInUser?.username,
            parentCommentId: commentId, 
            imageFilePath: loggedInUser?.userProfileImage,
            comment: comments[index].addComment,
            commentBy: loggedInUser?.displayName,
            isCreator,
            isCollaborator,
            createdAt: date
        }

        if (type === 'UPDATE') {
            payload.updateId = updateId
            await axios.post(`${API_URL}/updates/add-childComment`, payload, { withCredentials: true })
        } else {
            await addChildComment(payload)
        }
 
        let tempArray = [...comments]
        if (!tempArray[index].childCommentAdds)
            tempArray[index].childCommentAdds = 0 
        if (tempArray[index].replies && tempArray[index].replies.length > 0) {
            tempArray[index].childComments.push(id)
            tempArray[index].replies = [...tempArray[index].replies, newComment]
            tempArray[index].childCommentAdds = tempArray[index].childCommentAdds + 1
        } else if (!comment.showReplies) {
            tempArray[index].childComments.push(id)
        } 
        tempArray[index].showAddReply = false
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }

    //@dev if reply modal is open, closes it, otherwise it opens it. 
    const handleShowReply = (index) => {
        let tempArray = [...comments]
        if (tempArray[index].showAddReply)
            tempArray[index].showAddReply = false
        else 
            tempArray[index].showAddReply = true

        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }

    const handleProfileClick = () => {
        document.body.style.overflow = ''
        if (comment.artistSlug) {
            navigate(`/artist-profile/${comment.artistSlug}`)
        } else {
            navigate(`/user-profile/${comment.username}`)
        }

        window.scrollTo({
            top: 0,
        })
    }

    let profilePicture = `${comment?.imageFilePath}`

    return (
        <CommentSection style={index === 0 ? { marginTop: 0 } : {}}>
            {(comment?.isCreator || comment.isCollaborator) && <CreatorTab></CreatorTab>}
            <InfoSection style={(!comment.isCreator && !comment.isCollaborator) ? { marginLeft: 20 } : {}}>
                <HeaderWrapper onClick={handleProfileClick}>
                    {!imageError ?
                        <ProfilePicContainer 
                            src={profilePicture} 
                            onError={() => setImageError(true)}
                        />
                    :
                        <ProfileErrorContainer>
                            <FaUser size={25} />
                        </ProfileErrorContainer>
                    }
                    <HeaderContainer>
                        <HeaderWrapper>
                            <Header>{comment.commentBy}</Header>
                            <Time>{timeFromNow(comment.createdAt)}</Time>
                        </HeaderWrapper>
                        {comment.isCreator && <SubHeader>Creator</SubHeader>}
                        {!comment.isCreator && comment.isCollaborator && <SubHeader>Collaborator</SubHeader>}
                    </HeaderContainer>
                </HeaderWrapper>
                {!comment.editComment 
                    ? 
                    <div style={{ marginTop: 20, fontSize: 13 }}>{comment.comment}</div> 
                    : 
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <AddComment autoFocus onFocus={handleFocus} ref={(element) => addToRef(element, commentRefs)} defaultValue={comment.comment} onInput={() => handleInputIncreases(commentRefs)} onChange={e => handleEditCommentChange(e, index)} style={{ width: '180%', marginTop: 20 }} placeholder='Add a reply...' />
                        <div style={{ display: 'flex' }}>
                            <CancelButton onClick={() => handleEditCommentCancel(index)}>Cancel</CancelButton>
                            <AddButton onClick={() => handleEditCommentSubmit(index, comment._id)} style={comment.updateCommentText ? { } : { background: '#D8D8D8', cursor: 'default' }}>Comment</AddButton>
                        </div>
                    </div>
                }
                <div style={{ display: 'flex' }} >
                    {comment.showAddReply ? 
                    <React.Fragment>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <AddComment autoFocus ref={element => addToRef(element, replyRefs)} onInput={() => handleInputIncreases(replyRefs)} onChange={e => handleChildCommentChange(e, index)} style={{ width: '180%', marginTop: 20 }} placeholder='Add a reply...' />
                            <div style={{ display: 'flex' }}>
                                <CancelButton onClick={() => handleShowReply(index)}>Cancel</CancelButton>
                                <AddButton onClick={() => handleAddChildComment(comment._id, index)} style={comment.addComment ? { } : { background: '#D8D8D8', cursor: 'default' }}>Comment</AddButton>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <div style={{ display: 'flex', position: 'relative' }}>
                        {loggedInUser?.id === comment?.userId && <Reply ref={editRef} onClick={(e) => handleEditClick(e, index)} style={{ color: '#2192FF' }}>Edit</Reply>}
                        <Reply onClick={() => handleShowReply(index)}>Reply</Reply>
                        {showEditModal && <EditModal 
                            commentRefs={commentRefs} 
                            editRef={editRef}
                            replyRefs={replyRefs} 
                            setCommentAdds={setCommentAdds} 
                            setPageCount={setPageCount} 
                            projectInfo={projectInfo} 
                            comments={comments} 
                            setComments={setComments} 
                            index={index} 
                            setCommentsLength={setCommentsLength} 
                            setShowEditModal={setShowEditModal}
                            route={route}
                            setUpdates={setUpdates}
                            updateId={updateId}
                        />}
                    </div>
                    }
                </div>
            </InfoSection>
        </CommentSection>
    )
}

export default CommentCard