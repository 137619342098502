import React, { useState, useRef } from 'react'
import { handleFocus, addToRef, handleInputIncreases } from '../helpers/inputHelpers'

import { updateChildComment } from '../discussionApi'
import { updateUpdateChildComment } from '../../Updates/updatesApi'

import ChildEditModal from '../ChildEditModal'

import { 
    AddComment, 
    AddButton,
    CancelButton,
    Reply,
    CommentSection, 
    InfoSection,
    ProfileErrorContainer,
    ProfilePicContainer, 
    HeaderWrapper,
    HeaderContainer,
    Header,
    SubHeader,
    Time,
    CreatorTab, 
} from '../DiscussionElements'

import { timeFromNow } from '../../../../../helpers/TimeFromNow'

import { FaUser } from 'react-icons/fa'

const ChildCommentCard = ({ 
    setCommentAdds,
    setPageCount, 
    projectInfo,
    comments, 
    setComments,
    comment,
    reply,
    index,
    replyIndex,
    setCommentsLength,
    route,
    loggedInUser
}) => {
    const [showReplyEdit, setShowReplyEdit] = useState({})
    const [imageError, setImageError] = useState(false)
    const editRef = useRef(null)
    const childCommentRefs = useRef([])
    childCommentRefs.current = []

    //@dev checks if the child edit modal is open and closes it if it is and opens if not. 
    const handleShowChildEditModal = (replyId) => {
        setShowReplyEdit(prevState => ({
            ...prevState,
            [replyId]: !prevState[replyId]
        }))
    }

    //@dev sets replies to false and resets update comment text
    const cancelChildEdit = (index, replyIndex) => {
        setComments(currentComments => {
            const newComments = [...currentComments]
    
            // Reset the updateCommentText to the original comment
            newComments[index].replies[replyIndex] = {
                ...newComments[index].replies[replyIndex],
                updateCommentText: newComments[index].replies[replyIndex].comment,
                editReply: false
                // You can also reset other properties here if needed
            }
    
            return newComments
        })
        setShowReplyEdit(!showReplyEdit)
    }

    //@dev creates updateComment text if it doesn't exist and then sets it to e value.
    const handleChildCommentChange = (e, index, replyIndex) => {
        const newCommentText = e.target.value
    
        setComments(currentComments => {
            // Create a new array for comments
            const newComments = [...currentComments]
    
            // Update the specific reply's updateCommentText
            newComments[index].replies[replyIndex] = {
                ...newComments[index].replies[replyIndex],
                updateCommentText: newCommentText
            }
    
            return newComments
        })
    }

    //@dev updates child comment (reply), updates react comment array
    //and closes input. 
    const handleUpdateChildComment = async (e, index, replyIndex) => {
        const comment = comments[index].replies[replyIndex]._id
        const updatedComment = comments[index].replies[replyIndex].updateCommentText

        if (comments[index].replies[replyIndex].updateCommentText && comments[index].replies[replyIndex].updateCommentText !== comments[index].replies[replyIndex].comment) {

            let response
            if (route === 'project-comments') {
                response = await updateChildComment(loggedInUser.id, comment, updatedComment)
            } else {
                response = await updateUpdateChildComment(loggedInUser.id, comment, updatedComment)
            }

            if (response) {
                // Only update state if necessary
                setComments(currentComments => {
                    // Create a new array for comments
                    const newComments = [...currentComments]
                    // Update only the specific reply's comment
                    newComments[index].replies[replyIndex] = {
                        ...newComments[index].replies[replyIndex],
                        comment: updatedComment,
                        editReply: false
                    }
                    return newComments
                })
            }
        }
    }

    let profilePicture = `${reply?.imageFilePath}`

    return (
        <CommentSection style={{ width: '90%' }}>
            {reply.isCreator ? <CreatorTab></CreatorTab> : null}
            <InfoSection style={!reply.isCreator ? { marginLeft: 20 } : {}}>
                <HeaderWrapper>
                    {!imageError ?
                        <ProfilePicContainer 
                            src={profilePicture} 
                            onError={() => setImageError(true)}
                        />
                    :
                        <ProfileErrorContainer>
                            <FaUser size={25} />
                        </ProfileErrorContainer>
                    }
                    <HeaderContainer>
                        <HeaderWrapper>
                            <Header>{reply.commentBy}</Header>
                            <Time>{timeFromNow(reply.createdAt)}</Time>
                        </HeaderWrapper>
                        {reply.isCreator && <SubHeader>Creator</SubHeader>}
                    </HeaderContainer>
                </HeaderWrapper>
                {!reply.editReply ?
                <div style={{ marginTop: 20, fontSize: 13 }}>{reply.comment}</div>
                :
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AddComment autoFocus onFocus={e => handleFocus(e, childCommentRefs)} ref={element => addToRef(element, childCommentRefs)} onInput={() => handleInputIncreases(childCommentRefs)} onChange={e => handleChildCommentChange(e, index, replyIndex, childCommentRefs)} style={{ width: '180%', marginTop: 20 }} defaultValue={reply.comment} placeholder='Add a reply...' />
                    <div style={{ display: 'flex' }}>
                        <CancelButton onClick={() => cancelChildEdit(index, replyIndex)} >Cancel</CancelButton>
                        <AddButton onClick={e => handleUpdateChildComment(e, index, replyIndex)} style={comments[index].replies[replyIndex].updateCommentText && comments[index].replies[replyIndex].updateCommentText !== comments[index].replies[replyIndex].comment ? { } : { background: '#D8D8D8', cursor: 'default' }}>Comment</AddButton>
                    </div>
                </div>
                }
                <div style={{ display: 'flex', position: 'relative' }}>
                    {loggedInUser?.id === reply?.userId && <Reply ref={editRef} onClick={() => handleShowChildEditModal(reply._id)} style={{ color: '#2192FF' }}>Edit</Reply>}
                    {showReplyEdit[reply._id] && 
                    <ChildEditModal 
                        replyId={reply._id} 
                        showReplyEdit={showReplyEdit} 
                        setShowReplyEdit={setShowReplyEdit}  
                        setPageCount={setPageCount} 
                        projectInfo={projectInfo} 
                        comments={comments} 
                        setComments={setComments} 
                        editRef={editRef}
                        index={index} 
                        replyIndex={replyIndex} 
                        setCommentsLength={setCommentsLength} 
                        route={route}
                    />}
                </div>
            </InfoSection>
        </CommentSection>
    )
}

export default ChildCommentCard