import React, { useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../../../../config'

import PerkCard from '../PerkCard'

import { DeletePerkButton, CancelPerkButton } from '../PerkElements'

import { 
    Modal, 
    Header, 
    HeaderText, 
    Body,
    BodySection,
} from '../../../Modal/EditModalElements'

import { BsCardHeading } from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'
import { IoCloseSharp } from 'react-icons/io5'

function DeletePerk({ setShowModal, perkArray, setPerkArray, setShowDeletePerk, projectInfo }) {
    const [perkIndex, setPerkIndex] = useState(-1)

    const { user, _id } = projectInfo.project

    const handleClose = () => {
        document.body.style.overflow = 'visible'
        setShowModal(false)
    }

    const handleSectionClick = (index) => {
        setPerkIndex(index)
    }

    let perks 

    if (perkIndex === -1) {
        perks = perkArray.map((perk, index) => {
            return (
                <BodySection onClick={() => handleSectionClick(index)} key={perk._id}>
                    <BsCardHeading size={19} style={{ marginRight: 5 }}/>
                    {perk.header}
                </BodySection>
            )
        })
    } else {
        perks = 
        <div style={{ padding: 10 }} >
            <PerkCard key={perkArray[perkIndex]._id} imgSrc={perkArray[perkIndex].imagePath} header={perkArray[perkIndex].header} amount={perkArray[perkIndex].amount} details={perkArray[perkIndex].details} estimatedDelivery={perkArray[perkIndex].estimatedDelivery} shipsTo={perkArray[perkIndex].shipsTo} unlocks={perkArray[perkIndex].unlocks}/>
        </div>
    }

    const handleDelete = async () => {
        let tempArray = [...perkArray]
        const payload = {
            projectId: _id,
            user,
            perkId: perkArray[perkIndex]._id
        }

        if (perkArray[perkIndex].imageFile) {
            await axios.post(`${API_URL}/project-perks/delete-perk-with-graphic/${_id}/${perkArray[perkIndex].imageFile}`, payload, { withCredentials: true })
        } else {
            await axios.post(`${API_URL}/project-perks/delete-perk/me`, payload, { withCredentials: true })
        }
        
        tempArray.splice(perkIndex, 1)
        setPerkIndex(-1)
        setPerkArray(tempArray)
        setShowModal(false)
        document.body.style.overflow = 'visible'
    }

    const handleBack = () => {
        if (perkIndex !== -1)
            setPerkIndex(-1)
        else
            setShowDeletePerk(false)
    }

    return (
        <Modal>
            <Header>
                <HeaderText onClick={handleBack} style={{ display: 'flex', alignItems: 'center' }}><IoIosArrowBack size={25} /> Delete Perks</HeaderText>
                <IoCloseSharp onClick={handleClose} size={25} style={{ cursor: 'pointer' }}/>
            </Header>
            <Body>
                {perks}
                { perkIndex !== -1 ?
                <div style={{ padding: 10 }} >
                    <DeletePerkButton onClick={handleDelete} >Delete Perk</DeletePerkButton>
                    <CancelPerkButton onClick={() => setPerkIndex(-1)}>Cancel</CancelPerkButton>
                </div>
                : null
                }
            </Body>
        </Modal>
    )
}

export default DeletePerk