import React, { useState, useContext } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import { AuthContext } from '../../authContext'
import { CLOUDFRONT_URL } from '../../config'
import { useNavigate } from 'react-router-dom'

import {
    UserCardContainer,
    UserCardNoImageContainer,
    UserCardImage,
    UserCardName,
    UserMiniBio,
    UserRoles,
    FollowButton
} from './UserProfileElements'

import { FaUser } from 'react-icons/fa'

const UserProfile = ({ user, isFollowing, setIsFollowing, setNumberOfFollowers }) => {
    const { loggedInUser } = useContext(AuthContext)
    const navigate = useNavigate()
    const [imageError, setImageError] = useState('')

    const handleImageError = () => {
        setImageError(true)
    }

    const handleFollowButtonClick = async (event) => {
        event.stopPropagation()

        // Check if user is logged in
        if (!loggedInUser.loggedIn) {
            navigate('/login')
            return
        }

        try {
            if (user && loggedInUser) {
                if (isFollowing) {
                    await axios.post(`${API_URL}/discover-users/unfollow`, 
                        { followedUserId: user._id }, 
                        { withCredentials: true }
                    )
                    setIsFollowing(false)
                    setNumberOfFollowers(prev => prev - 1)
                } else {
                    await axios.post(`${API_URL}/discover-users/follow`, 
                        { followedUserId: user._id }, 
                        { withCredentials: true }
                    )
                    setIsFollowing(true)
                    setNumberOfFollowers(prev => prev + 1)
                }
            }
        } catch (error) {
            console.log(error)
            // Handle the error and display an error message to the user if needed
        }
    }

    // Use profilePictureUrl if available, fallback to constructing from profilePicture
    const imageUrl = user?.profilePictureUrl || 
    (user?.profilePicture && user.profilePicture !== 'null' ? 
        `${CLOUDFRONT_URL}/${user.profilePicture}` 
        : null
    )

    return (
        <UserCardContainer>
            {!imageError && imageUrl ?
                <UserCardImage src={imageUrl} onError={handleImageError} />
                :
                <UserCardNoImageContainer>
                    <FaUser size={50} color='white' />
                </UserCardNoImageContainer>
            }
            <UserCardName>{user?.displayName ? user?.displayName : user?.username}</UserCardName>
            <UserRoles></UserRoles>
            <UserMiniBio>{user?.miniBio}</UserMiniBio>
            {user._id !== loggedInUser.id &&
                <FollowButton 
                    className={isFollowing ? 'unfollow' : 'follow'} 
                    onClick={handleFollowButtonClick}
                >
                    {isFollowing ? 'Following' : 'Follow'}
                </FollowButton>
            }
        </UserCardContainer>
    )
}

export default UserProfile