import styled from 'styled-components'

export const ProjectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 95%;
    height: 95%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
`

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
`

export const Info = styled.div`
    display: flex;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
`

export const BottomInfo = styled.div`
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    height: 40px;
    background-color: #F5F5F5;
`

export const ProjectImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ProjectImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ImageOverlay = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    ${props => props.isVisible && `
        opacity: 1;
    `};
`

export const ImageText = styled.div`
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const UnfollowButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 30px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    cursor: pointer;

    &:hover {
        background-color: #333333;
    }
`

export const EditButton = styled.button`
    float: right;
    margin-top: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #eee;
    border: none;
    font-size: 13px;
    padding: 5px;
    cursor: pointer;

    &:hover {
        background-color: #F5F5F7;
    }
`

